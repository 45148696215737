import { Fragment, useCallback, useEffect, useState, useRef } from 'react';

import { TableRow } from '@mui/material';
import Confirmation from './Stages/Confirmation/Confirmation';
import Shipper from './Stages/Shipper/Shipper';
import Trucking from './Stages/Trucking/Trucking';
import Wh from './Stages/Wh/Wh';
import Pod from './Stages/Pod/Pod';
import Pol from './Stages/Pol/Pol';
import Importer from './Stages/Importer/Importer';
import Customs from './Stages/Customs/Customs';
import Departure from './Stages/Departure/Departure';
import OrderRowCollapse from './OrderRowCollapse';
import OrderRowInfo from './OrderRowInfo';
import { onOrderSelected } from 'reducers/order-reducers/ordersSlicer';
import {orderRowBgColor} from '../Orders';
import { useSelector, useDispatch } from 'react-redux';

import { sendRequest, REQUEST_ACTIONS, setOrderData } from 'utils/Communicator';
import { snackbarToggle } from 'reducers/snackbarSlicer';

const OrderRow = ({ order, loginUser, pageName }) => {

    const { selectedLocationIndex, } = useSelector(state => state.orderTableFilter);
  const [openCollapse, setOpenCollapse] = useState(false);
  const onHandleSwitchCollapse = () => setOpenCollapse(!openCollapse);
  const { orderSelected} = useSelector(state => state.orders);
    const dispatch = useDispatch();
    const {
        userUSNames,
        userNLNames,
        userILNames,
    } = useSelector(state => state.predefinedData);
    const [ilUserImporters, setIlUsersImporters] = useState([]);
    const [ilUserTracers, setIlUsersTracers] = useState([]);
    const [ilUserCustoms, setIlUsersCustoms] = useState([]);

    useEffect(() => {
        // predefined user local data get
        var importers = userILNames.filter(name => name.department == 1 || name.department == null);
        var tracers = userILNames.filter(name => name.department == 2);
        var customs = userILNames.filter(name => name.department == 3);
        setIlUsersImporters(importers);
        setIlUsersTracers(tracers);
        setIlUsersCustoms(customs);
    }, [userILNames]);

    const isNLUser = () => {
        if (loginUser.authority === "Admin")
            return true;
        var lengthNL = userNLNames ? userNLNames.length : 0;
        for (var i = 0; i < lengthNL; i++) {
            if (userNLNames[i].name == loginUser.fullName) {
                return true;
            }
        }
        var lengthUS = userUSNames ? userUSNames.length : 0;
        for (var i = 0; i < lengthUS; i++) {
            if (userUSNames[i].name == loginUser.fullName) {
                return true;
            }
        }
        return false;
    }
    const isImporterUser = () => {
        if (loginUser.authority === "Admin")
            return true;
        var lenIlUserImporters = ilUserImporters ? ilUserImporters.length : 0;
        for (var i = 0; i < lenIlUserImporters; i++) {
            if (ilUserImporters[i].name == loginUser.fullName) {
                return true;
            }
        }
        return false;
    }
    const isTracerUser = () => {
        if (loginUser.authority === "Admin")
            return true;
        var lengthNL = ilUserTracers ? ilUserTracers.length : 0;
        for (var i = 0; i < lengthNL; i++) {
            if (ilUserTracers[i].name == loginUser.fullName) {
                return true;
            }
        }
        return false;
    }
    const isCustomsUser = () => {
        if (loginUser.authority === "Admin")
            return true;
        var lengthNL = ilUserCustoms ? ilUserCustoms.length : 0;
        for (var i = 0; i < lengthNL; i++) {
            if (ilUserCustoms[i].name == loginUser.fullName) {
                return true;
            }
        }
        return false;
    }
  return (
    <Fragment>
      <OrderRowInfo
              loginUser={loginUser}
              order={order}
              openCollapse={openCollapse}
              onHandleSwitchCollapse={onHandleSwitchCollapse}
              pageName={pageName}
              stages={<>
                  {((isNLUser() || isImporterUser()) && (selectedLocationIndex == 0 || selectedLocationIndex == 1)) && <Confirmation loginUser={loginUser} order={order} pageName={pageName} />}
                  {(isNLUser() || isImporterUser()) && <Shipper loginUser={loginUser} order={order} pageName={pageName} />}
                  {(isNLUser() || isImporterUser()) && <Trucking loginUser={loginUser} order={order} pageName={pageName} />}
                  {/*{(isNLUser() || isImporterUser()) && <Wh loginUser={loginUser} order={order} pageName={pageName} />}*/}
                  {(isNLUser() || isImporterUser() || isTracerUser() || isCustomsUser()) && <Departure loginUser={loginUser} order={order} pageName={pageName} />}
                  {(isTracerUser() || isCustomsUser()) && <Pol loginUser={loginUser} order={order} pageName={pageName} />}
                  {(isTracerUser() || isCustomsUser()) && <Pod loginUser={loginUser} order={order} pageName={pageName} />}
                  {isCustomsUser() && <Customs loginUser={loginUser} order={order} pageName={pageName} />}
                  {isCustomsUser() && <Importer loginUser={loginUser} order={order} pageName={pageName} />}
              </>}
      />
      {/*<TableRow sx={{ '& > *': { pr: 0 } }} onClick={() => dispatch(onOrderSelected(order?.id))} style={order?.id === orderSelected ?orderRowBgColor:null}>
        <Confirmation loginUser={loginUser} order={order} pageName={pageName} />
        <Shipper loginUser={loginUser} order={order} pageName={pageName}/>
        <Trucking loginUser={loginUser} order={order} pageName={pageName}/>
        <Wh loginUser={loginUser} order={order} pageName={pageName}/>
        <Departure loginUser={loginUser} order={order} pageName={pageName}/>
      </TableRow>*/}
      {openCollapse && <OrderRowCollapse orderDetails={order.orderDetails} openCollapse={openCollapse} orderID={order?.id} />}
    </Fragment>
  );
};

export default OrderRow;
