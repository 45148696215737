import { useState, memo } from 'react';

import StageButton from '../StageButton';
import PolDialog from './PolDialog';
import {StyledTableCell} from 'Utils';

const Pol = ({ loginUser, order }) => {
    const [customsDate, setCustomsDate] = useState(order.airStatus?.polDate ? order.airStatus?.polDate : (order.orderStage[4]?.departureStage[0]?.etd ? order.orderStage[4]?.departureStage[0].etd : null));
    const currentStage = order.orderStatus ? order.orderStatus[7] : null;

    const getGap = () => {
        var dt1 = order.orderStage[4]?.departureStage[0]?.etd ? order.orderStage[4]?.departureStage[0]?.etd : null
        var date1 = new Date(dt1);
        var date2 = new Date(customsDate);
        var delay = 0;
        var status = order?.orderStatus[5]?.status ? order?.orderStatus[5]?.status : "NOT_STARTED";
        var timeDiff = date2.getTime() - date1.getTime();
        if (timeDiff > 0)
            delay = timeDiff / 86400000 | 0;
        if (delay && status !== "SUCCESS")
            return " (" + delay + ")";
            return ""
    }

    const [openDialog, setOpenDialog] = useState(false);
    const onHandleOpenDialog = () => setOpenDialog(true);
    const onHandleCloseDialog = () => setOpenDialog(false);

  return (
      <StyledTableCell style={{ width: "5vw", p: 0 }}>
          {/*<StageButton stage={{ 'status': `${order?.orderStatus ? ((order?.orderStatus[6]?.status === "SUCCESS" || order?.orderStatus[6]?.status === "IN_PROGRESS") ? order.orderStatus[6]?.status : `${order?.orderStatus[4]?.status === "SUCCESS" ? "IN_PROGRESS" : "NOT_STARTED"}`) : null}`, 'notes': "" }} onHandleOpenDialog={onHandleOpenDialog} stageName='POL' loginUser={loginUser} />*/}
          <StageButton stage={{ 'status': `${order?.orderStatus[5]?.status ? order?.orderStatus[5]?.status : "NOT_STARTED"}`, 'notes': "" }} onHandleOpenDialog={onHandleOpenDialog} stageName={'DEP' + getGap() } loginUser={loginUser} />
          {openDialog && <PolDialog onHandleCancel={onHandleCloseDialog} loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} />}
    </StyledTableCell>
  );
};

export default memo(Pol);
