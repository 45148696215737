import { Fragment, useCallback, useEffect, useState } from 'react';

import { Box, Grid, Button, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import { SelectField, TextField, DateTimePicker, MultilineField, Dialog, Autocomplete } from 'style-guide';
import AirDetails from './OrderDetails/AirDetails';
import FCLDetails from './OrderDetails/FCLDetails';
import LCLDetails from './OrderDetails/LCLDetails';
import OrderInformation from './OrderInformation';

import { sendRequest, REQUEST_ACTIONS, setOrderData } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { getOrders, onOrdersCountChange, onChatCountChange, onChatCountCChange, onChatCountTChange, onChatCountCTChange, onUserSpecificOrdersCountChange, onUnConfirmedOrdersCountChange } from 'reducers/order-reducers/ordersSlicer';
import {
    onAirPoaDataChange,
    onAirPolDataChange,
    onLclPoaDataChange,
    onLclPolDataChange,
    onIncotermsDataChange,
    onFCLType20DataChange,
    onFCLType40DataChange,
} from 'reducers/order-reducers/predefinedDataSlicer';
import { addOrderFilter, addSearchFilter } from 'Utils';

const OrderForm = ({ brokers, onHandleCancel, order, copyOrder, loginUser, orderTableFilter, pageName }) => {
    const { incotermsData, airPolData, airPoaData, lclPolData, lclPoaData, userILNames, countryOfOriginData, subModeData, fclType20Data, fclType40Data } =
        useSelector(state => state.predefinedData);

    const { selectedLocationIndex, } = useSelector(state => state.orderTableFilter);

    const [showMail, setShowMail1] = useState(false);

    const getCompanyShortName = (company) => {
        if (company?.companyShortName)
            return company.companyShortName?.substring(0, 11).toUpperCase();
        else
            return company.companyName?.substring(0, 11).toUpperCase();
    }

    const setShowMail = () => {
        showMail ? setShowMail1(false) : setShowMail1(true);
        var sub = getType(order.shippingModeId) + " " + getFrom(order.polId, order.shippingModeId, true) + "-" + getTo(order.poaId, order.shippingModeId);
        sub += " | " + getCompanyShortName(order.shipper) + " | " + getCompanyShortName(order.consignee) + ", ref " + order.jobNumber;
        setMailSubject(sub);
    }
    const getTodayDate = () => {
        return new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(new Date())
    }
    const [customerlocation, setCustomerlocation] = useState(order?.locationId ? selectedLocationIndex : null);
    const [customerLocationList, setCustomerLocationList] = useState([{ id: 0, name: "NL" }, { id: 1, name: "US" }, { id: 2, name: "ALL" }, { id: 3, name: "EXP" }]);

    const [mailSubject, setMailSubject] = useState("");
    const [mailBody, setMailBody] = useState("");
    const [mailToAdd, setMailToAdd1] = useState("");
    const [customsDate, setCustomsDate] = useState(order?.orderStage?.length ? order?.orderStage[4]?.departureStage[0]?.eta : null);
    
    const [useMailToAgent, setUseMailToAgent] = useState(false);
    const [mailToAgent, setMailToAgent1] = useState("");
    const [mailToAgentValidation, setMailToAgentValidation] = useState(true);
    const setMailToAgent = (str) => {
        setMailToAgent1(str);
        setMailToAgentValidation(validateEmail(str));
    }
    const [useMailToAgent2, setUseMailToAgent2] = useState(false);
    const [mailToAgent2, setMailToAgent12] = useState("");
    const [mailToAgentValidation2, setMailToAgentValidation2] = useState(true);
    const setMailToAgent2 = (str) => {
        setMailToAgent12(str);
        setMailToAgentValidation2(validateEmail(str));
    }
    const [useMailToAgent3, setUseMailToAgent3] = useState(false);
    const [mailToAgent3, setMailToAgent13] = useState("");
    const [mailToAgentValidation3, setMailToAgentValidation3] = useState(true);
    const setMailToAgent3 = (str) => {
        setMailToAgent13(str);
        setMailToAgentValidation3(validateEmail(str));
    }
    const [useMailToAgent4, setUseMailToAgent4] = useState(false);
    const [mailToAgent4, setMailToAgent14] = useState("");
    const [mailToAgentValidation4, setMailToAgentValidation4] = useState(true);
    const setMailToAgent4 = (str) => {
        setMailToAgent14(str);
        setMailToAgentValidation4(validateEmail(str));
    }
    const [useMailToAgent5, setUseMailToAgent5] = useState(false);
    const [mailToAgent5, setMailToAgent15] = useState("");
    const [mailToAgentValidation5, setMailToAgentValidation5] = useState(true);
    const setMailToAgent5 = (str) => {
        setMailToAgent15(str);
        setMailToAgentValidation5(validateEmail(str));
    }
    const [useMailToAdd, setUseMailToAdd] = useState(false);
    const setMailToAdd = (str) => {
        setMailToAdd1(str);
        if (str) {
            var strs = str.split(",");
            var valid = true;
            for (var i = 0; i < strs.length; i++) {
                if (!validateEmail(strs[i]))
                    valid = false;
            }
            setUseMailToAdd(valid);
        }
    }
    const [brokerNames, setBrokerNames] = useState(false);
    const [brokerName, setBrokerName] = useState(false);
    const [brokers1, setBrokers1] = useState(false);
    const setBrokers = (data) => {
        setBrokers1(data);
        const brokerNameOptions = data?.map(broker => ({
            id: broker.id,
            name: broker.companyName,
            serviceProviderLocationId: broker.serviceProviderLocationId,
        }));
        if (order?.customer?.id) {
            var customerEmail = data.find(dat => dat.id == order.customer.id)?.email || null;
            var customerEmail2 = data.find(dat => dat.id == order.customer.id)?.email2 || null;
            var customerEmail3 = data.find(dat => dat.id == order.customer.id)?.email3 || null;
            var customerEmail4 = data.find(dat => dat.id == order.customer.id)?.email4 || null;
            var customerEmail5 = data.find(dat => dat.id == order.customer.id)?.email5 || null;
            var customerCompanyName = data.find(dat => dat.id == order.customer.id).companyName
            setMailToAgent(customerEmail);
            setMailToAgent2(customerEmail2);
            setMailToAgent3(customerEmail3);
            setMailToAgent4(customerEmail4);
            setMailToAgent5(customerEmail5);
            setBrokerName(customerCompanyName ? customerCompanyName : "");
        } else {
            setMailToAgent("");
            setMailToAgent2("");
            setMailToAgent3("");
            setMailToAgent4("");
            setMailToAgent5("");

        }
        setBrokerNames(brokerNameOptions ? brokerNameOptions : []);
    }
    const [useMailToCustomer, setUseMailToCustomer] = useState(false);
    const [mailToCustomer, setMailToCustomer] = useState(order?.consignee?.email ? order.consignee.email : "");
    const [mailToCustomerValidation, setMailToCustomerValidation] = useState(order?.consignee?.email ? true : false);

    const [useMailToCustomer2, setUseMailToCustomer2] = useState(false);
    const [mailToCustomer2, setMailToCustomer2] = useState(order?.consignee?.email2 ? order.consignee.email2 : "");
    const [mailToCustomerValidation2, setMailToCustomerValidation2] = useState(order?.consignee?.email2 ? true : false);

    const [useMailToCustomer3, setUseMailToCustomer3] = useState(false);
    const [mailToCustomer3, setMailToCustomer3] = useState(order?.consignee?.email3 ? order.consignee.email3 : "");
    const [mailToCustomerValidation3, setMailToCustomerValidation3] = useState(order?.consignee?.email3 ? true : false);

    const [useMailToCustomer4, setUseMailToCustomer4] = useState(false);
    const [mailToCustomer4, setMailToCustomer4] = useState(order?.consignee?.email4 ? order.consignee.email4 : "");
    const [mailToCustomerValidation4, setMailToCustomerValidation4] = useState(order?.consignee?.email4 ? true : false);

    const [useMailToCustomer5, setUseMailToCustomer5] = useState(false);
    const [mailToCustomer5, setMailToCustomer5] = useState(order?.consignee?.email5 ? order.consignee.email5 : "");
    const [mailToCustomerValidation5, setMailToCustomerValidation5] = useState(order?.consignee?.email5 ? true : false);

    const isHebrew = (str) => {
        return (/[\u0590-\u05FF]/).test(str);
    }

    const getComments = (str) => {
        var strs1 = [];
        var strs = [];
        strs = str.split("\n");
        for (var i = 0; i < strs.length; i++) {
            //if (isHebrew(strs[i]))
            //    strs1.push(encodeURI(strs[i]));
            //else
                strs1.push(strs[i]);
        }
        return strs1.join("<br/>");
    }
    const getKindOfPieces = () => {
        if (order.shippingModeId == 3) {
            if (order.orderFCLDetails) {
                var fcl20 = "";
                var fcl40 = "";
                if (order.orderFCLDetails?.fclType20Id) {
                    fcl20 = fclType20Data.find(dat => dat.id == order.orderFCLDetails?.fclType20Id)?.name
                }
                if (order.orderFCLDetails?.fclType40Id) {
                    fcl40 = fclType40Data.find(dat => dat.id == order.orderFCLDetails?.fclType40Id)?.name
                }
                if (fcl20 && fcl40)
                    return fcl20 + ", " + fcl40;
                if (fcl20 && !fcl40)
                    return fcl20;
                if (!fcl20 && fcl40)
                    return fcl40;
                return "";
            }
        }
        return "";
    }
    const getTotalPieces = () => {
        var num = 0;
        for (var i = 0; i < order.orderDetails?.length; i++) {
            num += order.orderDetails[i].numberOfPieces;
        }
        if (order.orderFCLDetails?.quantity20) {
            num += order.orderFCLDetails.quantity20;
        }
        if (order.orderFCLDetails?.quantity40) {
            num += order.orderFCLDetails.quantity40;
        }
        return num ? num : "";
    }
    const getFrom = (poaId, shippingModeId, bol) => {
        try {
            var str = "";
            if (shippingModeId == 1) {
                str = airPoaData.find(poa => poa.id == poaId)?.name
            }
            else str = lclPoaData.find(poa => poa.id == poaId)?.name
            if (str)
                return str;
            else if(bol)
                str = countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name;
            return str? str : "";
        } catch (e) {
            return "N/A";
        }
    }
    const getTo = (polId, shippingModeId) => {
        try {
            var str = "";
            if (shippingModeId == 1) {
                str = airPolData.find(pol => pol.id == polId)?.name
            }
            else str = lclPolData.find(pol => pol.id == polId)?.name
            if (str)
                return str;
            return "";
        } catch (e) {
            return "N/A";
        }
    }
    const getType = (shippingModeId) => {
        try {
            if (shippingModeId == 1) {
                return "Air";
            } else if (shippingModeId == 2) {
                return "LCL";
            } else {
                return "FCL";
            }
        } catch (e) {
            return "N/A"
        }
    }
    const getTotalWeight = () => {
        var wt = 0;
        for (var i = 0; i < order.orderDetails.length; i++) {
            wt += order.orderDetails[i].weightPerPiece != 0 ? (order.orderDetails[i].numberOfPieces * order.orderDetails[i].weightPerPiece) : order.orderDetails[i].totalweight;
        }
        return wt ? wt.toFixed(2) :"";
    }
    const getChWeight = () => {
        var wt = 0;
        var vt = 0;
        var manualwt = 0;
        for (var i = 0; i < order.orderDetails.length; i++) {
            wt += order.orderDetails[i].weightPerPiece != 0 ? (order.orderDetails[i].numberOfPieces * order.orderDetails[i].weightPerPiece) : order.orderDetails[i].totalweight;
            vt += order.orderDetails[i]['length'] * order.orderDetails[i].width * order.orderDetails[i].height / 6000;
        }
        wt = wt > vt ? wt : vt
        return wt ? wt.toFixed(2) : manualwt.toFixed(2);
    }
    const sendMail = () => {
        if (useMailToAgent && mailToAgent === "") {
            alert("Please enter agent email.")
            return;
        }
        if (useMailToAgent && !validateEmail(mailToAgent)) {
            alert("Please enter a proper agent email.")
            return;
        }
        if (useMailToCustomer && mailToCustomer === "") {
            alert("Please make sure to have proper consignee email.")
            return;
        }
        if (useMailToCustomer && !validateEmail(mailToCustomer)) {
            alert("Please enter a proper consignee email in CRM.")
            return;
        }
        if (!((useMailToAgent && validateEmail(mailToAgent)) ||
            (useMailToCustomer && validateEmail(mailToCustomer)) || useMailToAdd)) {
            alert("Select atleast one of agent and/or customer and/or additional e-mails should be valid.");
            return;
        }
        var mtoCust = mailToCustomer;
        if (!useMailToCustomer) {
            mtoCust = null;
        }
        var mtoCust2 = mailToCustomer2;
        if (!useMailToCustomer2) {
            mtoCust2 = null;
        }
        var mtoCust3 = mailToCustomer3;
        if (!useMailToCustomer3) {
            mtoCust3 = null;
        }
        var mtoCust4 = mailToCustomer4;
        if (!useMailToCustomer4) {
            mtoCust4 = null;
        }
        var mtoCust5 = mailToCustomer5;
        if (!useMailToCustomer5) {
            mtoCust5 = null;
        }
        var mtoagent = mailToAgent;
        if (!useMailToAgent) {
            mtoagent = null;
        }
        var mtoagent2 = mailToAgent2;
        if (!useMailToAgent2) {
            mtoagent2 = null;
        }
        var mtoagent3 = mailToAgent3;
        if (!useMailToAgent3) {
            mtoagent3 = null;
        }
        var mtoagent4 = mailToAgent4;
        if (!useMailToAgent4) {
            mtoagent4 = null;
        }
        var mtoagent5 = mailToAgent5;
        if (!useMailToAgent5) {
            mtoagent5 = null;
        }
        var addto = mailToAdd;
        if (!useMailToAdd) {
            addto = null;
        }
        var impt = order.locationId === "2" ? "Export" : "Import";
        var mbody = '<div style="width: 100%; text-align: center"><img src="cid:header"/></div>';
        mbody += '<br /> <div style="text-align: center; width: 100%"><b>' + (order.locationId === "2" ? "Export " : "Import ") + ' JOB ORDER No. - ' + order.jobNumber + ' </b></div> <br /><br />';
        mbody += '<b> Date: </b>' + getTodayDate() + ' <br /><br /><table style="border-collapse:collapse"><tr>'
        mbody += '<td style="background: lightgrey; width: 25%"><b style="margin-right: 5px; margin-left: 5px">Shipper:</b></td>';
        mbody += '<td>' + order.shipper.companyName;
        mbody += (order.shipper.address1 ? '<br/>' : '') + (order.shipper.address1 ? order.shipper.address1 : '');
        mbody += (order.shipper.address2 ? '<br/>' : '') + (order.shipper.address2 ? order.shipper.address2 : '');
        mbody += (order.shipper.city ? '<br/>' : '') + (order.shipper.city ? order.shipper.city : '');
        mbody += (order.shipper.zipCode ? '<br/>' : '') + (order.shipper.zipCode ? order.shipper.zipCode : '');
        mbody += '</td></tr><tr><td style="background: lightgrey"><b style="margin-right: 5px; margin-left: 5px">Contact:</b></td>';
        mbody += '<td>' + (order.shipper.name ? order.shipper.name : "") + '</td></tr><tr><td style="background: lightgrey"><b style="marginright: 5px; margin-left: 5px">Email:</b></td>';
        mbody += '<td>';
        mbody += (order.shipper.email ? order.shipper.email : "") + (order.shipper.email ? '<br/>' : '');
        mbody += (order.shipper.email2 ? order.shipper.email2 : "") + (order.shipper.email2 ? '<br/>' : '');
        mbody += (order.shipper.email3 ? order.shipper.email3 : "") + (order.shipper.email3 ? '<br/>' : '');
        mbody += (order.shipper.email4 ? order.shipper.email4 : "") + (order.shipper.email4 ? '<br/>' : '');
        mbody += (order.shipper.email5 ? order.shipper.email5 : "") + (order.shipper.email5 ? '<br/>' : '');
        mbody += '</td></tr><tr><td style="background: lightgrey"><b style="margin-right: 5px; margin-left: 5px">Tel:</b></td>';
        mbody += '<td>' + (order.shipper.businessPhone ? order.shipper.businessPhone : (order.shipper.mobile ? order.shipper.mobile : "")) + '</td></tr></table><br /><table style="border-collapse:collapse"><tr><td style="background: lightgrey; width: 25%">';
        mbody += '<b style="marginright: 5px; margin-left: 5px">Consignee:</b></td ><td>' + order.consignee.companyName;
        mbody += (order.consignee.address1 ? '<br/>' : '') + (order.consignee.address1 ? order.consignee.address1 : '');
        mbody += (order.consignee.address2 ? '<br/>' : '') + (order.consignee.address2 ? order.consignee.address2 : '');
        mbody += (order.consignee.city ? '<br/>' : '') + (order.consignee.city ? order.consignee.city : '');
        mbody += (order.consignee.zipCode ? '<br/>' : '') + (order.consignee.zipCode ? order.consignee.zipCode : '');
        mbody += '</td></tr></table><br/>';
        mbody += '<p>' + (mailBody ? getComments(mailBody) : "") + "</p><br/>";
        mbody += '<table style="width:461.25pt;margin-left:.5pt;border-collapse:collapse;border:none"><tr><td width="615" colspan="4" style="width:461.25pt;border:solid black 1.0pt;border-bottom:solid windowtext 1.0pt;background:#f2f2f2;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt; text-align: center;">SHIPPING INFORMATION </td></tr>';
        mbody += '<tr><td width="150" valign="top" style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Consignee Reference</td>';
        mbody += '<td width="139" valign="top" style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.clientRefNumber ? order.clientRefNumber : "") + '</td><td style="background: lightgrey; border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Origin</td>';
        mbody += '<td width="154" valign="top" style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name + '</td></tr><tr>';
        mbody += '<td width="172" valign="top" style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipper reference</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.suppliersPO ? order.suppliersPO : "") + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Port of loading</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getFrom(order.polId, order.shippingModeId, false) + '</td></tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">No. of packs</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTotalPieces() + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Port of discharge</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTo(order.poaId, order.shippingModeId) + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Kind of packages</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getKindOfPieces() + '</td>';
        mbody += '<td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipment Method</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getType(order.shippingModeId) + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Weight Kg</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getTotalWeight() + '</td>';
        mbody += '<td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Shipment Terms</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + incotermsData.find(dat => dat.id == order.incotermsId)?.name + '</td>';
        mbody += '</tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Volume CBM.</td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + getChWeight() + '</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Consignee VAT.</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + (order.consignee.uatnumber ? order.consignee.uatnumber : "") + '</td></tr><tr><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">Type</td>';
        mbody += '<td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt">' + subModeData.find(dat => dat.id == order.subModeId)?.name +'</td><td style="background: lightgrey;border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt"></td><td style="border-top:none;border-left:none;border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;padding:0cm 5.05pt 0cm 5.05pt;height:14.85pt"></td></tr></table>';
        mbody += "<br/><br/> Regards.<br/>" + loginUser.fullName + ", <br/>" + (validateEmail(loginUser.username) ? loginUser.username : "");

        var toList = [];
        if (mtoCust)
            toList.push(mtoCust);
        if (mtoCust2)
            toList.push(mtoCust2);
        if (mtoCust3)
            toList.push(mtoCust3);
        if (mtoCust4)
            toList.push(mtoCust4);
        if (mtoCust5)
            toList.push(mtoCust5);
        if (mtoagent)
            toList.push(mtoagent);
        if (mtoagent2)
            toList.push(mtoagent2);
        if (mtoagent3)
            toList.push(mtoagent3);
        if (mtoagent4)
            toList.push(mtoagent4);
        if (mtoagent5)
            toList.push(mtoagent5);
        if (addto)
            toList.push(addto);
        console.log(toList.toString());
        var mailData = { to: toList.toString(), subject: mailSubject, content: mbody, cc: validateEmail(loginUser.username) ? loginUser.username : "", orderId: order.id };
        
        sendRequest(`/api/fa/sendMail`, REQUEST_ACTIONS.POST, mailData, {
            successCallback: response => {
                if (response === "SUCCESS")
                    dispatch(snackbarToggle({ type: 'success', message: "Mail was sent successfully." }));
                else
                    dispatch(snackbarToggle({ type: 'error', message: "Mail not sent: " + response }));
            },
            failedCallback,
        });
    }
    const validateEmail = (email) => {
        if (!email)
            return false;
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    const initialState = {
        userId: null,
        jobNumber: null,
        userILId: loginUser?.location.code === 'IL' ? userILNames.find(user => user.name === loginUser.fullName)?.id : null || null,
        userILTracerId: null,
        userILCustomsId: null,
        incotermsId: null,
        shipperId: null,
        consigneeId: null,
        countryOfOriginId: null,
        clientRefNumber: null,
        suppliersPO: null,
        customerId: null,
        shippingModeId: 1,
        subModeId: null,
        polId: null,
        poaId: null,
        notes: null,
        coments: null,
        orderDetails: [],
        orderFCLDetails: null,
        orderStage: [],
        codeFileCount: [],
        files: [],
        numberOfPieces: null,
        weightPerPiece: null,
        width: null,
        height: null,
        length: null,
        manualVolume: null,
        manualWeight: null,
        totalweight: null,
        totalvolume: null,
        orderStageId: null,
        quantity20: null,
        quantity40: null,
        fclType20Id: null,
        fclType40Id: null,
    };

    const [state, setState] = useState(initialState);
    const [isLoaded, setIsLoaded] = useState(false);

    const onStateChange = (field, value) => {
        if (field === "userILCustomsId" || field === "userILTracerId") {
            if (value) {
                if (!Number(value)) {
                    value = null
                }
            } else
                value = null;
        }
        setState(state => ({ ...state, [field]: value }));
    }

    const dispatch = useDispatch();
    const { orders, page, size, chatCount, userSpecificOrdersCount } = useSelector(state => state.orders);
    const { formData } = useSelector(state => state.orderInfo);
    const failedCallback = useCallback(
        m => dispatch(snackbarToggle({ type: 'error', m })),
        [dispatch],
    );

    // predefined incoterms data get
    useEffect(() => {

        if (!incotermsData) {
            sendRequest('/api/incoterms', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onIncotermsDataChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, incotermsData]);

    // predefined air pol data get
    useEffect(() => {
        if (!airPolData) {
            sendRequest('/api/pol/air', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onAirPolDataChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, airPolData]);

    // predefined air poa data get
    useEffect(() => {
        if (!airPoaData) {
            sendRequest('/api/poa/air', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onAirPoaDataChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, airPoaData]);

    // predefined lcl/fcl pol data get
    useEffect(() => {
        if (!lclPolData) {
            sendRequest('/api/pol/lcl', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onLclPolDataChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, lclPolData]);

    // predefined lcl/fcl poa data get
    useEffect(() => {
        if (!lclPoaData) {
            sendRequest('/api/poa/lcl', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onLclPoaDataChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, lclPoaData]);

    // predefined fcl type 20 data get
    useEffect(() => {
        if (!fclType20Data) {
            sendRequest('/api/fclType20', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onFCLType20DataChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, fclType20Data]);

    // predefined fcl type 40 data get
    useEffect(() => {
        if (!fclType40Data) {
            sendRequest('/api/fclType40', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onFCLType40DataChange(response)),
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, fclType40Data]);

    // loading spinner toggle
    useEffect(() => {
        setIsLoaded(
            incotermsData && airPolData && airPoaData && lclPolData && lclPoaData && fclType20Data && fclType40Data,
        );
    }, [incotermsData, airPolData, airPoaData, lclPolData, lclPoaData, fclType20Data, fclType40Data]);
    // loading spinner toggle
    useEffect(() => {
        // predefined customer data get
        sendRequest('/api/serviceProvider/12?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                setBrokers(response);
            },
            failedCallback,
        });
    }, [incotermsData, airPolData, airPoaData, lclPolData, lclPoaData, fclType20Data, fclType40Data]);

    // find current order and update state
    useEffect(() => {
        if (order?.id) {

            let filesArray = [];
            if (order.orderStage) {
                for (const ordStage of order.orderStage) {
                    if (ordStage.archives && ordStage.archives.length > 0) {
                        filesArray.push(...ordStage.archives);
                    }
                }
            }
            setState(state => ({
                ...state,
                ...order,
                ...order?.orderFCLDetails,
                consigneeId: order?.consignee?.id,
                shipperId: order?.shipper?.id,
                customerId: order?.customer ? order?.customer?.id : null,
                orderStageId: order.orderStage ? order.orderStage[0].id : null,
                files: filesArray,
            }));
        }
    }, [order?.id, setState]);

    // create order
    const onHandleCreateOrder = () => {

        const {
            jobNumber,
            userILId,
            userILTracerId,
            userILCustomsId,
            incotermsId,
            shipperId,
            consigneeId,
            countryOfOriginId, originState,
            clientRefNumber,
            suppliersPO,
            customerId,
            shippingModeId,
            subModeId,
            polId,
            poaId,
            notes,
            coments,
            numberOfPieces,
            weightPerPiece,
            width,
            height,
            length,
            quantity20,
            quantity40,
            fclType20Id,
            fclType40Id,
            totalweight,
            totalvolume,
        } = state;

        let orderDetails = [];
        let orderFCLDetails = null;

        // shipping mode Air
        if (shippingModeId === 1 || shippingModeId === 2) {
            const isAddDetails = totalweight || totalvolume || numberOfPieces || weightPerPiece || width || height || length;

            // details without id
            const detailsWithoutId = state.orderDetails.map(item => {
                if (typeof item.id === 'string') {
                    return {
                        numberOfPieces: item.numberOfPieces,
                        length: item.length,
                        width: item.width,
                        height: item.height,
                        weightPerPiece: item.weightPerPiece,
                        manualWeight: null,
                        manualVolume: null,
                        totalweight: item.totalweight,
                        totalvolume: item.totalvolume,
                    };
                }
                return item;
            });

            if (isAddDetails) {
                orderDetails = [
                    ...detailsWithoutId,
                    {
                        numberOfPieces,
                        length,
                        width,
                        height,
                        weightPerPiece,
                        manualWeight: null,
                        manualVolume: null,
                        totalweight,
                        totalvolume,
                    },
                ];
            }
        }


        // shipping mode FCL
        if (shippingModeId === 3) {
            const isAddDetails = quantity20 || quantity40 || fclType20Id || fclType40Id;

            if (isAddDetails) {
                orderFCLDetails = {
                    quantity20,
                    quantity40,
                    fclType20Id,
                    fclType40Id,
                };
            }
        }

        //if (!orderDetails.length && !orderFCLDetails) {
        //  return dispatch(snackbarToggle({ type: 'error', message: 'Please add all details' }));
        //}
        const dropStatus = false;
        // create new order data
        const newOrder = {
            userId: 1,
            jobNumber,
            userILId,
            userILTracerId,
            userILCustomsId,
            dropStatus,
            incotermsId,
            shipper: { id: shipperId },
            consignee: { id: consigneeId },
            countryOfOriginId, originState,
            clientRefNumber,
            suppliersPO,
            customer: { id: customerId },
            shippingModeId,
            subModeId,
            polId,
            poaId,
            chargeWeight: 9,
            notes,
            coments,
            orderDetails,
            orderFCLDetails,
        };
        if (!customerId)
            newOrder.customer = null;

        if (polId === undefined || polId === "") {
            dispatch(snackbarToggle({ type: 'error', message: 'POL can not be empty!' }));
            return;
        }
        if (clientRefNumber == null && clientRefNumber === "") {
            dispatch(snackbarToggle({ type: 'error', message: 'Client Ref # can only be letters and numbers.' }));
            return;
        }

        var date = new Date();
        var chat = {
            'time': date.toUTCString(),
            'text': 'New Order has been placed',
            'initiator': loginUser.fullName,
            'initiatorType': loginUser.location.code == 'NL' ? '3' : '2',
            'location': loginUser.location.code,
            'ILStatus': loginUser.authorities[0].role.id == 1 ? false : loginUser.location.code == 'IL',
            'NLStatus': loginUser.authorities[0].role.id == 1 ? false : (loginUser.location.code == 'NL' || loginUser.location.code == 'US')
        }
        var chat_list = [];
        chat_list[0] = chat;
        newOrder.chat = JSON.stringify(chat_list);

        var selectedLocation = "NL"

        if (loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User' && (loginUser?.location?.code === 'NL' || loginUser?.location?.code === 'US')) {
            selectedLocation = loginUser?.location?.code;
        } else if (selectedLocationIndex == 1) {
            selectedLocation = "US"
        } else if (selectedLocationIndex == 2) {
            selectedLocation = "ALL"
        } else if (selectedLocationIndex == 3) {
            selectedLocation = "IL"
        }
        selectedLocation = customerLocationList.find(cust => cust.id == customerlocation)?.name
        if (!selectedLocation) {
            selectedLocation = "NL";
        }
        if (selectedLocation == "EXP") {
            selectedLocation = "IL";
        }
        sendRequest('/api/order?fromStage=0&loginUserId=' + loginUser?.id + '&actionOn=OrderCreation&selectedLocation=' + selectedLocation, REQUEST_ACTIONS.POST, newOrder, {
            successCallback: response => {
                if (!formData) {
                    sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, orderTableFilter?.selectedLocationIndex)}${addSearchFilter(orderTableFilter?.customerId, orderTableFilter?.urgentShipment, orderTableFilter?.userLocalNumber, orderTableFilter?.userAbroadNumber, orderTableFilter?.consigneeId, orderTableFilter?.shipperId, orderTableFilter?.countryOfOriginId, orderTableFilter?.originState, orderTableFilter?.shippingModeId, orderTableFilter?.subModeId, orderTableFilter?.userLocalId, orderTableFilter?.userTracer, orderTableFilter?.userCustoms, orderTableFilter?.userAbroadId, orderTableFilter?.dueDate, orderTableFilter?.search, orderTableFilter?.lastUpdateDate, orderTableFilter?.reminderDate, orderTableFilter?.ilReminderDate, orderTableFilter?.startReminderDate, orderTableFilter?.endReminderDate, orderTableFilter?.startIlReminderDate, orderTableFilter?.endIlReminderDate, orderTableFilter?.stageSearch, orderTableFilter?.stageStateSearch, orderTableFilter?.reminderDateAdv, orderTableFilter?.ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                        successCallback: response => {
                            sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, orderTableFilter?.selectedLocationIndex)}${addSearchFilter(orderTableFilter?.customerId, orderTableFilter?.urgentShipment, orderTableFilter?.userLocalNumber, orderTableFilter?.userAbroadNumber, orderTableFilter?.consigneeId, orderTableFilter?.shipperId, orderTableFilter?.countryOfOriginId, orderTableFilter?.originState, orderTableFilter?.shippingModeId, orderTableFilter?.subModeId, orderTableFilter?.userLocalId, orderTableFilter?.userTracer, orderTableFilter?.userCustoms, orderTableFilter?.userAbroadId, orderTableFilter?.dueDate, orderTableFilter?.search, orderTableFilter?.lastUpdateDate, orderTableFilter?.reminderDate, orderTableFilter?.ilReminderDate, orderTableFilter?.startReminderDate, orderTableFilter?.endReminderDate, orderTableFilter?.startIlReminderDate, orderTableFilter?.endIlReminderDate, orderTableFilter?.stageSearch, orderTableFilter?.stageStateSearch, orderTableFilter?.reminderDateAdv, orderTableFilter?.ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                                successCallback: response => {
                                    dispatch(onOrdersCountChange(response?.ordersCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                                    dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                                    dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                                    dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                                },
                                failedCallback,
                            });
                            setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                            dispatch(
                                snackbarToggle({
                                    type: 'success',
                                    message: 'Order created successfully',
                                }),
                            );
                            onHandleCancel();
                        },
                        failedCallback,
                    });
                    return;
                }
                formData.append('orderStageId', response.orderStage[0].id);
                formData.append('orderId', response.id);
                formData.append('loginUserId', loginUser.id);
                sendRequest('/api/upload', REQUEST_ACTIONS.POST, formData, {
                    successCallback: () => {
                        sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, orderTableFilter?.selectedLocationIndex)}${addSearchFilter(orderTableFilter?.customerId, orderTableFilter?.urgentShipment, orderTableFilter?.userLocalNumber, orderTableFilter?.userAbroadNumber, orderTableFilter?.consigneeId, orderTableFilter?.shipperId, orderTableFilter?.countryOfOriginId, orderTableFilter?.originState, orderTableFilter?.shippingModeId, orderTableFilter?.subModeId, orderTableFilter?.userLocalId, orderTableFilter?.userTracer, orderTableFilter?.userCustoms, orderTableFilter?.userAbroadId, orderTableFilter?.dueDate, orderTableFilter?.search, orderTableFilter?.lastUpdateDate, orderTableFilter?.reminderDate, orderTableFilter?.ilReminderDate, orderTableFilter?.startReminderDate, orderTableFilter?.endReminderDate, orderTableFilter?.startIlReminderDate, orderTableFilter?.endIlReminderDate, orderTableFilter?.stageSearch, orderTableFilter?.stageStateSearch, orderTableFilter?.reminderDateAdv, orderTableFilter?.ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                            successCallback: response => {
                                sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, orderTableFilter?.selectedLocationIndex)}${addSearchFilter(orderTableFilter?.customerId, orderTableFilter?.urgentShipment, orderTableFilter?.userLocalNumber, orderTableFilter?.userAbroadNumber, orderTableFilter?.consigneeId, orderTableFilter?.shipperId, orderTableFilter?.countryOfOriginId, orderTableFilter?.originState, orderTableFilter?.shippingModeId, orderTableFilter?.subModeId, orderTableFilter?.userLocalId, orderTableFilter?.userTracer, orderTableFilter?.userCustoms, orderTableFilter?.userAbroadId, orderTableFilter?.dueDate, orderTableFilter?.search, orderTableFilter?.lastUpdateDate, orderTableFilter?.reminderDate, orderTableFilter?.ilReminderDate, orderTableFilter?.startReminderDate, orderTableFilter?.endReminderDate, orderTableFilter?.startIlReminderDate, orderTableFilter?.endIlReminderDate, orderTableFilter?.stageSearch, orderTableFilter?.stageStateSearch, orderTableFilter?.reminderDateAdv, orderTableFilter?.ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                                    successCallback: response => {
                                        dispatch(onOrdersCountChange(response?.ordersCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                                    },
                                    failedCallback,
                                });
                                setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                dispatch(
                                    snackbarToggle({
                                        type: 'success',
                                        message: 'Order created successfully',
                                    }),
                                );
                                onHandleCancel();
                            },
                            failedCallback,
                        });
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };

    // update order
    const onHandleUpdateOrder = () => {
        const {
            jobNumber,
            userILId,
            userILTracerId,
            userILCustomsId,
            incotermsId,
            shipperId,
            consigneeId,
            countryOfOriginId, originState,
            clientRefNumber,
            suppliersPO,
            customerId,
            shippingModeId,
            subModeId,
            polId,
            poaId,
            notes,
            coments,
            numberOfPieces,
            weightPerPiece,
            width,
            height,
            length,
            quantity20,
            quantity40,
            fclType20Id,
            fclType40Id,
            totalweight,
            totalvolume,
        } = state;

        let orderDetails = [];
        let orderFCLDetails = null;

        // shipping mode Air
        if (shippingModeId === 1 || shippingModeId === 2) {
            const isAddDetails = totalweight || totalvolume || numberOfPieces || weightPerPiece || width || height || length;

            // details without id
            const detailsWithoutId = state.orderDetails.map(item => {
                if (typeof item.id === 'string') {
                    return {
                        numberOfPieces: item.numberOfPieces,
                        length: item.length,
                        width: item.width,
                        height: item.height,
                        weightPerPiece: item.weightPerPiece,
                        manualWeight: null,
                        manualVolume: null,
                        totalweight: item.totalweight,
                        totalvolume: item.totalvolume,
                    };
                }
                return item;
            });

            if (isAddDetails) {
                orderDetails = [
                    ...detailsWithoutId,
                    {
                        numberOfPieces,
                        length,
                        width,
                        height,
                        weightPerPiece,
                        manualWeight: null,
                        manualVolume: null,
                        totalweight,
                        totalvolume,
                    },
                ];
            } else {
                orderDetails = detailsWithoutId;
            }

            // if (!orderDetails.length) {
            //   return dispatch(snackbarToggle({ type: 'error', message: 'Please add all details' }));
            // }
        }

        // // shipping mode LCL
        // if (shippingModeId === 2) {
        //   // details without id
        //   const detailsWithoutId = state.orderDetails.map(item => {
        //     if (typeof item.id === 'string') {
        //       return {
        //         numberOfPieces: item.numberOfPieces,
        //         length: item.length,
        //         width: item.width,
        //         height: item.height,
        //         weightPerPiece: item.weightPerPiece,
        //         manualWeight: item.manualWeight,
        //         manualVolume: item.manualVolume,
        //       };
        //     }
        //     return item;
        //   });

        //   orderDetails = [
        //     ...detailsWithoutId,
        //     {
        //       numberOfPieces,
        //       length,
        //       width,
        //       height,
        //       weightPerPiece,
        //       manualWeight,
        //       manualVolume,
        //     },
        //   ];
        // }

        // shipping mode FCL
        if (shippingModeId === 3) {
            const isAddDetails = quantity20 || quantity40 || fclType20Id || fclType40Id;

            if (isAddDetails) {
                orderFCLDetails = {
                    quantity20,
                    quantity40,
                    fclType20Id,
                    fclType40Id,
                };
            }
        }

        // updated order data
        const updatedOrder = {
            ...order,
            jobNumber,
            userILId,
            userILTracerId,
            userILCustomsId,
            incotermsId,
            shipper: { id: shipperId },
            consignee: { id: consigneeId },
            countryOfOriginId, originState,
            clientRefNumber,
            suppliersPO,
            customer: { id: customerId },
            shippingModeId,
            subModeId,
            polId,
            poaId,
            notes,
            coments,
            orderDetails,
            orderFCLDetails,
        };
        if (!customerId) {
            updatedOrder.customer = null;
        }
        sendRequest(`/api/order?fromStage=0&loginUserId=` + loginUser?.id + `&actionOn=OrderUpdate`, REQUEST_ACTIONS.PUT, updatedOrder, {
            successCallback: () => {
                sendRequest(`/api/order?${addOrderFilter(loginUser, pageName, orderTableFilter?.selectedLocationIndex)}${addSearchFilter(orderTableFilter?.customerId, orderTableFilter?.urgentShipment, orderTableFilter?.userLocalNumber, orderTableFilter?.userAbroadNumber, orderTableFilter?.consigneeId, orderTableFilter?.shipperId, orderTableFilter?.countryOfOriginId, orderTableFilter?.originState, orderTableFilter?.shippingModeId, orderTableFilter?.subModeId, orderTableFilter?.userLocalId, orderTableFilter?.userTracer, orderTableFilter?.userCustoms, orderTableFilter?.userAbroadId, orderTableFilter?.dueDate, orderTableFilter?.search, orderTableFilter?.lastUpdateDate, orderTableFilter?.reminderDate, orderTableFilter?.ilReminderDate, orderTableFilter?.startReminderDate, orderTableFilter?.endReminderDate, orderTableFilter?.startIlReminderDate, orderTableFilter?.endIlReminderDate, orderTableFilter?.stageSearch, orderTableFilter?.stageStateSearch, orderTableFilter?.reminderDateAdv, orderTableFilter?.ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                        dispatch(
                            snackbarToggle({
                                type: 'success',
                                message: 'Order updated successfully',
                            }),
                        );
                        onHandleCancel();
                    },
                    failedCallback,
                });
            },
            failedCallback,
        });
    };

    return (
        <Box component='form' noValidate autoComplete='off'>
            <Grid container spacing={2} position='relative'>
                {!isLoaded ? (
                    <Grid container item justifyContent='center' mt={2}>
                        <CircularProgress />
                    </Grid>
                ) : (
                        <Fragment>
                            {(copyOrder ? true : !order?.id) && <Grid item container style={{ width: "100%" }}>
                            <Autocomplete
                                field='customerlocation'
                                label='Location'
                                lg={null}
                                value={customerlocation}
                                onChange={(id, value) => setCustomerlocation(value)}
                                options={customerLocationList}
                            />
                        </Grid>}
                            <OrderInformation customerlocation={customerlocation} order={order} copyOrder={copyOrder} brokers={brokers} loginUser={loginUser} orderId={order?.id} state={state} onStateChange={onStateChange} pageName={pageName} onHandleCloseParentModal={onHandleCancel} />
                        {state.shippingModeId === 1 ? <AirDetails state={state} onStateChange={onStateChange} /> : null}
                        {state.shippingModeId === 2 ? <LCLDetails state={state} onStateChange={onStateChange} /> : null}
                        {state.shippingModeId === 3 ? <FCLDetails state={state} onStateChange={onStateChange} /> : null}
                        {showMail && <Grid container item lg={12}>
                            <TextField
                                lg={12}
                                required
                                dissabled
                                style={{ padding: "5px" }}
                                type="Text"
                                label='Subject'
                                value={mailSubject}
                            />
                            <Grid container item lg={12}>
                                <Grid lg={12}>
                                    <br /> <div style={{ textAlign: "center", width: "100%" }}><b>{order.locationId === "2" ? "Export " : "Import "} JOB ORDER No. - {order.jobNumber}</b></div> <br /><br />
                                    <b>Date: </b> {getTodayDate()}<br /><br />
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td style={{ background: "lightgrey", width: "25%" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Shipper:</b></td>
                                            <td>{order.shipper.companyName}
                                                {order.shipper.address1 && <br></br>}{order.shipper.address1 ? order.shipper.address1 : ""}
                                                {order.shipper.address2 && <br></br>}{order.shipper.address2 ? order.shipper.address2 : ""}
                                                    {order.shipper.city && <br></br>}{order.shipper.city ? order.shipper.city : ""}
                                                {order.shipper.zipCode && <br></br>}{order.shipper.zipCode ? order.shipper.zipCode : ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Contact:</b></td>
                                                <td>{order.shipper.name ? order.shipper.name : ""}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Email:</b></td>
                                            <td>
                                                {order.shipper.email ? order.shipper.email : ""}{order.shipper.email && <br></br>}
                                                {order.shipper.email2 ? order.shipper.email2 : ""}{order.shipper.email2 && <br></br>}
                                                {order.shipper.email3 ? order.shipper.email3 : ""}{order.shipper.email3 && <br></br>}
                                                {order.shipper.email4 ? order.shipper.email4 : ""}{order.shipper.email4 && <br></br>}
                                                {order.shipper.email5 ? order.shipper.email5 : ""}{order.shipper.email5 && <br></br>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "lightgrey" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Tel:</b></td>
                                                <td>{order.shipper.businessPhone ? order.shipper.businessPhone : (order.shipper.mobile ? order.shipper.mobile : "")}</td>
                                        </tr>
                                    </table>
                                    <br />
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td style={{ background: "lightgrey", width: "25%" }}><b style={{ marginRight: "5px", marginLeft: "5px" }}>Consignee:</b></td>
                                            <td>{order.consignee.companyName}
                                                {order.consignee.address1 && <br></br>}{order.consignee.address1 ? order.consignee.address1 : ""}
                                                {order.consignee.address2 && <br></br>}{order.consignee.address2 ? order.consignee.address2 : ""}
                                                {order.consignee.city && <br></br>}{order.consignee.city ? order.consignee.city : ""}
                                                {order.consignee.zipCode && <br></br>}{order.consignee.zipCode ? order.consignee.zipCode : ""}
                                            </td>
                                        </tr>
                                        </table>
                                        <Grid continer lg={12} item style={{ margin: "5px" }}>
                                            <MultilineField
                                                required
                                                xs={12}
                                                sm={null}
                                                md={null}
                                                lg={null}
                                                label='Comments'
                                                value={mailBody}
                                                onChange={event => setMailBody(event.target.value)}
                                            />
                                        </Grid>
                                    <table style={{ width: "100%" }}>
                                        <tr style={{ background: "lightgrey", width: "100%", textAlign: "center" }}>SHIPPING INFORMATION </tr>
                                    </table>
                                    <table style={{ width: "100%" }}>
                                        <tr>
                                            <td style={{ background: "lightgrey", width: "25%" }}>Consignee Reference</td>
                                                <td style={{ width: "25%" }}>{order.clientRefNumber}</td>
                                            <td style={{ background: "lightgrey", width: "25%" }}>Origin</td>
                                                <td style={{ width: "25%" }}>{countryOfOriginData.find((ctr) => ctr.id == order.countryOfOriginId)?.name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "lightgrey" }}>Shipper reference</td>
                                                <td>{order.suppliersPO}</td>
                                            <td style={{ background: "lightgrey" }}>Port of loading</td>
                                            <td>{getFrom(order.polId, order.shippingModeId, false)}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "lightgrey" }}>No. of packs</td>
                                            <td>{getTotalPieces()}</td>
                                            <td style={{ background: "lightgrey" }}>Port of discharge</td>
                                            <td>{getTo(order.poaId, order.shippingModeId)}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "lightgrey" }}>Kind of packages</td>
                                            <td>{getKindOfPieces()}</td>
                                            <td style={{ background: "lightgrey" }}>Shipment Method</td>
                                            <td>{getType(order.shippingModeId)}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "lightgrey" }}>Weight Kg</td>
                                            <td>{getTotalWeight()}</td>
                                            <td style={{ background: "lightgrey" }}>Shipment Terms</td>
                                            <td>{incotermsData.find(dat => dat.id == order.incotermsId)?.name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "lightgrey" }}>Volume CBM.</td>
                                            <td>{getChWeight()}</td>
                                            <td style={{ background: "lightgrey" }}>Consignee VAT.</td>
                                            <td>{order.consignee.uatnumber}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ background: "lightgrey" }}>Type</td>
                                            <td>{subModeData.find(dat => dat.id == order.subModeId)?.name}</td>
                                            <td style={{ background: "lightgrey" }}></td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </Grid>
                            </Grid>
                            <Grid container item lg={12}>
                                <br />
                                Regards.
                            </Grid>
                            <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                <TextField
                                    lg={12}
                                    style={{ padding: "5px", marginTop: "20px" }}
                                    type="Text"
                                    label='Additional E-Mails'
                                    value={mailToAdd}
                                    onChange={event => setMailToAdd(event.target.value)}
                                />
                                {!useMailToAdd && <Grid container item lg={12} style={{ color: "red" }}> * Additional emails are invalid.</Grid>}
                                </Grid>

                                <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent} onChange={event => useMailToAgent ? setUseMailToAgent(false) : setUseMailToAgent(true)} />
                                            }
                                            label={"Broker email: " + mailToAgent}
                                        />
                                    </view>
                                </Grid>
                                {!mailToAgentValidation && <Grid container item lg={12} style={{ color: "red" }}> * Broker email for: {brokerName} is invalid.</Grid>}

                                {mailToAgent2 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent2} onChange={event => useMailToAgent2 ? setUseMailToAgent2(false) : setUseMailToAgent2(true)} />
                                            }
                                            label={"Broker email2: " + mailToAgent2}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent2 && !mailToAgentValidation2) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email2 for: {brokerName} is invalid.</Grid>}

                                {mailToAgent3 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent3} onChange={event => useMailToAgent3 ? setUseMailToAgent3(false) : setUseMailToAgent3(true)} />
                                            }
                                            label={"Broker email 3: " + mailToAgent3}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent3 && !mailToAgentValidation3) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email3 for: {brokerName} is invalid.</Grid>}

                                {mailToAgent4 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent4} onChange={event => useMailToAgent4 ? setUseMailToAgent4(false) : setUseMailToAgent4(true)} />
                                            }
                                            label={"Broker email 4: " + mailToAgent4}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent4 && !mailToAgentValidation4) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email4 for: {brokerName} is invalid.</Grid>}

                                {mailToAgent5 && <Grid container lg={12} style={{ borderTop: "1px solid black", marginTop: "25px" }}>
                                    <view style={{ marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToAgent5} onChange={event => useMailToAgent5 ? setUseMailToAgent5(false) : setUseMailToAgent5(true)} />
                                            }
                                            label={"Broker email5: " + mailToAgent5}
                                        />
                                    </view>
                                </Grid>}
                                {(mailToAgent5 && !mailToAgentValidation5) && <Grid container item lg={12} style={{ color: "red" }}> * Broker email5 for: {brokerName} is invalid.</Grid>}

                                <Grid container lg={12}>
                                    <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToCustomer} onChange={event => useMailToCustomer ? setUseMailToCustomer(false) : setUseMailToCustomer(true)} />
                                            }
                                            label={"Consignee email: " + order.consignee.email}
                                        />
                                    </view>
                                </Grid>
                                {!mailToCustomerValidation && <Grid container item style={{ color: "red" }}>* Consignee email for: {order.consignee.companyName} is invalid.</Grid>}

                                {order.consignee.email2 && <Grid container lg={12}>
                                    <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToCustomer2} onChange={event => useMailToCustomer2 ? setUseMailToCustomer2(false) : setUseMailToCustomer2(true)} />
                                            }
                                            label={"Consignee email2: " + order.consignee.email2}
                                        />
                                    </view>
                                </Grid>}
                                {(order.consignee.email2 && !mailToCustomerValidation2) && <Grid container item style={{ color: "red" }}>* Consignee email2 for: {order.consignee.companyName} is invalid.</Grid>}

                                {order.consignee.email3 && <Grid container lg={12}>
                                    <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToCustomer3} onChange={event => useMailToCustomer3 ? setUseMailToCustomer3(false) : setUseMailToCustomer3(true)} />
                                            }
                                            label={"Consignee email3: " + order.consignee.email3}
                                        />
                                    </view>
                                </Grid>}
                                {(order.consignee.email3 && !mailToCustomerValidation3) && <Grid container item style={{ color: "red" }}>* Consignee email3 for: {order.consignee.companyName} is invalid.</Grid>}

                                {order.consignee.email4 && <Grid container lg={12}>
                                    <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToCustomer4} onChange={event => useMailToCustomer4 ? setUseMailToCustomer4(false) : setUseMailToCustomer4(true)} />
                                            }
                                            label={"Consignee email 4: " + order.consignee.email4}
                                        />
                                    </view>
                                </Grid>}
                                {(order.consignee.email4 && !mailToCustomerValidation4) && <Grid container item style={{ color: "red" }}>* Consignee email4 for: {order.consignee.companyName} is invalid.</Grid>}

                                {order.consignee.email5 && <Grid container lg={12}>
                                    <view style={{ marginTop: "10px", marginLeft: "0", maxWidth: "500px" }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={useMailToCustomer5} onChange={event => useMailToCustomer5 ? setUseMailToCustomer5(false) : setUseMailToCustomer5(true)} />
                                            }
                                            label={"Consignee email 5: " + order.consignee.email5}
                                        />
                                    </view>
                                </Grid>}
                                {(order.consignee.email5 && !mailToCustomerValidation5) && <Grid container item style={{ color: "red" }}>* Consignee email5 for: {order.consignee.companyName} is invalid.</Grid>}
                            <Grid container lg={12}>
                                <Button
                                    variant='contained'
                                    style={{ margin: "15px", marginLeft: "0", maxWidth: "150px" }}
                                    onClick={sendMail}>
                                    Send Mail
                                </Button>
                            </Grid>
                        </Grid>}
                        <Grid container lg={12} item justifyContent='flex-end' flexWrap="nowrap" style={{ maxHeight: "37px" }}>
                            {order?.id && <Button variant='contained' sx={{ mr: 2, height: "37px" }} onClick={setShowMail}>
                                Mail
                            </Button>}
                                <Grid container item justifyContent='flex-end'>
                                    <Button variant='contained' sx={{ mr: 2 }} onClick={copyOrder ? onHandleCreateOrder : (order?.id ? onHandleUpdateOrder : onHandleCreateOrder)}>
                                    Save
                                </Button>
                                <Button onClick={onHandleCancel} variant='contained' color='secondary'>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        </Box>
    );
};

export default OrderForm;
