import { Grid, Typography } from '@mui/material';
import AuditLogsTable from './AuditLogsTable';

const AuditLogs = () => {
  return (
    <Grid container>
      <AuditLogsTable />
    </Grid>
  );
};

export default AuditLogs;
