import { Fragment, useCallback, useEffect, useState, useRef } from 'react';

import { Box, Grid, Button, CircularProgress } from '@mui/material';
import { MultilineField, TextField } from 'style-guide';
import { sendRequest, REQUEST_ACTIONS, setOrderData } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { onChatListChange, getOrders, onOrdersCountChange, onChatCountChange, onChatCountTChange, onChatCountCChange, onChatCountCTChange, onUserSpecificOrdersCountChange, onUnConfirmedOrdersCountChange, onPageChange } from 'reducers/order-reducers/ordersSlicer';
import { addOrderFilter, addSearchFilter, usePrevious } from 'Utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ilChatNameStyle = { color: 'blue' };
const nlChatNameStyle = { color: 'orange' };
const adChatNameStyle = { color: 'black' };

const ChatDialog = ({ onHandleCancel, order, loginUser, pageName, consigneeNameDisplay, shipperNameDisplay }) => {

    const [state, setState] = useState();
    const [chatText, setChatText] = useState("");
    const [chatList, setChatList] = useState(JSON.parse(order['chat'] ? order['chat'] : '[]'));
    const [chatListT, setChatListT] = useState(JSON.parse(order['chatTracer'] ? order['chatTracer'] : '[]'));
    const [chatListC, setChatListC] = useState(JSON.parse(order['chatCustoms'] ? order['chatCustoms'] : '[]'));
    const [chatListCT, setChatListCT] = useState(JSON.parse(order['chatCT'] ? order['chatCT'] : '[]'));
    const [isLoaded, setIsLoaded] = useState(false);
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const [ilUsersLoaded, setIlUsersLoaded] = useState(false);
    const [nlUsersLoaded, setNlUsersLoaded] = useState(false);
    const [usUsersLoaded, setUSUsersLoaded] = useState(false);
    const [chatType, setChatType] = useState(1);
    const [userLoaded, setUsersLoaded] = useState(0);
    const [updatedChatView, setUpdatedChatView] = useState(false);
    const [updatedChatViewC, setUpdatedChatViewC] = useState(false);
    const [updatedChatViewT, setUpdatedChatViewT] = useState(false);
    const [updatedChatViewCT, setUpdatedChatViewCT] = useState(false);
    const [ilUserImporters, setIlUsersImporters] = useState([]);
    const [ilUserTracers, setIlUsersTracers] = useState([]);
    const [ilUserCustoms, setIlUsersCustoms] = useState([]);
    const [varInterval, setVarInterval] = useState();
    const { page, size, chatCount, userSpecificOrdersCount } = useSelector(state => state.orders);
    const prevPage = usePrevious({ page });
    const { chatCountC } = useSelector(state => state.orders);
    const { chatCountT } = useSelector(state => state.orders);
    const { chatCountCT } = useSelector(state => state.orders);
    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        customerId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userTracer,
        userCustoms,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        search,
        urgentShipment,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.orderTableFilter);
    //var messagesEnd;

    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const failedCallback1 = useCallback(message => { }, [dispatch]);
    const mounted = useRef(false);
    
    const setDefaultChat = () => {
        setChatType(1);
        updateSeen(1);
    }
    const setTracerChat = () => {
        setChatType(2);
        updateSeen(2);
    }
    const setCustomsChat = () => {
        setChatType(3);
        updateSeen(3);
    }
    const setCTChat = () => {
        setChatType(4);
        updateSeen(4);
    }
    const [unseenCount, setUnseenCount] = useState({});
    const getNewChatNotifications = () => {
        if (loginUser.authority != "Admin") {
            sendRequest(
                `/api/chat/list?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
                REQUEST_ACTIONS.GET,
                '',
                {
                    successCallback: response => {
                        //setChatList(response);
                        dispatch(onChatListChange(response))
                    },
                    failedCallback,
                },
            );
        }
    }
    const getUnseenCount = () => {
        if (chatList?.length == 0 || !ilUsersLoaded || !nlUsersLoaded || !usUsersLoaded) {
            return;
        }
        var count = {};
        count.i = 0;
        count.t = 0;
        count.c = 0;
        count.ct = 0;
        var userIL = order.userILId;
        var userILTracer = order.userILTracerId;
        var userILCustoms = order.userILCustomsId;
        var userNL = order.orderStage[0].orderConfirmationStage[0]?.userNLId;
        var lenIlUserImporters = ilUserImporters.length;
        var lenIlUserTracers = ilUserTracers.length;
        var lenIlUserCustoms = ilUserCustoms.length;
        var chat_list = chatList;
        var chat_listT = chatListT;
        var chat_listC = chatListC;
        var chat_listCT = chatListCT;
        var len = chat_list ? chat_list.length : 0;
        var lenT = chat_listT ? chat_listT.length : 0;
        var lenC = chat_listC ? chat_listC.length : 0;
        var lenCT = chat_listCT ? chat_listCT.length : 0;
        var lengthNL = nlUsers.length;
        var lengthUS = usUsers.length;
        // warehouse/importer chats
        for (var i = 0; i < lenIlUserImporters; i++) {
            if (ilUserImporters[i].fullName == loginUser.fullName && userIL == ilUserImporters[i].id) {
                for (var j = 0; j < len; j++) {
                    if (!chat_list[j]['ILStatus']) {
                        count.i++;
                    }
                }
            }
        }
        for (var i = 0; i < lengthNL; i++) {
            if (nlUsers[i].fullName == loginUser.fullName && userNL == nlUsers[i].id) {
                for (var j = 0; j < len; j++) {
                    if (!chat_list[j]['NLStatus']) {
                        count.i++;
                    }
                }
            }
        }
        for (var i = 0; i < lengthUS; i++) {
            if (usUsers[i].fullName == loginUser.fullName && userNL == usUsers[i].id) {
                for (var j = 0; j < len; j++) {
                    if (!chat_list[j]['NLStatus']) {
                        count.i++;
                    }
                }
            }
        }
        // tracer chat
        for (var i = 0; i < lenIlUserImporters; i++) {
            if (ilUserImporters[i].fullName == loginUser.fullName && userIL == ilUserImporters[i].id) {
                for (var j = 0; j < lenT; j++) {
                    if (!chat_listT[j]['ILStatus']) {
                        count.t++;
                    }
                }
            }
        }
        for (var i = 0; i < lenIlUserTracers; i++) {
            if (ilUserTracers[i].fullName == loginUser.fullName && userILTracer == ilUserTracers[i].id) {
                for (var j = 0; j < lenT; j++) {
                    if (!chat_listT[j]['NLStatus']) {
                        count.t++;
                    }
                }
            }
        }
        // customs chat
        for (var i = 0; i < lenIlUserImporters; i++) {
            if (ilUserImporters[i].fullName == loginUser.fullName && userIL == ilUserImporters[i].id) {
                for (var j = 0; j < lenC; j++) {
                    if (!chat_listC[j]['ILStatus']) {
                        count.c++;
                    }
                }
            }
        }
        for (var i = 0; i < lenIlUserCustoms; i++) {
            if (ilUserCustoms[i].fullName == loginUser.fullName && userILCustoms == ilUserCustoms[i].id) {
                for (var j = 0; j < lenC; j++) {
                    if (!chat_listC[j]['NLStatus']) {
                        count.c++;
                    }
                }
            }
        }
        // ct chat
        for (var i = 0; i < lenIlUserTracers; i++) {
            if (ilUserTracers[i].fullName == loginUser.fullName && userILTracer == ilUserTracers[i].id) {
                for (var j = 0; j < lenCT; j++) {
                    if (!chat_listCT[j]['ILStatus']) {
                        count.ct++;
                    }
                }
            }
        }
        for (var i = 0; i < lenIlUserCustoms; i++) {
            if (ilUserCustoms[i].fullName == loginUser.fullName && userILCustoms == ilUserCustoms[i].id) {
                for (var j = 0; j < lenCT; j++) {
                    if (!chat_listCT[j]['NLStatus']) {
                        count.ct++;
                    }
                }
            }
        }
        setUnseenCount(count);
    }
    const updateSeen = (ct) => {
        if (chatList?.length != 0 && ilUsersLoaded && nlUsersLoaded && usUsersLoaded) {
            var userIL = order.userILId;
            var userILTracer = order.userILTracerId;
            var userILCustoms = order.userILCustomsId;
            var userNL = order.orderStage[0].orderConfirmationStage[0]?.userNLId;
            let o1 = { ...order }
            if (!order['chat'] || order['chat'] == undefined)
                return;
            var chat_list = JSON.parse(order['chat']);
            var chat_listT = JSON.parse(order['chatTracer']);
            var chat_listC = JSON.parse(order['chatCustoms']);
            var chat_listCT = JSON.parse(order['chatCT']);
            var len = chat_list ? chat_list.length : 0;
            var lenT = chat_listT ? chat_listT.length : 0;
            var lenC = chat_listC ? chat_listC.length : 0;
            var lenCT = chat_listCT ? chat_listCT.length : 0;
            var lengthIL = ilUsers.length;
            var lengthNL = nlUsers.length;
            var lengthUS = usUsers.length;
            var lenIlUserImporters = ilUserImporters.length;
            var lenIlUserTracers = ilUserTracers.length;
            var lenIlUserCustoms = ilUserCustoms.length;
            var changeMade = false;
            var exists = false;
            if (ct == 1 && !updatedChatView) {
                for (var i = 0; i < lenIlUserImporters; i++) {
                    if (ilUserImporters[i].fullName == loginUser.fullName && userIL == ilUserImporters[i].id) {
                        for (var j = 0; j < len; j++) {
                            if (!chat_list[j]['ILStatus']) {
                                changeMade = true;
                                chat_list[j]['ILStatus'] = true;
                            }
                        }
                    }
                }
                if (!exists) {
                    for (var i = 0; i < lengthNL; i++) {
                        if (nlUsers[i].fullName == loginUser.fullName && userNL == nlUsers[i].id) {
                            for (var j = 0; j < len; j++) {
                                if (!chat_list[j]['NLStatus']) {
                                    changeMade = true;
                                    chat_list[j]['NLStatus'] = true;
                                }
                            }
                        }
                    }
                    for (var i = 0; i < lengthUS; i++) {
                        if (usUsers[i].fullName == loginUser.fullName && userNL == usUsers[i].id) {
                            for (var j = 0; j < len; j++) {
                                if (!chat_list[j]['NLStatus']) {
                                    changeMade = true;
                                    chat_list[j]['NLStatus'] = true;
                                }
                            }
                        }
                    }
                }
                setUpdatedChatView(true);
            } else if (ct == 2 && !updatedChatViewT) {
                for (var i = 0; i < lenIlUserImporters; i++) {
                    if (ilUserImporters[i].fullName == loginUser.fullName && userIL == ilUserImporters[i].id) {
                        for (var j = 0; j < lenT; j++) {
                            if (!chat_listT[j]['ILStatus']) {
                                changeMade = true;
                                chat_listT[j]['ILStatus'] = true;
                            }
                        }
                    }
                }
                if (!exists) {
                    for (var i = 0; i < lenIlUserTracers; i++) {
                        if (ilUserTracers[i].fullName == loginUser.fullName && userILTracer == ilUserTracers[i].id) {
                            for (var j = 0; j < lenT; j++) {
                                if (!chat_listT[j]['NLStatus']) {
                                    changeMade = true;
                                    chat_listT[j]['NLStatus'] = true;
                                }
                            }
                        }
                    }
                }
                setUpdatedChatViewT(true);
            } else if (ct == 3 && !updatedChatViewC) {
                for (var i = 0; i < lenIlUserImporters; i++) {
                    if (ilUserImporters[i].fullName == loginUser.fullName && userIL == ilUserImporters[i].id) {
                        for (var j = 0; j < lenC; j++) {
                            if (!chat_listC[j]['ILStatus']) {
                                changeMade = true;
                                chat_listC[j]['ILStatus'] = true;
                            }
                        }
                    }
                }
                if (!exists) {
                    for (var i = 0; i < lenIlUserCustoms; i++) {
                        if (ilUserCustoms[i].fullName == loginUser.fullName && userILCustoms == ilUserCustoms[i].id) {
                            for (var j = 0; j < lenC; j++) {
                                if (!chat_listC[j]['NLStatus']) {
                                    changeMade = true;
                                    chat_listC[j]['NLStatus'] = true;
                                }
                            }
                        }
                    }
                }
                setUpdatedChatViewC(true);
            } else if (ct == 4 && !updatedChatViewCT) {
                for (var i = 0; i < lenIlUserTracers; i++) {
                    if (ilUserTracers[i].fullName == loginUser.fullName && userILTracer == ilUserTracers[i].id) {
                        for (var j = 0; j < lenCT; j++) {
                            if (!chat_listCT[j]['ILStatus']) {
                                changeMade = true;
                                chat_listCT[j]['ILStatus'] = true;
                            }
                        }
                    }
                }
                if (!exists) {
                    for (var i = 0; i < lenIlUserCustoms; i++) {
                        if (ilUserCustoms[i].fullName == loginUser.fullName && userILCustoms == ilUserCustoms[i].id) {
                            for (var j = 0; j < lenCT; j++) {
                                if (!chat_listCT[j]['NLStatus']) {
                                    changeMade = true;
                                    chat_listCT[j]['NLStatus'] = true;
                                }
                            }
                        }
                    }
                }
                setUpdatedChatViewCT(true);
            }
            if (changeMade) {
                o1.chat = JSON.stringify(chat_list)
                o1.chatTracer = JSON.stringify(chat_listT)
                o1.chatCustoms = JSON.stringify(chat_listC)
                o1.chatCT = JSON.stringify(chat_listCT)
                sendRequest('/api/order?fromStage=0&loginUserId=' + loginUser?.id + '&actionOn=ChatSeen', REQUEST_ACTIONS.POST, o1, {
                    successCallback: response => {
                        setChatListFx1(JSON.parse(o1.chat));
                        setChatListT(JSON.parse(o1.chatTracer));
                        setChatListC(JSON.parse(o1.chatCustoms));
                        setChatListCT(JSON.parse(o1.chatCT));
                        getUnseenCount();
                        sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId,urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                            successCallback: response => {
                                dispatch(onOrdersCountChange(response?.ordersCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                                dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                                dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                                dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount)); },
                            failedCallback,
                        });
                        getNewChatNotifications();

                        sendRequest(
                            `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
                            REQUEST_ACTIONS.GET,
                            '',
                            {
                                successCallback: response => {
                                    setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                    if (prevPage?.page == page && addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                                        dispatch(onPageChange(0));
                                    }
                                },
                                failedCallback,
                            },
                        );
                    },
                    failedCallback,
                });
            }
        }
    };

    const setChatListFx = (chat) => {
        setChatList(chat);
        updateSeen(1);
    }

    useEffect(() => {
        getUnseenCount
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    useEffect(() => {
        getUnseenCount();
        if (!isLoaded) {
            setVarInterval(setInterval(() => {
                sendRequest('/api/order/' + order['id'], REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {
                        setChatListFx(JSON.parse(response['chat']));
                        setChatListT(JSON.parse(response['chatTracer']));
                        setChatListC(JSON.parse(response['chatCustoms']));
                        setChatListCT(JSON.parse(response['chatCT']));
                    }
                    ,
                    failedCallback1,
                });
            }, 15000));
            setIsLoaded(true);
        }
        return () => {
            if (!mounted.current) {
                clearInterval(varInterval);
            }
        }
    }, [chatList, setChatList, order, updateSeen,]);

    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); setNlUsersLoaded(true) },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); setUSUsersLoaded(true) },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    setIlUsers(response);
                    setIlUsersLoaded(true);
                    var importers = response.filter(name => name.department == 1 || name.department == null);
                    var tracers = response.filter(name => name.department == 2);
                    var customs = response.filter(name => name.department == 3);
                    setIlUsersImporters(importers);
                    setIlUsersTracers(tracers);
                    setIlUsersCustoms(customs);

                },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);




    useEffect(() => {
        // for construction
        if (chatList?.length != 0 && ilUsersLoaded && nlUsersLoaded && usUsersLoaded && !updatedChatView) {
            updateSeen(1)
        }
    }, [setUpdatedChatView, updatedChatView, updateSeen, chatList, ilUsersLoaded, nlUsersLoaded, usUsersLoaded]);


    const setChatListFx1 = (chat) => {
        setChatList(chat);
        setChatText("");
        setUpdatedChatView(true);
    }

    const onHandleSendChat = (statusChatButton) => {
        if (chatType == 1) {
            onHandleSendChat1(statusChatButton)
        }
        if (chatType == 2) {
            onHandleSendChatT(statusChatButton)
        }
        if (chatType == 3) {
            onHandleSendChatC(statusChatButton)
        }
        if (chatType == 4) {
            onHandleSendChatCT(statusChatButton)
        }
    }
    const onHandleSendChat1 = (statusChatButton) => {
        if (order.orderStage[0].orderConfirmationStage.length == 0) {
            //dispatch(snackbarToggle({ type: 'error', message: 'Order Conformation should be completed before chat can ne used.' }))
            // return;
        }
        if (chatText == "") {
            dispatch(snackbarToggle({ type: 'error', message: 'Text is empty.' }))
            return;
        }
        var userIL = order.userILId;
        var userNL = order.orderStage[0].orderConfirmationStage[0]?.userNLId;
        var lengthIL = ilUsers.length;
        var lengthNL = nlUsers.length;
        var lengthUS = usUsers.length;
        var lenIlUserImporters = ilUserImporters.length;
        var exists = false;
        var chat = {
            'time': '',
            'text': chatText,
            'initiator': '',
            'initiatorType': '',
            'location': '',
            'ILStatus': false,
            'NLStatus': false
        }
        let o1 = { ...order }

        var chat_list = [];
        if (order.chat) {
            chat_list = JSON.parse(order.chat);
        }
        if (!chat_list) {
            chat_list = [];
        }
        for (var i = 0; i < lenIlUserImporters; i++) {
            if (ilUserImporters[i].fullName == loginUser.fullName) {
                var date = new Date();
                chat['time'] = date.toUTCString();
                chat['initiator'] = loginUser.fullName;
                chat['initiatorType'] = 1;
                chat['location'] = 'IL';
                if (userIL == ilUserImporters[i].id) {
                    chat['ILStatus'] = true;
                    chat['initiatorType'] = 2;
                    for (var j = 0; j < chat_list.length; j++) {
                        if (!chat_list[j]['ILStatus']) {
                            chat_list[j]['ILStatus'] = true;
                        }
                    }
                }
                else if (ilUserImporters[i].roleId == 1)
                    chat['ILStatus'] = false;
                exists = true;
                break;
            }
        }
        if (!exists) {
            for (var i = 0; i < lengthNL; i++) {
                if (nlUsers[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'NL';
                    if (userNL == nlUsers[i].id) {
                        chat['NLStatus'] = true;
                        chat['initiatorType'] = 3;
                        for (var j = 0; j < chat_list.length; j++) {
                            if (!chat_list[j]['NLStatus']) {
                                chat_list[j]['NLStatus'] = true;
                            }
                        }
                    }
                    else if (nlUsers[i].roleId == 1)
                        chat['NLStatus'] = false;
                    exists = true;
                    break;
                }
            }
            for (var i = 0; i < lengthUS; i++) {
                if (usUsers[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'US';
                    if (userNL == usUsers[i].id) {
                        chat['USStatus'] = true;
                        chat['initiatorType'] = 3;
                        for (var j = 0; j < chat_list.length; j++) {
                            if (!chat_list[j]['NLStatus']) {
                                chat_list[j]['NLStatus'] = true;
                            }
                        }
                    }
                    else if (usUsers[i].roleId == 1)
                        chat['NLStatus'] = false;
                    exists = true;
                    break;
                }
            }
        }
        if (!order.chat) {
            chat_list[0] = chat;
            //o1.chat = JSON.stringify(chat_list);
        }
        else {
            chat_list.push(chat);
            //o1.chat = JSON.stringify(chat_list);
        }


        var chatString = JSON.stringify(chat) //getStatusChatMessage(order, 'Departure', departureStatuses.find(status => status.id === newOrder?.orderStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
        
        onUpdateChat(order.id, chatString, 0);
        return;
    }
    const onHandleSendChatT = (statusChatButton) => {
        if (order.orderStage[0].orderConfirmationStage.length == 0) {
            //dispatch(snackbarToggle({ type: 'error', message: 'Order Conformation should be completed before chat can ne used.' }))
            // return;
        }
        if (chatText == "") {
            dispatch(snackbarToggle({ type: 'error', message: 'Text is empty.' }))
            return;
        }
        var userIL = order.userILId;
        var userNL = order.orderStage[0].orderConfirmationStage[0]?.userNLId;
        var userILTracer = order.userILTracerId;
        var userILCustoms = order.userILCustomsId;
        var lengthIL = ilUsers.length;
        var lengthNL = nlUsers.length;
        var lengthUS = usUsers.length;
        var lenIlUserImporters = ilUserImporters.length;
        var lenIlUserTracers = ilUserTracers.length;
        var exists = false;
        var chat = {
            'time': '',
            'text': chatText,
            'initiator': '',
            'initiatorType': '',
            'location': '',
            'ILStatus': false,
            'NLStatus': false
        }
        let o1 = { ...order }

        var chat_list = [];
        if (order.chatTracer) {
            chat_list = JSON.parse(order.chatTracer);
        }
        if (!chat_list) {
            chat_list = [];
        }
        for (var i = 0; i < lenIlUserImporters; i++) {
            if (ilUserImporters[i].fullName == loginUser.fullName) {
                var date = new Date();
                chat['time'] = date.toUTCString();
                chat['initiator'] = loginUser.fullName;
                chat['initiatorType'] = 1;
                chat['location'] = 'IL';
                if (userIL == ilUserImporters[i].id) {
                    chat['ILStatus'] = true;
                    chat['initiatorType'] = 2;
                    for (var j = 0; j < chat_list.length; j++) {
                        if (!chat_list[j]['ILStatus']) {
                            chat_list[j]['ILStatus'] = true;
                        }
                    }
                }
                else if (ilUserImporters[i].roleId == 1)
                    chat['ILStatus'] = false;
                exists = true;
                break;
            }
        }
        if (!exists) {
            for (var i = 0; i < lenIlUserTracers; i++) {
                if (ilUserTracers[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'NL';
                    if (userILTracer == ilUserTracers[i].id) {
                        chat['NLStatus'] = true;
                        chat['initiatorType'] = 3;
                        for (var j = 0; j < chat_list.length; j++) {
                            if (!chat_list[j]['NLStatus']) {
                                chat_list[j]['NLStatus'] = true;
                            }
                        }
                    }
                    else if (ilUserTracers[i].roleId == 1)
                        chat['NLStatus'] = false;
                    exists = true;
                    break;
                }
            }
        }
        if (!order.chatTracer) {
            chat_list[0] = chat;
            //o1.chat = JSON.stringify(chat_list);
        }
        else {
            chat_list.push(chat);
            //o1.chat = JSON.stringify(chat_list);
        }


        var chatString = JSON.stringify(chat) //getStatusChatMessage(order, 'Departure', departureStatuses.find(status => status.id === newOrder?.orderStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
        
        onUpdateChatT(order.id, chatString, 0);
        return;
    }
    const onHandleSendChatC = (statusChatButton) => {
        if (order.orderStage[0].orderConfirmationStage.length == 0) {
            //dispatch(snackbarToggle({ type: 'error', message: 'Order Conformation should be completed before chat can ne used.' }))
            // return;
        }
        if (chatText == "") {
            dispatch(snackbarToggle({ type: 'error', message: 'Text is empty.' }))
            return;
        }
        var userIL = order.userILId;
        var userILTracer = order.userILTracerId;
        var userILCustoms = order.userILCustomsId;
        var userNL = order.orderStage[0].orderConfirmationStage[0]?.userNLId;
        var lengthIL = ilUsers.length;
        var lengthNL = nlUsers.length;
        var lengthUS = usUsers.length;
        var lenIlUserImporters = ilUserImporters.length;
        var lenIlUserCustoms = ilUserCustoms.length;
        var exists = false;
        var chat = {
            'time': '',
            'text': chatText,
            'initiator': '',
            'initiatorType': '',
            'location': '',
            'ILStatus': false,
            'NLStatus': false
        }
        let o1 = { ...order }

        var chat_list = [];
        if (order.chatCustoms) {
            chat_list = JSON.parse(order.chatCustoms);
        }
        if (!chat_list) {
            chat_list = [];
        }
        for (var i = 0; i < lenIlUserImporters; i++) {
            if (ilUserImporters[i].fullName == loginUser.fullName) {
                var date = new Date();
                chat['time'] = date.toUTCString();
                chat['initiator'] = loginUser.fullName;
                chat['initiatorType'] = 1;
                chat['location'] = 'IL';
                if (userIL == ilUserImporters[i].id) {
                    chat['ILStatus'] = true;
                    chat['initiatorType'] = 2;
                    for (var j = 0; j < chat_list.length; j++) {
                        if (!chat_list[j]['ILStatus']) {
                            chat_list[j]['ILStatus'] = true;
                        }
                    }
                }
                else if (ilUserImporters[i].roleId == 1)
                    chat['ILStatus'] = false;
                exists = true;
                break;
            }
        }
        if (!exists) {
            for (var i = 0; i < lenIlUserCustoms; i++) {
                if (ilUserCustoms[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'NL';
                    if (userILCustoms == ilUserCustoms[i].id) {
                        chat['NLStatus'] = true;
                        chat['initiatorType'] = 3;
                        for (var j = 0; j < chat_list.length; j++) {
                            if (!chat_list[j]['NLStatus']) {
                                chat_list[j]['NLStatus'] = true;
                            }
                        }
                    }
                    else if (ilUserCustoms[i].roleId == 1)
                        chat['NLStatus'] = false;
                    exists = true;
                    break;
                }
            }
        }
        if (!order.chatCustoms) {
            chat_list[0] = chat;
            //o1.chat = JSON.stringify(chat_list);
        }
        else {
            chat_list.push(chat);
            //o1.chat = JSON.stringify(chat_list);
        }


        var chatString = JSON.stringify(chat) //getStatusChatMessage(order, 'Departure', departureStatuses.find(status => status.id === newOrder?.orderStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
        
        onUpdateChatC(order.id, chatString, 0);
        return;
    }
    const onHandleSendChatCT = (statusChatButton) => {
        if (order.orderStage[0].orderConfirmationStage.length == 0) {
            //dispatch(snackbarToggle({ type: 'error', message: 'Order Conformation should be completed before chat can ne used.' }))
            // return;
        }
        if (chatText == "") {
            dispatch(snackbarToggle({ type: 'error', message: 'Text is empty.' }))
            return;
        }
        var userIL = order.userILId;
        var userILTracer = order.userILTracerId;
        var userILCustoms = order.userILCustomsId;
        var userNL = order.orderStage[0].orderConfirmationStage[0]?.userNLId;
        var lengthIL = ilUsers.length;
        var lengthNL = nlUsers.length;
        var lengthUS = usUsers.length;
        var lenIlUserCustoms = ilUserCustoms.length;
        var lenIlUserTracers = ilUserTracers.length;
        var exists = false;
        var chat = {
            'time': '',
            'text': chatText,
            'initiator': '',
            'initiatorType': '',
            'location': '',
            'ILStatus': false,
            'NLStatus': false
        }
        let o1 = { ...order }

        var chat_list = [];
        if (order.chatCustoms) {
            chat_list = JSON.parse(order.chatCustoms);
        }
        if (!chat_list) {
            chat_list = [];
        }
        for (var i = 0; i < lenIlUserTracers; i++) {
            if (ilUserTracers[i].fullName == loginUser.fullName) {
                var date = new Date();
                chat['time'] = date.toUTCString();
                chat['initiator'] = loginUser.fullName;
                chat['initiatorType'] = 1;
                chat['location'] = 'IL';
                if (userILTracer == ilUserTracers[i].id) {
                    chat['ILStatus'] = true;
                    chat['initiatorType'] = 2;
                    for (var j = 0; j < chat_list.length; j++) {
                        if (!chat_list[j]['ILStatus']) {
                            chat_list[j]['ILStatus'] = true;
                        }
                    }
                }
                else if (ilUserTracers[i].roleId == 1)
                    chat['ILStatus'] = false;
                exists = true;
                break;
            }
        }
        if (!exists) {
            for (var i = 0; i < lenIlUserCustoms; i++) {
                if (ilUserCustoms[i].fullName == loginUser.fullName) {
                    var date = new Date();
                    chat['time'] = date.toUTCString();
                    chat['initiator'] = loginUser.fullName;
                    chat['initiatorType'] = 1;
                    chat['location'] = 'NL';
                    if (userILCustoms == ilUserCustoms[i].id) {
                        chat['NLStatus'] = true;
                        chat['initiatorType'] = 3;
                        for (var j = 0; j < chat_list.length; j++) {
                            if (!chat_list[j]['NLStatus']) {
                                chat_list[j]['NLStatus'] = true;
                            }
                        }
                    }
                    else if (ilUserCustoms[i].roleId == 1)
                        chat['NLStatus'] = false;
                    exists = true;
                    break;
                }
            }
        }
        if (!order.chatCustoms) {
            chat_list[0] = chat;
            //o1.chat = JSON.stringify(chat_list);
        }
        else {
            chat_list.push(chat);
            //o1.chat = JSON.stringify(chat_list);
        }


        var chatString = JSON.stringify(chat) //getStatusChatMessage(order, 'Departure', departureStatuses.find(status => status.id === newOrder?.orderStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser);
        
        onUpdateChatCT(order.id, chatString, 0);
        return;
    }
    const isNLUser = () => {
        if (loginUser.authority === "Admin")
            return false;
        var lengthNL = nlUsers.length;
        for (var i = 0; i < lengthNL; i++) {
            if (nlUsers[i].fullName == loginUser.fullName) {
                if (userLoaded == 0) {
                    setUsersLoaded(1);
                    setChatType(1);
                    updateSeen(1);
                }
                return true;
            }
        }
        var lengthUS = usUsers.length;
        for (var i = 0; i < lengthUS; i++) {
            if (usUsers[i].fullName == loginUser.fullName) {
                if (userLoaded == 0) {
                    setUsersLoaded(1);
                    setChatType(1);
                    updateSeen(1);
                }
                return true;
            }
        }
        return false;
    }
    const isImporterUser = () => {
        if (loginUser.authority === "Admin")
            return true;
        var lenIlUserImporters = ilUserImporters.length;
        for (var i = 0; i < lenIlUserImporters; i++) {
            if (ilUserImporters[i].fullName == loginUser.fullName) {
                if (userLoaded == 0) {
                    setUsersLoaded(1);
                    setChatType(1);
                    updateSeen(1);
                }
                return true;
            }
        }
        return false;
    }
    const isTracerUser = () => {
        if (loginUser.authority === "Admin")
            return false;
        var lengthNL = ilUserTracers.length;
        for (var i = 0; i < lengthNL; i++) {
            if (ilUserTracers[i].fullName == loginUser.fullName) {
                if (userLoaded == 0) {
                    setUsersLoaded(1);
                    setChatType(2);
                    updateSeen(2);
                }
                return true;
            }
        }
        return false;
    }
    const isCustomsUser = () => {
        if (loginUser.authority === "Admin")
            return false;
        var lengthNL = ilUserCustoms.length;
        for (var i = 0; i < lengthNL; i++) {
            if (ilUserCustoms[i].fullName == loginUser.fullName) {
                if (userLoaded == 0) {
                    setUsersLoaded(1);
                    setChatType(3);
                    updateSeen(3);
                }
                return true;
            }
        }
        return false;
    }
    const onHandlechangeChat = (value) => {
        setChatText(value);
    }

    const setChatListFx2 = (chat) => {
        setChatList(chat);
        setChatText("");
    }
    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateChat/${id}?fromStage=${fromStage}&loginUserId=${loginUser?.id}&actionOn=ChatDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                setChatListFx2(JSON.parse(JSON.stringify([...chatList, JSON.parse(chatString)])));
                sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {

                        dispatch(onOrdersCountChange(response?.ordersCount));
                        sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                        dispatch(onChatCountChange(response?.unreadChatCount));
                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount));
                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                        sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount);
                        dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount));
                        dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));/**/


                    },
                    failedCallback,
                });
                sendRequest(
                    `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
                    REQUEST_ACTIONS.GET,
                    '',
                    {
                        successCallback: response => {
                            setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                            if (prevPage?.page == page && addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                                dispatch(onPageChange(0));
                            }
                        },
                        failedCallback,
                    },
                );
            },
            failedCallback,
        });
    };
    const onUpdateChatT = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateChatT/${id}?fromStage=${fromStage}&loginUserId=${loginUser?.id}&actionOn=ChatDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                setChatListT(JSON.parse(JSON.stringify([...chatListT, JSON.parse(chatString)])));
                setChatText("");
                sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {

                        dispatch(onOrdersCountChange(response?.ordersCount));
                        sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                        dispatch(onChatCountChange(response?.unreadChatCount));
                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount));
                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                        sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount);
                        dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount));
                        dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));/**/


                    },
                    failedCallback,
                });
                sendRequest(
                    `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
                    REQUEST_ACTIONS.GET,
                    '',
                    {
                        successCallback: response => {
                            setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                            if (prevPage?.page == page && addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                                dispatch(onPageChange(0));
                            }
                        },
                        failedCallback,
                    },
                );
            },
            failedCallback,
        });
    };
    const onUpdateChatC = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateChatC/${id}?fromStage=${fromStage}&loginUserId=${loginUser?.id}&actionOn=ChatDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                setChatListC(JSON.parse(JSON.stringify([...chatListC, JSON.parse(chatString)])));
                setChatText("");
                sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {

                        dispatch(onOrdersCountChange(response?.ordersCount));
                        sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                        dispatch(onChatCountChange(response?.unreadChatCount));
                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount));
                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                        sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount);
                        dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount));
                        dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));/**/


                    },
                    failedCallback,
                });
                sendRequest(
                    `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
                    REQUEST_ACTIONS.GET,
                    '',
                    {
                        successCallback: response => {
                            setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                            if (prevPage?.page == page && addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                                dispatch(onPageChange(0));
                            }
                        },
                        failedCallback,
                    },
                );
            },
            failedCallback,
        });
    };
    const onUpdateChatCT = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateChatCT/${id}?fromStage=${fromStage}&loginUserId=${loginUser?.id}&actionOn=ChatDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                setChatListCT(JSON.parse(JSON.stringify([...chatListCT, JSON.parse(chatString)])));
                setChatText("");
                sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {

                        dispatch(onOrdersCountChange(response?.ordersCount));
                        sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                        dispatch(onChatCountChange(response?.unreadChatCount));
                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount));
                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                        sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount);
                        dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount));
                        dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));/**/


                    },
                    failedCallback,
                });
                sendRequest(
                    `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
                    REQUEST_ACTIONS.GET,
                    '',
                    {
                        successCallback: response => {
                            setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                            if (prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                                dispatch(onPageChange(0));
                            }
                        },
                        failedCallback,
                    },
                );
            },
            failedCallback,
        });
    };
    const getInitiatorStyle = (ch) => {
        if (ch['location']) {
            if (ch['location'] == 'IL')
                return ilChatNameStyle;
            if (ch['location'] == 'NL')
                return nlChatNameStyle;
        }
        var type = ch['initiatorType']
        if (type == 1)
            return adChatNameStyle;
        if (type == 2)
            return ilChatNameStyle;
        if (type == 3)
            return nlChatNameStyle;
    }

    const copyText = (dat) => {
        navigator.clipboard.writeText(dat);
        alert("Copied: " + dat)
    }

    /*  const enterKeyPressed = (e) => {
        if(e.keyCode == 13) {
          e.preventDefault();
          onHandleSendChat(11);
        }
      } */
    return (
        <Box component='form' noValidate autoComplete='off'>
            <Grid item container style={{borderBottom:"1px solid grey"}} flexWrap="nowrap">
                <Grid item container style={{ margin: "10px" }}>
                    <ContentCopyIcon title="copy" onClick={() => copyText(order?.jobNumber)} />
                    {order?.jobNumber}
                </Grid>
                <Grid item container style={{ margin: "10px" }}>
                    {/*<ContentCopyIcon title="copy" onClick={() => copyText(consigneeNameDisplay)} />*/}
                    {consigneeNameDisplay}
                </Grid>
                <Grid item container style={{ margin: "10px" }}>
                    {/*<ContentCopyIcon title="copy" onClick={() => copyText(shipperNameDisplay) } /> */}
                    {shipperNameDisplay}
                </Grid>
            </Grid>
            {(ilUsersLoaded && nlUsersLoaded && usUsersLoaded) && <Grid container spacing={2} position='relative'>
                {(isNLUser() && false) && <Grid item container>
                    <Button style={{ margin: "5px", background: `${chatType == 1 ? "#1976d270" : "#bdbdbd69"}`, color: "black" }} variant='outlined' onClick={(e) => setDefaultChat()}>Warehouse ({unseenCount.i})</Button>
                </Grid>}
                {(isImporterUser()) && <Grid item container>
                    <Button style={{ margin: "5px", background: `${chatType == 1 ? "#1976d270" : "#bdbdbd69"}`, color: "black" }} variant='outlined' onClick={(e) => setDefaultChat()}>Warehouse ({unseenCount.i})</Button>
                    <Button style={{ margin: "5px", background: `${chatType == 2 ? "#1976d270" : "#bdbdbd69"}`, color: "black" }} variant='outlined' onClick={(e) => setTracerChat()}>Tracer ({unseenCount.t})</Button>
                    <Button style={{ margin: "5px", background: `${chatType == 3 ? "#1976d270" : "#bdbdbd69"}`, color: "black" }} variant='outlined' onClick={(e) => setCustomsChat()}>Customs ({unseenCount.c})</Button>
                </Grid>}
                {(isCustomsUser()) && <Grid item container>
                    <Button style={{ margin: "5px", background: `${chatType == 3 ? "#1976d270" : "#bdbdbd69"}`, color: "black" }} variant='outlined' onClick={(e) => setCustomsChat()}>Importer ({unseenCount.c})</Button>
                    <Button style={{ margin: "5px", background: `${chatType == 4 ? "#1976d270" : "#bdbdbd69"}`, color: "black" }} variant='outlined' onClick={(e) => setCTChat()}>Tracer ({unseenCount.ct})</Button>
                </Grid>}
                {(isTracerUser()) && <Grid item container>
                    <Button style={{ margin: "5px", background: `${chatType == 2 ? "#1976d270" : "#bdbdbd69"}`, color: "black" }} variant='outlined' onClick={(e) => setTracerChat()}>Importer ({unseenCount.t})</Button>
                    <Button style={{ margin: "5px", background: `${chatType == 4 ? "#1976d270" : "#bdbdbd69"}`, color: "black" }} variant='outlined' onClick={(e) => setCTChat()}>Customs ({unseenCount.ct})</Button>
                </Grid>}
                <Fragment>
                    <div style={{ height: '60vh', width: '900px', overflowY: 'auto', border: '1px solid black', display: 'flex', flexDirection: 'column-reverse' }}>
                        <div style={{ height: 'auto', width: '100%' }}>
                            {chatType == 1 && chatList?.map(ch => (
                                <div style={{ width: '100%', padding: '5px' }}> <span style={{ color: 'grey' }}>{ch['time'] ? new Intl.DateTimeFormat('en-GB', {
                                    year: '2-digit',
                                    month: '2-digit',
                                    day: '2-digit',
                                }).format(new Date(ch['time'])) + ' ' + new Intl.DateTimeFormat('en-GB', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }).format(new Date(ch['time'])) : ''}</span> <span style={getInitiatorStyle(ch)}>{ch['initiator']}</span><span style={{ color: 'black' }}> : {ch['text']}</span></div>
                            ))}
                            {chatType == 2 && chatListT?.map(ch => (
                                <div style={{ width: '100%', padding: '5px' }}> <span style={{ color: 'grey' }}>{ch['time'] ? new Intl.DateTimeFormat('en-GB', {
                                    year: '2-digit',
                                    month: '2-digit',
                                    day: '2-digit',
                                }).format(new Date(ch['time'])) + ' ' + new Intl.DateTimeFormat('en-GB', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }).format(new Date(ch['time'])) : ''}</span> <span style={getInitiatorStyle(ch)}>{ch['initiator']}</span><span style={{ color: 'black' }}> : {ch['text']}</span></div>
                            ))}
                            {chatType == 3 && chatListC?.map(ch => (
                                <div style={{ width: '100%', padding: '5px' }}> <span style={{ color: 'grey' }}>{ch['time'] ? new Intl.DateTimeFormat('en-GB', {
                                    year: '2-digit',
                                    month: '2-digit',
                                    day: '2-digit',
                                }).format(new Date(ch['time'])) + ' ' + new Intl.DateTimeFormat('en-GB', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }).format(new Date(ch['time'])) : ''}</span> <span style={getInitiatorStyle(ch)}>{ch['initiator']}</span><span style={{ color: 'black' }}> : {ch['text']}</span></div>
                            ))}
                            {chatType == 4 && chatListCT?.map(ch => (
                                <div style={{ width: '100%', padding: '5px' }}> <span style={{ color: 'grey' }}>{ch['time'] ? new Intl.DateTimeFormat('en-GB', {
                                    year: '2-digit',
                                    month: '2-digit',
                                    day: '2-digit',
                                }).format(new Date(ch['time'])) + ' ' + new Intl.DateTimeFormat('en-GB', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                }).format(new Date(ch['time'])) : ''}</span> <span style={getInitiatorStyle(ch)}>{ch['initiator']}</span><span style={{ color: 'black' }}> : {ch['text']}</span></div>
                            ))}
                        </div>
                        <div style={{ float: "left", clear: "both" }} id="bottomOfScreen"></div>
                    </div>
                    <Grid container item justifyContent='space-between'>
                        <MultilineField
                            sm={10}
                            md={null}
                            lg={null} style={{ width: '100%' }} id="chatText" label="Chat" variant="outlined" type="text" value={chatText} onChange={(event) => onHandlechangeChat(event.target.value)}
                        />
                        <Button variant='contained' sx={{ mr: 2 }} onClick={(event) => onHandleSendChat(11)}>
                            Send
                        </Button>
                    </Grid>
                </Fragment>

            </Grid>}
        </Box>
    );
};

export default ChatDialog;
