import { useState, memo } from 'react';

import StageButton from '../StageButton';
import ShipperDialog from './ShipperDialog';
import {StyledTableCell} from 'Utils';

const Shipper = ({ loginUser, order }) => {
    const getStatus = () => {
        var stage = order.orderStage ? order.orderStage[1] : null;
        if (order.locationId != 2) {
            if (order.incotermsId == 3 || order.incotermsId == 4 || order.incotermsId == 5 || order.incotermsId == 6) {
                stage = {};
                stage.status = "dissable";
                stage.notes = "";
            }
        }
        return stage;
    }
    const currentStage = order.orderStage?order.orderStage[1]:null;

  const [openDialog, setOpenDialog] = useState(false);
  const onHandleOpenDialog = () => setOpenDialog(true);

  return (
      <StyledTableCell style={{ width: "5vw", p: 0 }}>
      <StageButton stage={getStatus()} onHandleOpenDialog={onHandleOpenDialog} stageName='Shipper' loginUser={loginUser} />
      {openDialog && <ShipperDialog loginUser={loginUser} order={order} openDialog={openDialog} setOpenDialog={setOpenDialog} />}
    </StyledTableCell>
  );
};

export default memo(Shipper);
