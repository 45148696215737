import { v4 as uuidv4 } from 'uuid';

import { Divider, Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { TextField } from 'style-guide';

import { useDispatch } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';

const LCLDetails = ({ onStateChange, state }) => {
  const { orderDetails, numberOfPieces, weightPerPiece, width, height, length, manualVolume, manualWeight, totalweight, totalvolume } = state;

  const dispatch = useDispatch();
  const onHandleChange = (event, field) => onStateChange(field, event.target.value);

  const onHandleAdd = () => {
    const isAddAllDetails = (numberOfPieces && weightPerPiece && width && height && length) || (totalweight && totalvolume);

    if (!isAddAllDetails) {
      return dispatch(snackbarToggle({ type: 'error', message: 'Please add all details' }));
    }

    onStateChange('orderDetails', [
      ...orderDetails,
      {
        id: uuidv4(),
        numberOfPieces,
        weightPerPiece,
        width,
        height,
        length,
        manualWeight,
        manualVolume,
        totalweight, 
        totalvolume,
      },
    ]);
    onStateChange('numberOfPieces', null);
    onStateChange('weightPerPiece', null);
    onStateChange('width', null);
    onStateChange('height', null);
    onStateChange('length', null);
    onStateChange('manualWeight', null);
    onStateChange('manualVolume', null);
    onStateChange('totalweight', null);
    onStateChange('totalvolume', null);
  };
  const resetBasicDetails = () => {
    //onStateChange('numberOfPieces', null);
    onStateChange('weightPerPiece', null);
    onStateChange('width', null);
    onStateChange('height', null);
    onStateChange('length', null);
  }

  const resetTotalDetails = () => {
    onStateChange('totalweight', null);
    onStateChange('totalvolume', null);
  }
  const onHandleDelete = id => {
    onStateChange(
      'orderDetails',
      orderDetails.filter(detail => detail.id !== id),
    );
  };

  return (
     <Grid container item spacing={2}>
      <Grid container item alignItems='center'>
        <IconButton onClick={onHandleAdd} color='primary' size='small'>
          <AddIcon />
        </IconButton>
        <Typography variant='h6'>Shipment Details</Typography>
      </Grid>
      {/* <Grid container item spacing={1} xs={12}>
        <Grid item xs={0.5} />
        <TextField
          sm={3}
          md={null}
          lg={null}
          type='number'
          label='Manual Volume'
          value={manualVolume || orderDetails[0]?.manualVolume}
          onChange={event => onHandleChange(event, 'manualVolume')}
        />
        <TextField
          sm={3}
          md={null}
          lg={null}
          type='number'
          label='Manual Weight'
          value={manualWeight || orderDetails[0]?.manualWeight}
          onChange={event => onHandleChange(event, 'manualWeight')}
        />
        <TextField
          readOnly
          sm={3}
          md={null}
          lg={null}
          type='number'
          label='Total W/M'
          value={manualWeight}
          onChange={event => onHandleChange(event, 'manualWeight')}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid> */}
      <Grid container item spacing={2} xs={12}>
        <Grid container item spacing={1}>
          <Grid item xs={0.5} />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            type='number'
            label='Pieces'
            value={numberOfPieces}
            onChange={event => onHandleChange(event, 'numberOfPieces')}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            type='number'
            label='L (cm)'
            value={length}
            onChange={event => {resetTotalDetails(); onHandleChange(event, 'length')}}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            type='number'
            label='W (cm)'
            value={width}
            onChange={event => {resetTotalDetails(); onHandleChange(event, 'width')}}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            type='number'
            label='H (cm)'
            value={height}
            onChange={event => {resetTotalDetails(); onHandleChange(event, 'height')}}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            type='number'
            label='Weight per Piece (kg)'
            value={weightPerPiece}
            onChange={event => {resetTotalDetails(); onHandleChange(event, 'weightPerPiece')}}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            label='Total Weight (kg)'
            value={totalweight || numberOfPieces * weightPerPiece}
            onChange={event => {resetBasicDetails(); onHandleChange(event, 'totalweight')}}
          />
          <TextField
            sm={1.5}
            md={null}
            lg={null}
            label='Total Volume (m3)'
            onChange={event => {resetBasicDetails(); onHandleChange(event, 'totalvolume')}}
            value={totalvolume || ((numberOfPieces * (length * width * height)) / 1000000)}
          />
        </Grid>
        {orderDetails.map(detail => {
          return (
            <Grid container item spacing={1} alignItems='center' key={detail.id || uuidv4()}>
              <Grid item xs={0.5}>
                <IconButton onClick={() => onHandleDelete(detail.id)} color='primary' size='small'>
                  <RemoveIcon />
                </IconButton>
              </Grid>
              <TextField sm={1.5} md={null} lg={null} readOnly label='Pieces' value={detail.numberOfPieces} />
              <TextField sm={1.5} md={null} lg={null} readOnly label='L (cm)' value={detail.length} />
              <TextField sm={1.5} md={null} lg={null} readOnly label='W (cm)' value={detail.width} />
              <TextField sm={1.5} md={null} lg={null} readOnly label='H (cm)' value={detail.height} />
              <TextField
                sm={1.5}
                md={null}
                lg={null}
                readOnly
                label='Weight per Piece (kg)'
                value={detail.weightPerPiece}
              />
              <TextField
                readOnly
                sm={1.5}
                md={null}
                lg={null}
                label='Total Weight (kg)'
                value={detail.totalweight || detail.numberOfPieces * detail.weightPerPiece}
              />
              <TextField
                readOnly
                sm={1.5}
                md={null}
                lg={null}
                label='Total Volume (m3)'
                value={detail.totalvolume || ((detail.numberOfPieces * (detail.length * detail.width * detail.height)) / 1000000)}
              />
            </Grid>
          );
        })}
        <Grid container item spacing={1}>
          <Grid item xs={7.5} />
          <TextField
            readOnly
            sm={1.5}
            md={null}
            lg={null}
            label='Sum - Total Weight'
            value={orderDetails.reduce((acc, detail) => {
              return parseFloat(acc) + parseFloat(detail.totalweight || detail.numberOfPieces * detail.weightPerPiece);
          }, (totalweight || numberOfPieces * weightPerPiece))}
          />
          <TextField
            readOnly
            sm={1.5}
            md={null}
            lg={null}
            label='Sum - Total W/M'
            value={orderDetails.reduce((acc, detail) => {
              return parseFloat(acc) + parseFloat(detail.totalvolume || ((detail.numberOfPieces * (detail.length * detail.width * detail.height)) / 1000000));
            }, totalvolume || ((numberOfPieces * (length * width * height)) / 1000000))}
          />
          <TextField
            readOnly
            sm={1.5}
            md={null}
            lg={null}
            label={<Typography fontSize={14}>Chargeable Weight</Typography>}
            value={
              Math.max(orderDetails.reduce((acc, detail) => {
                return parseFloat(acc) + parseFloat(detail.totalweight || detail.numberOfPieces * detail.weightPerPiece);
                }, (totalweight || numberOfPieces * weightPerPiece)),
                orderDetails.reduce((acc, detail) => {
                return parseFloat(acc) + (parseFloat(detail.totalvolume*1000000 || (detail.numberOfPieces * detail.length * detail.width * detail.height))/6000);
                }, (totalvolume*1000000 || (numberOfPieces * length * width * height))/6000))?.toFixed(2)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LCLDetails;
