import { useState, useCallback, useEffect } from 'react';
import { Grid, Button, FormControlLabel, Checkbox } from '@mui/material';
import { SelectField, TextField, MultilineField } from 'style-guide';
import { sendRequest, REQUEST_ACTIONS } from 'utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { addUser, updateUser } from 'reducers/UsersSlicer';
import { Snackbar } from 'style-guide';
import imgHide from './hide.png'
import imgShow from './show.png'
import { onRolesChange, onLocationChange, onCustomerChange } from 'reducers/UsersSlicer';

const AddUser = props => {
    const dispatch = useDispatch();
    //const { roles, locations, customers } = useSelector(state => state.users);
    const { roles, customers } = useSelector(state => state.users);
    const { consigneeNameOptions } = useSelector(state => state.consignees);
    const { snackbar } = useSelector(state => state.snackbar);
    const onSnackbarHandleClose = () => dispatch(snackbarToggle(false));
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const [locations, setLocations] = useState([{ "id": 1, "code": "NL", "name": "Nederland" }, { "id": 2, "code": "IL", "name": "Israel" }, { "id": 4, "code": "US", "name": "US" }]);
    const [departments, setDepartments] = useState([{ "id": 1, "code": "F/U", "name": "F/U" }, { "id": 2, "code": "OPS", "name": "OPS" }, { "id": 3, "code": "Customs", "name": "Customs" }]);
    const [value, setValue] = useState(
        props.data
            ? {
                id: props.data.id,
                roleId: props.data.roleId,
                department: props.data.department,
                fullName: props.data.fullName,
                userName: props.data.userName,
                password: props.data.password,
                confirmPassword: props.data.password,
                locationId: props.data.locationId,
                customerId: props.data.customerId,
                description: props.data.description,
                seePass: props.data.seePass,
                active: props.data.active,
            }
            : {
                id: null,
                roleId: null,
                department: null,
                fullName: null,
                userName: null,
                password: null,
                confirmPassword: null,
                locationId: null,
                customerId: null,
                description: null,
                seePass: false,
                active: true,
            },
    );

    const save = () => {
        if (value.password === value.confirmPassword) {
            sendRequest('/api/addUser', REQUEST_ACTIONS.POST, value, {
                successCallback: response => {
                    dispatch(addUser(response));
                    dispatch(snackbarToggle({ type: 'success', message: 'User Created successfully' }));
                    props?.onHandleCancel();
                },
                failedCallback,
            });
        } else {
            dispatch(snackbarToggle({ type: 'error', message: 'password and confirm password not same' }));
        }
    };

    const seePassword = () => {
        setValue(prev => ({ ...prev, seePass: !value.seePass }))
    }

    const update = () => {
        if (value.password === value.confirmPassword) {
            sendRequest('/api/updateUser', REQUEST_ACTIONS.PUT, value, {
                successCallback: response => {
                    dispatch(updateUser(response));
                    dispatch(snackbarToggle({ type: 'success', message: 'User details updated successfully' }));
                    props?.onHandleCancel();
                },
                failedCallback,
            });
        } else {
            dispatch(snackbarToggle({ type: 'error', message: 'password and confirm password not same' }));
        }
    };

    const autoSelectLocation = (event) => {
        setValue(prev => ({ ...prev, roleId: event.target.value }));
        if (event.target.value == '3') {
            setValue(prev => ({ ...prev, locationId: 3 }));
        } else {
            setValue(prev => ({ ...prev, locationId: '' }));
        }
        setValue(prev => ({ ...prev, customerId: '' }));
    }

    /*const customerNameOptions = customers.map(customer => ({
        id: customer.id,
        name: customer.companyName,
      }));*/
    const customerNameOptions = consigneeNameOptions.map(consigneeNameOption => ({
        id: consigneeNameOption.id,
        name: consigneeNameOption.name,
    }));

    useEffect(() => {
        if (props?.viewProfile && props?.data?.id) {
            sendRequest(`/api/user/` + props.data.id, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    setValue({
                        id: response.id,
                        roleId: response.roleId,
                        fullName: response.fullName,
                        userName: response.userName,
                        password: response.password,
                        confirmPassword: response.password,
                        locationId: response.locationId,
                        customerId: response.customerId,
                        description: response.description,
                        seePass: response.seePass,
                        active: response.active,
                    });
                },
                failedCallback,
            });

            sendRequest('/api/roles', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onRolesChange(response)),
                failedCallback,
            });

            sendRequest('/api/locations', REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(onLocationChange(response));
                },
                failedCallback,
            });
            sendRequest('/api/serviceProvider/13?activeFlag=true', REQUEST_ACTIONS.GET, '', {
                successCallback: response => dispatch(onCustomerChange(response)),
                failedCallback
            });
        }
    }, [dispatch, props?.data?.id, props?.viewProfile]);

    return (
        <div>
            <Grid container item spacing={2}>
                <Grid container item spacing={2}>
                    <TextField
                        required
                        lg={11}
                        value={value.fullName}
                        onChange={event => setValue(prev => ({ ...prev, fullName: event.target.value }))}
                        label='Name'
                    />

                    <TextField
                        required
                        readOnly={props.viewProfile}
                        lg={11}
                        label='E-Mail'
                        value={value.userName}
                        onChange={event => setValue(prev => ({ ...prev, userName: event.target.value }))}
                    />

                    <SelectField
                        required
                        value={value.roleId}
                        disabled={props.viewProfile}
                        lg={5.5}
                        onChange={event => autoSelectLocation(event)}
                        label='Role'
                        options={roles}
                    />

                    <SelectField
                        required
                        value={value.department}
                        disabled={props.viewProfile}
                        lg={5.5}
                        onChange={event => setValue(prev => ({ ...prev, department: event.target.value }))}
                        label='Department'
                        options={departments}
                    />

                    <TextField
                        required
                        lg={4.5}
                        value={value.password}
                        label='Password'
                        type={value.seePass ? "" : "password"}
                        onChange={event => setValue(prev => ({ ...prev, password: event.target.value }))}
                    />
                    <TextField
                        required
                        lg={4.5}
                        type={value.seePass ? "" : "password"}
                        label='Confirm Password'
                        value={value.confirmPassword}
                        onChange={event => setValue(prev => ({ ...prev, confirmPassword: event.target.value }))}
                    />
                    <view style={{ marginLeft: 5 }}>
                        <Button lg={2} variant='contained' sx={{ mt: 2, mr: 2 }} onClick={seePassword}>
                            <img src={value.seePass ? imgShow : imgHide} width="25" height="25" />
                        </Button>
                    </view>
                    {value.roleId === 3 ? null :
                        <SelectField
                            required
                            lg={11}
                            label='Location'
                            disabled={props.viewProfile}
                            value={value.locationId}
                            options={locations}
                            onChange={event => setValue(prev => ({ ...prev, locationId: event.target.value }))} />
                    }
                    {value.roleId === 3 ?
                        <SelectField
                            lg={11}
                            label='Customer'
                            disabled={props.viewProfile}
                            value={value.customerId}
                            options={customerNameOptions}
                            onChange={event => setValue(prev => ({ ...prev, customerId: event.target.value }))} />
                        : null}
                    <MultilineField
                        xs={11}
                        sm={null}
                        md={null}
                        lg={null}
                        label='Notes'
                        value={value.description}
                        onChange={event => setValue(prev => ({ ...prev, description: event.target.value }))}
                    />
                    {props.viewProfile ? null :
                        <view style={{ marginLeft: 15 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={(value.active != null) ? value.active : true} onChange={event => setValue(prev => ({ ...prev, active: event.target.checked }))} />
                                }
                                label='Active/In-Active'
                            />
                        </view>
                    }
                </Grid>
                <Grid container item justifyContent='flex-end' lg={11}>
                    <Button variant='contained' sx={{ mt: 2, mr: 2 }} onClick={props.data?.id ? update : save}>
                        Save
                    </Button>
                    <Button variant='contained' sx={{ mt: 2 }} color='secondary' onClick={props?.onHandleCancel}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default AddUser;
