import { useCallback, useState, useEffect } from 'react';

import { Grid, Box, Button, Typography } from '@mui/material';
import { Dialog, DateTimePicker, SelectField, Tooltip } from 'style-guide';
import { TextField } from 'style-guide'; import {
    Table,
    TableCell,
    TableBody,
    TableContainer,
    TableRow,
    TableHead,
} from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';

import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';
import { useDispatch, useSelector } from 'react-redux';

const AdvSearchDialog = ({ selectedGeneral, setSelectedGeneral, onHandleChange, subModeId, subModeData, onSubModeIdChange, STAGES_OPTIONS, stageSearch, OnStageSearchChange,
    fileNumber, setFileNumber, urgentShipment, onUrgentShipmentChange, advancedSearchDefaultStyle, resetAdvancedSearchFilter, globalSearch, setGlobalSearch,
    setSearchResult, isLoading, CircularProgress, globalSearchList, selectedLocationIndex, onSelectedLocationIndexChange, onSearchChange, setSearchSpinner,
    setSearchText, dispatch, sendRequest, failedCallback, onHandleCancelAdvSearchDialog, setOrderData, loginUser, getOrders }) => {

    const focusSearchInputField = input => {
        if (input) {
            setTimeout(() => { input.focus() }, 100);
            //window.requestAnimationFrame(() => input.focus())
        }
    };

    return (
        <Grid item container flexDirection="column" flexWrap="nowrap" style={{ minHeight: "250px", minWidth: "800px" }}>
            <Grid item container flexDirection="row" flexWrap="nowrap" style={{ margin: "10px" }}>
                <Button variant='contained' sx={{ backgroundColor: `${selectedGeneral ? "#1976d270 !important" : "#e4e4e4"}`, textTransform: "none", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "16%", fontSize: "1vw !important" }} onClick={() => {
                    setSelectedGeneral(true);
                }}>
                    General
                </Button>
                <Button variant='contained' sx={{ backgroundColor: `${!selectedGeneral ? "#1976d270 !important" : "#e4e4e4"}`, textTransform: "none", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "16%", fontSize: "1vw !important" }} onClick={() => {
                    setSelectedGeneral(false);
                }}>
                    Filter
                </Button>
            </Grid>
            {!selectedGeneral && <Grid>
                <Grid item container flexDirection="row" flexWrap="nowrap">
                    <Grid sx={{ padding: "11px", pl: 0, pr: 0, width: "16%", margin: "2px" }}>
                        <SelectField
                            sm={null}
                            md={null}
                            lg={null}
                            label={<Typography fontSize="0.8vw" fontWeight='bold'>Mode</Typography>}
                            value={subModeId}
                            onChange={event => onHandleChange(event, onSubModeIdChange)}
                            options={[{ id: null, name: 'Reset' }, ...subModeData]}
                        />
                    </Grid>
                    <Grid sx={{ padding: "11px", pl: 0, pr: 0, width: "16%", margin: "2px" }} >
                        <SelectField
                            sm={null}
                            md={null}
                            lg={null}
                            label={<Typography fontSize="0.8vw" fontWeight='bold'>Stage</Typography>}
                            options={STAGES_OPTIONS}
                            value={stageSearch}
                            onChange={event => onHandleChange(event, OnStageSearchChange)}
                        />
                    </Grid>
                    <Grid sx={{ padding: "11px", pl: 0, pr: 0, width: "16%", margin: "2px" }} >
                        <TextField
                            lg={12}
                            type="Text"
                            label='File Number'
                            value={fileNumber}
                            onChange={event => setFileNumber(event.target.value)}
                        />
                    </Grid>
                    <Grid sx={{ padding: "11px", pl: 0, pr: 0, width: "20%", margin: "2px" }} >
                        <SelectField
                            sm={null}
                            md={null}
                            lg={null}
                            label={<Typography fontSize="0.8vw" fontWeight='bold'>Urgent Shipment</Typography>}
                            options={[{ id: null, name: 'Reset' }, { id: 1, name: 'Urgent' }, { id: 2, name: 'Not Urgent' }]}
                            value={urgentShipment}
                            onChange={event => onHandleChange(event, onUrgentShipmentChange)}
                        />
                    </Grid>
                    <Grid item container sx={{ padding: "11px", pl: 0, pr: 0, width: "16%", margin: "2px" }} alignItems='center' className="headerDate" >
                        <Tooltip title='Reset Search'>
                            <SearchOffIcon style={advancedSearchDefaultStyle} fontSize='medium' onClick={() => dispatch(resetAdvancedSearchFilter(null))} />
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>}
            {selectedGeneral && <Grid item container flexDirection="column" flexWrap="nowrap">
                <Grid item container style={{ paddingBottom: "10px", borderBottom: "1px solid grey" }} alignItems="center" flexWrap="nowrap">
                    <Grid item container lg={6}>
                        <TextField
                            lg={12}
                            md={12}
                            sm={12}
                            type="Text"
                            label='Search Text'
                            value={globalSearch}
                            onChange={event => setGlobalSearch(event.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setGlobalSearch(e.target.value);
                                    setSearchResult();
                                }
                            }}
                        />
                    </Grid>
                    <Grid item container lg={6} justifyContent="center">
                        <Button color='secondary' onClick={setSearchResult} variant='contained' sx={{ padding: "0px 6px 0px 6px !important", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                            <Typography noWrap variant='button'>
                                Search
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                {isLoading && <Grid item container style={{ height: "300px" }} alignItems="center" justifyContent="center">
                    <CircularProgress style={{ marginTop: "20px" }} />
                </Grid>}
                {!isLoading && <TableContainer sx={{ maxHeight: 500, minWidth: 500 }} className="orderTable">
                    <Table className="orderTable">
                        <TableHead>
                            <TableRow className="header">
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>Job Number</Typography>
                                </TableCell>
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>Client PO</Typography>
                                </TableCell>
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>Shipper</Typography>
                                </TableCell>
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>Consignee</Typography>
                                </TableCell>
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b' style={{ marginLeft: "10px", marginRight: "10px" }}>Mode</Typography>
                                </TableCell>
                                {/*<TableCell colSpan={13} align='center'>
                                                        <Typography variant='b'>Cargo Type</Typography>
                                                    </TableCell>
                                                        <TableCell colSpan={13} align='center'>
                                                            <Typography variant='b'>Origin Country</Typography>
                                                        </TableCell>*/}
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>W/H</Typography>
                                </TableCell>
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>Action</Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {globalSearchList.map(data => <TableRow>
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>{data.jobNumber}</Typography>
                                </TableCell>
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>{data.clientPo}</Typography>
                                </TableCell>
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>{data.shipperName}</Typography>
                                </TableCell>
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>{data.customerName} </Typography>
                                </TableCell>
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>{data.mode}</Typography>
                                </TableCell>
                                {/*<TableCell colSpan={13} align='center'>
                                                        <Typography variant='b'>{data.cargoType}</Typography>
                                                    </TableCell>
                                                    <TableCell colSpan={13} align='center'>
                                                        <Typography variant='b'>{data.origin}</Typography>
                                                    </TableCell>*/}
                                <TableCell colSpan={13} align='center'>
                                    <Typography variant='b'>{data.location}</Typography>
                                </TableCell>
                                <TableCell colSpan={13} align='center'>
                                    <Button variant='text' onClick={() => {
                                        if (data.location.includes("-H")) {
                                            window.location = "/orderHistory?search=" + data.jobNumber;
                                        } else {
                                            var selLocation = selectedLocationIndex;
                                            /*setVarInterval(setInterval(() => {
                                                clearInterval(varInterval);
                                                dispatch(onSelectedLocationIndexChange(3));
                                                dispatch(onSearchChange(data.jobNumber));
                                            }, 1000));*/
                                            if (data.location == "IL") {
                                                if (selectedLocationIndex != 3) {
                                                    dispatch(onSelectedLocationIndexChange(3));
                                                    dispatch(onSearchChange(data.jobNumber));
                                                    selLocation = 3;
                                                }
                                            }
                                            else if (data.location == "NL") {
                                                if (selectedLocationIndex != 0) {
                                                    dispatch(onSelectedLocationIndexChange(0));
                                                    dispatch(onSearchChange(data.jobNumber));
                                                    selLocation = 0;
                                                }
                                            }
                                            else if (data.location == "US") {
                                                if (selectedLocationIndex != 1) {
                                                    dispatch(onSelectedLocationIndexChange(1));
                                                    dispatch(onSearchChange(data.jobNumber));
                                                    selLocation = 1;
                                                }
                                            }
                                            else if (data.location == "ALL") {
                                                if (selectedLocationIndex != 2) {
                                                    dispatch(onSelectedLocationIndexChange(2));
                                                    dispatch(onSearchChange(data.jobNumber));
                                                    selLocation = 2;
                                                }
                                            }
                                            var dt = [];
                                            dt.push(data);
                                            var pageName = "Order";
                                            setSearchSpinner(true);
                                            dispatch(onSearchChange(data.jobNumber));
                                            setSearchText(data.jobNumber);
                                            var qp = ""
                                            if (loginUser.department) {
                                                qp += "&userVisibility=" + loginUser.department;
                                            } else
                                                qp += "&userVisibility=1";
                                            sendRequest("/api/order?showUnassignedOrders=true&dep=1&page=0&size=1&search=" + data.jobNumber + qp, REQUEST_ACTIONS.GET, '', {
                                                successCallback: response => {
                                                    setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                                    setSearchSpinner(false);
                                                    onHandleCancelAdvSearchDialog();
                                                },
                                                failedCallback,
                                            });
                                        }

                                    }} style={{ color: "#1976d2", textDecoration: "underline" }}>
                                        <Typography noWrap variant='button'>
                                            Open
                                        </Typography>
                                    </Button>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>}
                <Grid item container justifyContent="flex-end" style={{ marginTop: "10px" }}>
                    <Button variant='text' onClick={onHandleCancelAdvSearchDialog} sx={{ padding: "0px 6px 0px 6px !important", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                        <Typography noWrap variant='button'>
                            Close
                        </Typography>
                    </Button>
                </Grid>
            </Grid>}
        </Grid>
    );
};

export default AdvSearchDialog;