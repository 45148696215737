import { useCallback, useEffect, useState } from 'react';

import { Box, Grid, Button, CircularProgress, Table, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import { sendRequest, REQUEST_ACTIONS } from 'utils/Communicator';
import { snackbarToggle } from "reducers/snackbarSlicer";
import { useDispatch, useSelector } from 'react-redux';
import {addOrderFilter} from 'Utils';
import ChatDialog from './ChatDialog';
import { Dialog } from 'style-guide';
import OrderForm from './OrderForm';
import { styled } from '@mui/system';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const ChatListDialog = ({loginUser}) => {
  const dispatch = useDispatch();
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
  const [chatListSpinner, setChatListSpinner] = useState(false);
  //const [chatList, setChatList] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [openChatDialog, setOpenChatDialog] = useState(false);
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const onOrderModalHandleOpen = () => setOpenOrderModal(true);
    const onOrderModalHandleCancel = () => setOpenOrderModal(false);

    const { chatListNotification } = useSelector(state => state.orders);

  const { 
    userLocalNumber,  
    userAbroadNumber,  
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userTracer,
    userCustoms,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    search,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.orderTableFilter);
  const orderTableFilter = { 
    userLocalNumber,  
    userAbroadNumber,  
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
      userLocalId,
      userTracer,
      userCustoms,
    userAbroadId,
    dueDate,
    lastUpdateDate,
    ilReminderDate,
    reminderDate,
    selectedLocationIndex,
    search,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv,};

  const onChatDialogHandleOpen = () => setOpenChatDialog(true);
  const onChatDialogHandleClose = () => {
    /*setChatListSpinner(true);
      sendRequest(
        `/api/chat/list?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
        REQUEST_ACTIONS.GET,
        '',
        {
          successCallback: response => {
            setChatList(response);
            setChatListSpinner(false);
          },
          failedCallback,
        },
      );*/
    setOpenChatDialog(false);
  }

  const StyledContent = styled('span')`
    cursor: pointer;
    text-decoration: underline;`;

    useEffect(() => {
      /*setChatListSpinner(true);
      /*sendRequest(
        `/api/chat/list?${addOrderFilter(loginUser, '', selectedLocationIndex)}`,
        REQUEST_ACTIONS.GET,
        '',
        {
          successCallback: response => {
            setChatList(response);
            setChatListSpinner(false);
          },
          failedCallback,
        },
      );*/
  }, []);

  const openChatDialogByOrderID = orderId => {
    if (orderId) {
      sendRequest('/api/order/'+orderId, REQUEST_ACTIONS.GET, '', {
        successCallback: response => {
          setCurrentOrder(response);
          onChatDialogHandleOpen();
          //onHandleCancelChatNotificationDialog();
        },
        failedCallback,
      });
   }
  }

  const openOrderDialogByOrderID = orderId => {
    if (orderId) {
      sendRequest('/api/order/'+orderId, REQUEST_ACTIONS.GET, '', {
        successCallback: response => {
          setCurrentOrder(response);
          onOrderModalHandleOpen();
          //onHandleCancelChatNotificationDialog();
        },
        failedCallback,
      });
   }
  }
  return (
    <Grid>
      <div style={{'max-height': '60vh', overflowY:'auto'}}>
        <Box
          component="span"
          sx={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
     {chatListSpinner ? (
          <Table>
            <TableBody>
              <TableRow>
                <TableCell colSpan={13} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
            </Table>
          ) : (
            
          chatListNotification?.length > 0 ? chatListNotification?.map(ch => (
                 <Button display="flex" flexWrap="wrap" component="span"  sx={{cursor: 'default', mr: 2, my: 0.5, border: '1px solid black', color: 'black', justifyContent: 'left',textAlign: 'left' }} style={{textTransform: 'none', backgroundColor:ch.chatText?.toLowerCase()?.indexOf('new order') !== -1?'#E5F6FD':''}}>
                 {ch.chatTime ? new Intl.DateTimeFormat('en-GB', {
                     year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                  }).format(new Date(ch.chatTime)) + ' '+ new Intl.DateTimeFormat('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                  }).format(new Date(ch.chatTime)) : ''}{' ('} {/*<ContentCopyIcon title="Copy Job Number" onClick={() => {
                      navigator.clipboard.writeText(ch.jobNumber);
                      alert("Copied: " + ch.jobNumber);
                  }} style={{ marginBottom: "-7px", cursor: "pointer" }} /> */} <StyledContent onClick={() => openOrderDialogByOrderID(ch.orderId)}>{ch.jobNumber + ' ' + (ch.orderServiceLocation || '-')}</StyledContent> <span style={{ 'margin-right': '3px' }}> {') : ' + ch.chatInitiator + ' : '} </span> <StyledContent onClick={() => openChatDialogByOrderID(ch.orderId)}>{ch.chatText}</StyledContent>
                </Button>
                )) : 'No New Chat Notification'
       )}
        {openChatDialog && (
            <Dialog
              open={openChatDialog}
              onHandleCancel={onChatDialogHandleClose}
              title={'Chat Box - '+currentOrder?.jobNumber}
              maxWidth='md'>
              <ChatDialog onHandleCancel={onChatDialogHandleClose} order={currentOrder} loginUser={loginUser} />
            </Dialog>
          )}
          {openOrderModal && (
            <Dialog
              open={openOrderModal}
              onHandleCancel={onOrderModalHandleCancel}
              title={'Order information - '+currentOrder?.jobNumber}
              maxWidth='md'>
              <OrderForm loginUser={loginUser} onHandleCancel={onOrderModalHandleCancel} order={currentOrder} orderTableFilter={orderTableFilter} pageName='Chat List Dialog'/>
            </Dialog>
          )}
          </Box>
       </div>
    </Grid>
  );
};

export default ChatListDialog;
