/* eslint-disable react/prop-types */
import { Box, Button, List, ListItem, Menu, MenuItem, Grid, InputAdornment } from "@mui/material";
import { TextField } from 'style-guide';
import React, { useState } from "react";
import './CustomerHeader.scss'
//import SearchIcon from "../../../../resources/search_icon.svg"
// import ChatIcon from "../../../../resources/chat_icon.svg"
// import InsightIcon from "../../../../resources/insight_icon.svg"
import UserIcon from "../../../../resources/user.png"
import map from "../../../../resources/map.png"
import SearchIcon from '@mui/icons-material/Search';

const CustomerHeader = ({ loginUser, logoutUser, jobSearch, setJobSearch, mapDisplay, setMapDisplay }) => {
    const [searchKeyWord, setSearchKeyword] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);

    const isDropMenu = Boolean(anchorEl);
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChangeSearch = (e) => {
        setSearchKeyword(e.target.value)
    };

    const onClickSearch = () => {
        alert(`Not found for "${searchKeyWord}"`)
    }

    return (
        <header className="customer-header">
            {/*<Box className="search-box">
                <input type={'search'} placeholder={'Search'} value={searchKeyWord} onChange={(e) => onChangeSearch(e)} />
                <Button
                    title="Search"
                    disabled={!searchKeyWord}
                    type="button"
                    className="search-icon" 
                    onClick={onClickSearch}>
                    <img src={SearchIcon} alt="Search" />
                </Button>
            </Box>*/}
            <Grid item container className="tk-good-times" style={{ marginLeft: "5.2vw", width: "18.5vw" }} alignItems="center" flexWrap="nowrap">
                <SearchIcon style={{width:"1.84vw", height:"auto", color:"lightgrey"}} />
                <TextField
                    xs={12}
                    sm={null}
                    md={null}
                    lg={null}
                    style={{ width: "calc(100% - 1.84vw)", border: "none", height:"2vw", padding:"0", margin:"0" }}
                    label='SEARCH'
                    type='search'
                    className="tk-good-times"
                    value={jobSearch}
                    onChange={event => setJobSearch(event.target.value)}
                />
            </Grid>
            {/*<TextField
                xs={12}
                sm={null}
                md={null}
                lg={null}
                style={{ width: "100%" }}
                label=''
                type='search'
                className=""
                value={jobSearch}
                endAdornment={
                    <InputAdornment position='start'>
                        <SearchIcon onClick={() => setOrderList1(orderList)} />
                    </InputAdornment>
                }
                onChange={event => setJobSearch(event.target.value)}
            />*/}
            <Box className="customer-details">
                <List className="details-list">
                    {/* <ListItem className="list-item">
                        <Button type="button" className="detail-icon">
                            <img src={ChatIcon} alt="Search" className="chat" />
                            <Box component="span" className="chat-count">{'9'}</Box>
                        </Button>
                    </ListItem> */}
                    {/* <ListItem className="list-item">
                        <Button type="button" className="detail-icon">
                            <img src={InsightIcon} alt="Search" className="noti" />
                            <Box component="span" className="chat-count">{'0'}</Box>
                        </Button>
                    </ListItem> */}
                    {/*<Button className="icon-btn-header" style={{  }} onClick={() => setisShipmentList1(!isShipmentList1)}>
                        <img src={map} alt="" />
                        <Box component={'span'}>Map</Box>
                    </Button>*/}
                    <Button className="icon-btn-header" style={{}} onClick={() => setMapDisplay(!mapDisplay) }>
                        <img src={map} alt="" />
                        <Box component={'span'}>Map</Box>
                    </Button>
                    {/*<Button className="icon-btn-header-cust" style={{ border: "none", boxShadow: "none" }} onClick={() => logoutUser()}>
                        <Box component={'span'}>Logout</Box>
                    </Button>
                    <Button className="icon-btn-header-cust" style={{ border: "none", boxShadow: "none" }}>
                        <img src={UserIcon} alt="" />
                        <Box component={'span'}>{loginUser}</Box>
                    </Button>*/}
                    <ListItem className="list-item">
                        <Button id="user-menu" className="icon-btn-header-cust" style={{ border: "none", boxShadow: "none" }}
                            aria-controls={isDropMenu ? 'dropdowm-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={isDropMenu ? 'true' : undefined}
                            onClick={handleClick}>
                            <img src={UserIcon} alt="" />
                            <Box component={'span'}>{loginUser}</Box>
                        </Button>
                        {/*<Button 
                            id="user-menu"
                            type="button"
                            aria-controls={isDropMenu ? 'dropdowm-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={isDropMenu ? 'true' : undefined}
                            onClick={handleClick}
                            >
                            <img src={UserIcon} alt="Search" className="user" />
                            <Box component="span" className="user-name">{loginUser}</Box>
                        </Button>*/}
                        <Menu
                            style={{overflow: "hidden"}}
                            id="dropdowm-menu"
                            anchorEl={anchorEl}
                            open={isDropMenu}
                            onClose={handleClose}
                            MenuListProps={{
                            'aria-labelledby': 'user-menu',
                            }}
                        >
                            <MenuItem className="menuItem" onClick={()=> logoutUser()}>Logout</MenuItem>
                        </Menu>
                    </ListItem>
                </List>
            </Box>
        </header>
    )
};

export default CustomerHeader;