import { useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress, Table, TableBody, Button, TableRow, TableContainer, Paper, TablePagination,TableCell, Typography, Grid } from '@mui/material';
import AuditLogsHeader from './AuditLogsHeader';
import { TextField } from 'style-guide';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';
import { useSelector, useDispatch } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import AuditLogsRow from './AuditLogsRow';

const AuditLogsTable = () => {
    const dispatch = useDispatch();
    const [auditLogsData, setAuditLogsData] = useState([]);
    const [allAuditLog, setAllAuditLog] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [auditLogsDataCount, setAuditLogsDataCount] = useState(0);
    const [jobSearch, setJobSearch] = useState("");

    const failedCallback = useCallback(
        m => dispatch(snackbarToggle({ type: 'error', m })),
        [dispatch],
    );


    // pagination
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const onPageHandleChange = (_, newPage) => {
        setPage(newPage);
    };

    const [searchSpinner, setSearchSpinner] = useState(false);
    const auditlogsByJob = id => {
        var cht = [{ "time": "Mon, 11 Sep 2023 11:24:55 GMT", "text": "New Order has been placed", "initiator": "Nurit Valdman", "initiatorType": "2", "location": "IL", "ILStatus": true, "NLStatus": true }, { "time": "Mon, 11 Sep 2023 11:45:22 GMT", "text": "Order has been confirmed", "initiator": "Rowan van der Beek", "initiatorType": 1, "location": "NL", "ILStatus": true, "NLStatus": true }, { "time": "Mon, 11 Sep 2023 13:30:44 GMT", "text": "Shipper status changed to Ready for pick-up", "initiator": "Rowan van der Beek", "initiatorType": 3, "location": "NL", "ILStatus": true, "NLStatus": true }, { "time": "Mon, 11 Sep 2023 13:31:13 GMT", "text": "please confirm trucking rates for weekend consol", "initiator": "Rowan van der Beek", "initiatorType": 3, "location": "NL", "ILStatus": true, "NLStatus": true }, { "time": "Mon, 11 Sep 2023 13:46:47 GMT", "text": "booked for loading tomorrow unloading 14.09 as confirm by mail", "initiator": "Rowan van der Beek", "initiatorType": 3, "location": "NL", "ILStatus": true, "NLStatus": true }, { "time": "Tue, 12 Sep 2023 05:11:48 GMT", "text": "Thanks dear, trucking rate is approved", "initiator": "Nurit Valdman", "initiatorType": 2, "location": "IL", "ILStatus": true, "NLStatus": true }, { "time": "Tue, 12 Sep 2023 09:26:12 GMT", "text": "Trucking status changed to Booked ", "initiator": "Rowan van der Beek", "initiatorType": 3, "location": "NL", "ILStatus": true, "NLStatus": true }, { "time": "Wed, 13 Sep 2023 09:02:06 GMT", "text": "WH status changed to Arrived", "initiator": "Rowan van der Beek", "initiatorType": 3, "location": "NL", "ILStatus": true, "NLStatus": true }]
        setSearchSpinner(true)
        sendRequest(`/api/auditlogs/allLogs/` + jobSearch, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                setAllAuditLog(response)
                setAuditLogsData(response)
                setSearchSpinner(false)
            },
            failedCallback,
        });
    }
    const onRowsPerPageHandleChange = event => {
        setSize(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        /*if (!allAuditLog.length) {
            sendRequest(`/api/auditlogs/allLogs`, REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    setAllAuditLog(response)
                },
                failedCallback,
            });           
        }*/

        if (startDate && endDate) {
            sendRequest(`/api/auditlogs/search?size=${size}&page=${page}&startDate=`+startDate?.split('.')[0]?.replace('T', ' ')+`&endDate=`+endDate?.split('.')[0]?.replace('T', ' '), REQUEST_ACTIONS.GET, '', {
                successCallback: response => setAuditLogsData(response),
                failedCallback,
            });
            sendRequest(`/api/auditlogs/count?startDate=`+startDate?.split('.')[0]?.replace('T', ' ')+`&endDate=`+endDate?.split('.')[0]?.replace('T', ' '), REQUEST_ACTIONS.GET, '', {
                successCallback: response => setAuditLogsDataCount(response),
                failedCallback,
            });
        }
    }, [page, size, dispatch, failedCallback, startDate, endDate]);



    return (

        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ maxHeight: 800 }}>
                
                <Table>
                    <AuditLogsHeader jobSearch={jobSearch} setJobSearch={setJobSearch} auditlogsByJob={auditlogsByJob} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />

                    
                    <TableBody>

                        {searchSpinner && <TableRow>
                            <TableCell colSpan={7}>
                                <Typography variant='h8'>
                                    <CircularProgress style={{ marginTop: "20px" }} />
                                </Typography>
                            </TableCell>
                        </TableRow>}


                        {auditLogsData?.length > 0 ?
                            auditLogsData?.map(auditLogData => (
                                <AuditLogsRow key={auditLogData?.id || uuidv4()} data={auditLogData} />
                            )) : <TableRow>
                                <TableCell colSpan={7}>
                                        <Typography variant='h8'>
                                            No data available
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component='div'
                count={auditLogsDataCount}
                rowsPerPage={size}
                page={page}
                onPageChange={onPageHandleChange}
                onRowsPerPageChange={onRowsPerPageHandleChange}
            />
        </Paper>
    );
};
export default AuditLogsTable;
