import { Fragment, useCallback, useEffect, useState, useRef } from 'react';

import { Box, Grid, Button, CircularProgress } from '@mui/material';
import { MultilineField, TextField } from 'style-guide';
import { sendRequest, REQUEST_ACTIONS, setOrderData } from 'utils/Communicator';
import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { getOrders, onOrdersCountChange, onChatCountChange, onChatCountCChange, onChatCountTChange, onChatCountCTChange, onUserSpecificOrdersCountChange, onUnConfirmedOrdersCountChange, onPageChange } from 'reducers/order-reducers/ordersSlicer';
import { addOrderFilter, addSearchFilter, usePrevious } from 'Utils';
import CheckBoxIcon from '@mui/icons-material/CheckBoxOutlined';

const ilChatNameStyle = { color: 'blue' };
const nlChatNameStyle = { color: 'orange' };
const adChatNameStyle = { color: 'black' };

const CustChatDialog = ({ onHandleCancel, order, loginUser, pageName }) => {

    const [state, setState] = useState();
    const [chatText, setChatText] = useState("");
    const [chatList, setChatList] = useState(JSON.parse(order['chatCustomer'] ? order['chatCustomer'] : '[]'));
    const [isLoaded, setIsLoaded] = useState(false);
    const [ilUsers, setIlUsers] = useState();
    const [nlUsers, setNlUsers] = useState();
    const [usUsers, setUSUsers] = useState();
    const [ilUsersLoaded, setIlUsersLoaded] = useState(false);
    const [nlUsersLoaded, setNlUsersLoaded] = useState(false);
    const [usUsersLoaded, setUSUsersLoaded] = useState(false);
    const [updatedChatView, setUpdatedChatView] = useState(false);
    const [varInterval, setVarInterval] = useState();
    const { page, size, chatCount, userSpecificOrdersCount } = useSelector(state => state.orders);
    const prevPage = usePrevious({ page });
    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        customerId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userTracer,
        userCustoms,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        search,
        urgentShipment,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.orderTableFilter);
    //var messagesEnd;

    const dispatch = useDispatch();
    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);
    const failedCallback1 = useCallback(message => { }, [dispatch]);
    const mounted = useRef(false);

    const updateSeen = () => {
        return;
        if (chatList?.length != 0 && ilUsersLoaded && nlUsersLoaded && usUsersLoaded && !updatedChatView) {
            var userIL = order.userILId;
            var userNL = order.orderStage[0].orderConfirmationStage[0]?.userNLId;
            let o1 = { ...order }
            if (!order['chat'] || order['chat'] == undefined)
                return;
            var chat_list = JSON.parse(order['chat']);
            var len = chat_list.length;
            var lengthIL = ilUsers.length;
            var lengthNL = nlUsers.length;
            var lengthUS = usUsers.length;
            var changeMade = false;
            var exists = false;
            for (var i = 0; i < lengthIL; i++) {
                if (ilUsers[i].fullName == loginUser.fullName && userIL == ilUsers[i].id) {
                    for (var j = 0; j < len; j++) {
                        if (!chat_list[j]['ILStatus']) {
                            changeMade = true;
                            chat_list[j]['ILStatus'] = true;
                        }
                    }
                }
            }
            if (!exists) {
                for (var i = 0; i < lengthNL; i++) {
                    if (nlUsers[i].fullName == loginUser.fullName && userNL == nlUsers[i].id) {
                        for (var j = 0; j < len; j++) {
                            if (!chat_list[j]['NLStatus']) {
                                changeMade = true;
                                chat_list[j]['NLStatus'] = true;
                            }
                        }
                    }
                }
                for (var i = 0; i < lengthUS; i++) {
                    if (usUsers[i].fullName == loginUser.fullName && userNL == usUsers[i].id) {
                        for (var j = 0; j < len; j++) {
                            if (!chat_list[j]['NLStatus']) {
                                changeMade = true;
                                chat_list[j]['NLStatus'] = true;
                            }
                        }
                    }
                }
            }
            if (changeMade) {
                o1.chat = JSON.stringify(chat_list)
                sendRequest('/api/order?fromStage=0&loginUserId=' + loginUser?.id + '&actionOn=ChatSeen', REQUEST_ACTIONS.POST, o1, {
                    successCallback: response => {
                        setChatListFx1(JSON.parse(o1.chatCustomer));
                        sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                            successCallback: response => {
                                dispatch(onOrdersCountChange(response?.ordersCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                                dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                                dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                                dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount)); },
                            failedCallback,
                        });

                        sendRequest(
                            `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
                            REQUEST_ACTIONS.GET,
                            '',
                            {
                                successCallback: response => {
                                    setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                    if (prevPage?.page == page && addSearchFilter(customerId, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                                        dispatch(onPageChange(0));
                                    }
                                },
                                failedCallback,
                            },
                        );
                    },
                    failedCallback,
                });
            }
        }
    };

    const setChatListFx = (chat) => {
        setChatList(chat);
        updateSeen();
    }

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    useEffect(() => {
        if (!isLoaded) {
            setVarInterval(setInterval(() => {
                sendRequest('/api/order/' + order['id'], REQUEST_ACTIONS.GET, '', {
                    successCallback: response => { setChatListFx(JSON.parse(response['chatCustomer'])); },
                    failedCallback1,
                });
            }, 15000));
            setIsLoaded(true);
        }
        return () => {
            if (!mounted.current) {
                clearInterval(varInterval);
            }
        }
    }, [chatList, setChatList, order, updateSeen,]);

    useEffect(() => {
        // predefined user abroad data get
        if (!nlUsers || nlUsers == undefined) {
            sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setNlUsers(response); setNlUsersLoaded(true) },
                failedCallback,
            });
        }
    }, [nlUsers, failedCallback, setNlUsers]);

    useEffect(() => {
        // predefined user abroad data get
        if (!usUsers || usUsers == undefined) {
            sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setUSUsers(response); setUSUsersLoaded(true) },
                failedCallback,
            });
        }
    }, [usUsers, failedCallback, setUSUsers]);

    useEffect(() => {
        // predefined user local data get
        if (!ilUsers || ilUsers == undefined) {
            sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
                successCallback: response => { setIlUsers(response); setIlUsersLoaded(true) },
                failedCallback,
            });
        }
    }, [ilUsers, failedCallback, setIlUsers]);




    useEffect(() => {
        // for construction
        if (chatList?.length != 0) {
            updateSeen();
            var needReview = false;
            for (var i = 0; i < chatList?.length; i++) {
                if (!chatList['ILStatus']) {
                    needReview = true;
                }
            }
            setUpdatedChatView(needReview);
        }
    }, [ chatList]);


    const setChatListFx1 = (chat) => {
        setChatList(chat);
        setChatText("");
        setUpdatedChatView(true);
    }

    const onHandleSendChat = (statusChatButton) => {
        if (order.orderStage[0].orderConfirmationStage.length == 0) {
            //dispatch(snackbarToggle({ type: 'error', message: 'Order Conformation should be completed before chat can ne used.' }))
            // return;
        }
        if (chatText == "") {
            dispatch(snackbarToggle({ type: 'error', message: 'Text is empty.' }))
            return;
        }
        var userIL = order.userILId;
        var userNL = order.orderStage[0].orderConfirmationStage[0]?.userNLId;
        var lengthIL = ilUsers.length;
        var lengthNL = nlUsers.length;
        var lengthUS = usUsers.length;
        var exists = false;
        var date = new Date();
        var chat = {
            'time': date.toUTCString(),
            'text': chatText,
            'initiator': loginUser.fullName,
            'initiatorType': 2,
            'location': '',
            'ILStatus': true,
            'NLStatus': false
        }
        let o1 = { ...order }

        var chatString = JSON.stringify(chat) //getStatusChatMessage(order, 'Departure', departureStatuses.find(status => status.id === newOrder?.orderStage[4]?.stageStatusInfoId)?.name, ilUsers, nlUsers, usUsers, loginUser

        onUpdateChat(order.id, chatString, 0);
        return;
    }
    const onHandlechangeChat = (value) => {
        setChatText(value);
    }

    const setChatListFx2 = (chat) => {
        setChatList(chat);
        setChatText("");
    }
    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateCustChat/${id}?fromStage=${fromStage}&loginUserId=${loginUser?.id}&actionOn=ChatDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                setChatListFx2(JSON.parse(JSON.stringify([...chatList, JSON.parse(chatString)])));
                sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                    successCallback: response => {

                        dispatch(onOrdersCountChange(response?.ordersCount));
                        sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                        dispatch(onChatCountChange(response?.unreadChatCount));
                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount));
                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                        sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount);
                        dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount));
                        dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));/**/
                        

                    },
                    failedCallback,
                });
                sendRequest(
                    `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
                    REQUEST_ACTIONS.GET,
                    '',
                    {
                        successCallback: response => {
                            setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                            if (prevPage?.page == page && addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                                dispatch(onPageChange(0));
                            }
                        },
                        failedCallback,
                    },
                );
            },
            failedCallback,
        });
    };
    const getInitiatorStyle = (ch) => {
        var type = ch['initiatorType']
        if (type == 5)
            return nlChatNameStyle;
        else
            return ilChatNameStyle;
    }

    /*  const enterKeyPressed = (e) => {
        if(e.keyCode == 13) {
          e.preventDefault();
          onHandleSendChat(11);
        }
      } */
    const reviewChat = (index) => {
        var chList = [];
        var changeMade = true;
        for (var i = 0; i < chatList.length; i++) {
            var chat = JSON.parse(JSON.stringify(chatList[i]));
            chat['ILStatus'] = true;
            chList.push(chat);
        }

        let o1 = { ...order }
        if (changeMade) {
            o1.chatCustomer = JSON.stringify(chList)
            sendRequest('/api/order?fromStage=0&loginUserId=' + loginUser?.id + '&actionOn=ChatSeen', REQUEST_ACTIONS.POST, o1, {
                successCallback: response => {
                    setChatList(JSON.parse(o1.chatCustomer));
                    setChatListFx1(JSON.parse(o1.chatCustomer));
                    sendRequest(`/api/order/count?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}`, REQUEST_ACTIONS.GET, '', {
                        successCallback: response => {
                            dispatch(onOrdersCountChange(response?.ordersCount)); sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                            dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                            dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                            dispatch(onChatCountCChange(response?.unreadChatCustomsCount)); dispatch(onChatCountChange(response?.unreadChatCount)); sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount); dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount)); dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                        },
                        failedCallback,
                    });

                    sendRequest(
                        `/api/order?${addOrderFilter(loginUser, pageName, selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}&page=${prevPage?.page == page && addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)?.length ? 0 : page}&size=${size}`,
                        REQUEST_ACTIONS.GET,
                        '',
                        {
                            successCallback: response => {
                                setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
                                if (prevPage?.page == page && addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)) {
                                    dispatch(onPageChange(0));
                                }
                            },
                            failedCallback,
                        },
                    );
                },
                failedCallback,
            });
        }
    }
    const allChatViewed = () => {
        for (var i = 0; i < chatList.length; i++) {
            if (!chatList['ILStatus'])
                return true;
        }
        return false;
    }
    return (
        <Box component='form' noValidate autoComplete='off'>
            <Grid container spacing={2} position='relative'>

                <Fragment>
                    <div style={{ height: '60vh', width: '900px', overflowY: 'auto', border: '1px solid black', display: 'flex', flexDirection: 'column-reverse' }}>
                        <div style={{ height: 'auto', width: '100%' }}>
                            {chatList?.map((ch, index) => (
                                <Grid item container style={{ width: '100%', padding: '5px' }}> <span style={{ color: 'grey' }}>{(new Date(ch['time'])).toLocaleString()}</span> <span style={getInitiatorStyle(ch)}>{ch['initiator']}</span><span style={{ color: 'black' }}> : {ch['text']}</span></Grid>
                            ))}
                        </div>
                        <div style={{ float: "left", clear: "both" }} id="bottomOfScreen"></div>
                    </div>
                    <Grid container item justifyContent='space-between' flexWrap="nowrap">
                        <MultilineField
                            sm={10}
                            md={null}
                            lg={null} style={{ width: '100%' }} id="chatText" label="Chat" variant="outlined" type="text" value={chatText} onChange={(event) => onHandlechangeChat(event.target.value)}
                        />
                        <Button variant='contained' sx={{ mr: 2, ml: 2 }} onClick={(event) => onHandleSendChat(11)}>
                            Send
                        </Button>
                        {/*{updatedChatView && <Button variant='contained' sx={{ mr: 2 }} onClick={(event) => reviewChat()}>
                            Review
                        </Button>}*/}
                        {<Button variant='contained' sx={{ mr: 2 }} onClick={(event) => reviewChat()}>
                            Review
                        </Button>}
                    </Grid>
                </Fragment>

            </Grid>
        </Box>
    );
};

export default CustChatDialog;
