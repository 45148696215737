import React from 'react';
import { useState } from 'react';
import './CustomerLogin.scss';
import { useNavigate } from 'react-router-dom';
import { REQUEST_ACTIONS, sendRequest } from 'utils/Communicator';

export const CustomerLogin = () => {
    const [userName, setUserName] = new useState("");
    const [password, setPassword] = new useState("");
    const [isEnable, setEnabled] = new useState(true);
    const [hasError, setHasError] = new useState(false);
    const navigate = useNavigate();

    const doLogin = () => {
        setEnabled(false);
        setHasError(false);
        let loginObj = { "username": userName, "password": password }
        sendRequest('/api/login', REQUEST_ACTIONS.POST, loginObj, {
            successCallback: (response) => {
                // navigate("/customerOrders");
                //navigate("/settings/Tracker");
                navigate("/customer/dashboard")
            },
            failedCallback: error => {
                console.error(error);
                setEnabled(true);
                setHasError(true);
            }
        });
    }

    const keyPressed = (e) => {
      if(e.keyCode == 13){
        doLogin();
      }
    }

    return (
        <div className="customer-login-main-container tk-good-times">
            <div className="login-container">
                <div className="login-title">
                    <div className="login-title-strong"></div>
                    <div className="login-title-sub" style={{ color: "#355394", marginBottom: "2vh", marginTop: "5.3vh" }}>FREIGHT ANALYTICS</div>
                    <div className="login-title-sub" style={{ color: "#00AEEF", fontSize: "1.1vw", marginBottom:"1.1vw" }}>Customer Management Service</div>
                </div>
                <div className="login_form"  >
                    <div className="login-label" style={{ width: "100%", textAlign: "center", marginTop: "8vh", marginBottom: "5px" }}>User name</div>
                    <input type="text" className="login-input" disabled={!isEnable} onChange={e => setUserName(e.target.value)} />
                    <div className="login-label password" style={{ width: "100%", textAlign: "center", marginBottom: "5px" }}>Password</div>
                    <input type="password" className="login-input" disabled={!isEnable} onKeyDown={e => keyPressed(e)} onChange={e => setPassword(e.target.value)} />
                    <div>
                        <button type="button" value="Login" disabled={!isEnable} className="login-button tk-good-times" onClick={doLogin}>
                            <span className="btn-label" style={{fontSize:"0.7vw"}}>LOGIN</span>
                            {!isEnable &&
                                <em className="btn-loading" />
                            }
                        </button>
                    </div>
                </div>
                <div className="login-error">{hasError && "Invalid login!"}</div>
            </div>
        </div>
    );
}

export default CustomerLogin;