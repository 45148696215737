import { useCallback, useState, Fragment, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import FormData from 'form-data';
import { v4 as uuidv4 } from 'uuid';

import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Dialog, SelectField } from 'style-guide';

import { REQUEST_ACTIONS, sendRequest, setOrderData } from 'utils/Communicator';

import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from 'reducers/order-reducers/ordersSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';
import { onFormDataChange } from 'reducers/order-reducers/orderInfoSlicer';

import './style.scss';
import { onFileCodesChange } from 'reducers/order-reducers/predefinedDataSlicer';
import {addOrderFilter, addSearchFilter} from 'Utils';

const file_codes=["AGENT_RATE", "COM_INVOICE", "CUSTOM_DEC", "DN", "CUSTOMER_DOC", "GENERAL", "HAWB", "INVOICE", "MAWB", "PERMITS", "PACKING", "QUOTATION", "EURO1", "MSDS", "PICTURES"]

const Dropzone = ({ loginUser, orderId, orderStageId, onFilesAdd, onCodeFileCountAdd, type, pageName, onHandleCloseParentModal }) => {
  const dispatch = useDispatch();
  const { fileCodes } = useSelector(state => state.predefinedData);
  const { page, size } = useSelector(state => state.orders);
  const { 
    userLocalNumber,  
    userAbroadNumber,  
    consigneeId,
    shipperId,
    countryOfOriginId, originState,
    shippingModeId,
    subModeId,
    userLocalId,
    userTracer,
    userCustoms,
      userAbroadId,
      urgentShipment,
      customerId, 
    dueDate,
    lastUpdateDate,
    reminderDate,
    ilReminderDate,
    selectedLocationIndex,
    search,
    startReminderDate,
    endReminderDate,
    startIlReminderDate,
    endIlReminderDate,
    stageSearch,
    stageStateSearch,
    reminderDateAdv, ilReminderDateAdv,} = useSelector(state => state.orderTableFilter);
  const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);
  const [codeId, setCodeId] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [openCodeModal, setOpenCodeModal] = useState(false);
  const onCodeModalHandleCancel = () => setOpenCodeModal(false);
  const onCodeModalHandleOpen = () => setOpenCodeModal(true);

  useEffect(() => {
    setFiles(prevState => [...prevState, file]);
  }, [file]);

  useEffect(() => {
    if (!fileCodes) {
      // predefined file codes data get
      sendRequest('/api/codes', REQUEST_ACTIONS.GET, '', {
        successCallback: response => dispatch(onFileCodesChange(response)),
        failedCallback,
      });
    }
  }, [dispatch, failedCallback, fileCodes]);

  useEffect(() => {
    setIsLoaded(!!fileCodes);
  }, [fileCodes]);

    const onDrop = useCallback(acceptedFiles => {
        //console.log("/api/emptyData")
        if (acceptedFiles[0].size < 5242880) {
            setFile(acceptedFiles[0]);
            onCodeModalHandleOpen();
            setCodeId('');
        }
        else {
            dispatch(snackbarToggle({ type: 'error', message: 'File size should be less than 5MB' }));
        }
  }, []);

  const getFileCodes = (codeId) => {
    if (file_codes.length < codeId) {
      const code = fileCodes.find(code => code.id === codeId);
      return code?.name?.replace(/ /g,"_")?.toUpperCase();
    } else {
      return file_codes[codeId-1];
    }
  }

  const onHandleSave = () => {
    if (!codeId) {
      return dispatch(snackbarToggle({ type: 'error', message: 'File code is required' }));
    }


    const time_now = new Date().toLocaleString("en-EN", {timeZone: 'Israel'}).split(",");
    const cur_date = time_now[0].split("/");
    const file_ext = file.name.split(".");
    const show_name = "" + getFileCodes(codeId) + "_" + cur_date[0] + "_" + cur_date[1] + "_" + cur_date[2] + "_" + new Date(time_now).getTime() + "." + file_ext[1];

    const codeFileName = {
      codeId,
      fileName: show_name,
    };
    onFilesAdd({ orderStageId, orderId, name: show_name, codeId, id: uuidv4()});

    const formData = new FormData();
    formData.append('code', JSON.stringify([codeFileName]));

    if (type === 'newOrder' && !orderStageId && !orderId) {
      const code = fileCodes.find(code => code.id === codeId);

      const codeFileCount = {
        code: code.name,
        count: 1,
      };

      onCodeFileCountAdd(codeFileCount);
      files.forEach(file => file && formData.append('files', file, getFileCodes(codeId) + "_" + cur_date[0] + "_" + cur_date[1] + "_" + cur_date[2] + "_" + new Date(time_now).getTime()+ "." + file.name.split(".")[1]));
      dispatch(onFormDataChange(formData));
      return onCodeModalHandleCancel();
    }

    formData.append('files', file, show_name);
    formData.append('orderStageId', orderStageId);
    formData.append('orderId', orderId);
      formData.append('loginUserId', loginUser.id);
    //for(var pair of formData.entries()) {
      //console.log(pair[0]+ ', '+ pair[1]);
    //}
    sendRequest('/api/upload', REQUEST_ACTIONS.POST, formData, {
      successCallback: message => {
            sendRequest(`/api/order?${addOrderFilter(loginUser, '', selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate , startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, pageName)}page=${page}&size=${size}`, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                setOrderData(dispatch, getOrders, response, loginUser, failedCallback);
            dispatch(snackbarToggle({ type: 'success', message }));
            onCodeModalHandleCancel();
            if (onHandleCloseParentModal) {
              onHandleCloseParentModal();
            }
          },
          failedCallback,
        });
      },
      failedCallback,
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <Fragment>
      <div {...getRootProps({ className: isDragActive ? `dropzone dashed` : 'dropzone' })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography component='span'>Drop the files here ...</Typography>
        ) : (
          <Typography component='span'>Click here to upload files, Or Drag and Drop files here</Typography>
        )}
      </div>
      {openCodeModal && (
        <Dialog open={openCodeModal} onHandleCancel={onCodeModalHandleCancel} title='Code' maxWidth='md'>
          {!isLoaded ? (
            <Grid container justifyContent='center' mt={2}>
              <CircularProgress />
            </Grid>
          ) : (
            <Grid container spacing={2} minWidth={500}>
              <SelectField
                required
                lg={null}
                md={null}
                sm={null}
                label='File code'
                value={codeId}
                defaultMenuIsOpen={true}
                onChange={event => setCodeId(event.target.value)}
                options={fileCodes}
              />
              <Grid container item justifyContent='flex-end'>
                <Button onClick={onHandleSave} variant='contained' sx={{ mr: 2 }}>
                  Save
                </Button>
                <Button onClick={onCodeModalHandleCancel} variant='contained' color='secondary'>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}
        </Dialog>
      )}
    </Fragment>
  );
};

export default Dropzone;
