import { useState, useEffect, useCallback, Fragment } from 'react';
import { Link, Route, Routes, useNavigate, useLocation } from 'react-router-dom';

import { Button, CircularProgress, Grid, Typography, Tab, Tabs, TableCell } from '@mui/material';
import { Dialog, SelectField, Tooltip } from 'style-guide';
import OrderTable from './OrderTable/OrderTable';
import OrderForm from './OrderForm';
import OrderSearch from './OrderTable/OrderSearch';
import Tracker from 'shipTracker/Tracker';
import { styled } from '@mui/system';

import { sendRequest, REQUEST_ACTIONS, setOrderData } from 'utils/Communicator';
import { COUNTRY_CODE_LIST } from 'constants/global-constants';
import { STAGES_OPTIONS, USA_STATES } from 'constants/global-constants';
import * as XLSX from 'xlsx';
import { addOrderFilter, addSearchFilter, usePrevious, StyledTableCell, advancedSearchDefaultStyle, filterServiceProvider } from 'Utils';
import { onChatListChange, getOrders, onOrdersCountChange, onChatCountChange, onChatCountTChange, onChatCountCChange, onChatCountCTChange, onUserSpecificOrdersCountChange, onUnConfirmedOrdersCountChange, onPageChange } from 'reducers/order-reducers/ordersSlicer';
import {
    onSearchChange,
} from 'reducers/order-reducers/orderTableFilterSlicer';
import { useDispatch, useSelector } from 'react-redux';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { onUnConfirmedParcelsCountChange } from 'reducers/parcel-reducers/ordersSlicer';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AdvSearchDialog from './OrderTable/AdvSearchDialog';
import {
    onUserNLDataChange,
    onUserUSDataChange,
    onUserILDataChange,
    onUserILNamesChange,
    onUserNLNamesChange,
    onUserUSNamesChange,
    onCustomerNamesChange,
    onSubModeDataChange,
    onShippingModeDataChange,
    onCountryOfOriginDataChange,
    onAirPoaDataChange,
    onAirPolDataChange,
    onLclPoaDataChange,
    onLclPolDataChange,
    onIncotermsDataChange,
    onFileCodesChange,
    onFCLType20DataChange,
    onFCLType40DataChange,
} from 'reducers/order-reducers/predefinedDataSlicer';
import {
    onConsigneeIdChange,
    onCountryOfOriginIdChange,
    onShipperIdChange,
    onShippingModeIdChange,
    onSubModeIdChange,
    onUserLocalIdChange,
    onUserTracerChange,
    onUserCustomsChange,
    onUserAbroadIdChange,
    onIlReminderDateChange,
    OnStageSearchChange,
    onReminderDateChange,
    onOriginStateChange,
    resetOrderTableFilterSlice,
    resetAdvancedSearchFilter,
    onUrgentShipmentChange,
    onBrokerIdChange,
    onSelectedLocationIndexChange,
    onSelectedTabIndexNameChange,
} from 'reducers/order-reducers/orderTableFilterSlicer';
import { onShipperNameOptionsChange, onShippersChange } from 'reducers/shippersSlicer';
import { onCustomerNameOptionsChange, onCustomersChange } from 'reducers/customersSlicer';
import { onConsigneeNameOptionsChange, onConsigneesChange } from 'reducers/consigneesSlicer';
import { snackbarToggle } from 'reducers/snackbarSlicer';

import { sendNewChatMsgNotification, sendNewOrderNotification } from 'utils/Notifications';

export const orderRowBgColor = {
    'background-color': '#057c054d',
}

const Orders = ({ loginUser, redboxParcels, agentOrders, setIsUsTime, isTrackerLoaded }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const onHandleChange = (event, action) => dispatch(action(event.target.value));
    const { unConfirmedOrdersCount } = useSelector(state => state.orders);
    const { subModeData, shippingModeData, countryOfOriginData, userILData, userNLData, userUSData, userNLNames, lclPolData, lclPoaData, airPoaData, airPolData } = useSelector(
        state => state.predefinedData,
    );
    const { incotermsData, userILNames, fclType20Data, fclType40Data } = useSelector(
        state => state.predefinedData,
    );
    const { page, size, chatCount, userSpecificOrdersCount } = useSelector(state => state.orders);
    const prevPage = usePrevious({ page });
    const [isLoading, setIsLoading] = useState(false);

    const [searchText, setSearchText] = useState("");
    const [fileNumber, setFileNumber] = useState("");
    const [shipperStatuses, setshipperStatuses] = useState([]);
    const [truckingStatuses, settruckingStatuses] = useState([]);
    const [departureStatuses, setdepartureStatuses] = useState([]);
    //const { orders } = useSelector(state => state.orders);
    const [isTrackerLoaded1, setIsTrackerLoaded1] = useState(false);
    const onHandleSelectedLocationTrackerChange = (event, newValue) => {
        navigate("/tracker")
        /*if (newValue == 0) {
            navigate("/settings/Tracker")
        } else {
            setIsUsTime(0)
        }*/
    }
    const onHideTracker = () => {
    }
    const onHandleSelectedLocationIndexChange = (event, newValue) => {
        setSearchText("");
        navigate("/home")
        dispatch(resetOrderTableFilterSlice(null));
        dispatch(onUserAbroadIdChange(null));
        if (newValue === 'US') {
            setIsUsTime(1)
        } else {
            setIsUsTime(0)
        }
        dispatch(onPageChange(0));
        dispatch(onSelectedLocationIndexChange(newValue));
    }

    const {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        customerId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userTracer,
        userCustoms, urgentShipment,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        selectedTabIndexName,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv, } = useSelector(state => state.orderTableFilter);
    const orderTableFilter = {
        userLocalNumber,
        userAbroadNumber,
        consigneeId,
        shipperId,
        countryOfOriginId, originState,
        shippingModeId,
        subModeId,
        userLocalId,
        userTracer,
        userCustoms, urgentShipment,
        userAbroadId,
        dueDate,
        lastUpdateDate,
        ilReminderDate,
        reminderDate,
        selectedLocationIndex,
        selectedTabIndexName,
        search,
        startReminderDate,
        endReminderDate,
        startIlReminderDate,
        endIlReminderDate,
        stageSearch,
        stageStateSearch,
        reminderDateAdv, ilReminderDateAdv,
    };
    const { shippers } = useSelector(state => state.shippers);
    const { customers } = useSelector(state => state.customers);
    const { consignees } = useSelector(state => state.consignees);
    const [brokers, setBrokers] = useState([]);

    const failedCallback = useCallback(message => dispatch(snackbarToggle({ type: 'error', message })), [dispatch]);

    const [isLoaded, setIsLoaded] = useState(true);
    const [searchSpinner, setSearchSpinner] = useState(false);

    const [globalSearchList, setGlobalSearchList] = useState([]);
    const [globalSearch, setGlobalSearch] = useState(false);
    const [advSearchDialog, setAdvSearchDialog] = useState(false);
    const onHandleCancelAdvSearchDialog = () => setAdvSearchDialog(false);
    const onHandleOpenAdvSearchDialog = () => setAdvSearchDialog(true);
    const setSearchResult = () => {
        setIsLoading(true);
        var list = [];
        sendRequest("/api/order/count?showUnassignedOrders=true&dep=1&search=" + globalSearch, REQUEST_ACTIONS.GET, '', {
            successCallback: response => {
                var ordersCount = response.ordersCount;
                if (ordersCount != 0) {
                    sendRequest("/api/order?showUnassignedOrders=true&dep=1&page=0&size=" + ordersCount + "&search=" + globalSearch, REQUEST_ACTIONS.GET, '', {
                        successCallback: response => {
                            for (var i = 0; i < response.length; i++) {
                                list.push({
                                    jobNumber: response[i].jobNumber,
                                    clientPo: response[i].suppliersPO,
                                    mode: response[i].shippingModeId == 3 ? "FCL" : (response[i].shippingModeId == 2 ? "LCL" : "AIR"),
                                    shipperName: response[i].shipper?.companyShortName ? response[i].shipper.companyShortName : response[i].shipper.companyName,
                                    customerName: response[i].consignee?.companyName,
                                    origin: response[i].countryOfOriginId,
                                    location: getLocationFromId(response[i].locationId)
                                })
                            }
                            setIsLoading(false);
                            setGlobalSearchList(list);
                        },
                        failedCallback,
                    });
                } else {
                    sendRequest("/api/order/count?showHistoryOrdersOnly=true&showUnassignedOrders=true&dep=1&search=" + globalSearch, REQUEST_ACTIONS.GET, '', {
                        successCallback: response => {
                            var ordersCount = response.ordersCount;
                            if (ordersCount != 0) {
                                sendRequest("/api/order?showHistoryOrdersOnly=true&showUnassignedOrders=true&dep=1&page=0&size=" + ordersCount + "&search=" + globalSearch, REQUEST_ACTIONS.GET, '', {
                                    successCallback: response => {
                                        for (var i = 0; i < response.length; i++) {
                                            list.push({
                                                jobNumber: response[i].jobNumber,
                                                clientPo: response[i].suppliersPO,
                                                mode: response[i].shippingModeId == 3 ? "FCL" : (response[i].shippingModeId == 2 ? "LCL" : "AIR"),
                                                shipperName: response[i].shipper?.companyShortName ? response[i].shipper.companyShortName : response[i].shipper.companyName,
                                                customerName: response[i].consignee?.companyShortName ? response[i].consignee.companyShortName : response[i].consignee.companyName,
                                                origin: response[i].countryOfOriginId,
                                                location: getLocationFromId(response[i].locationId) + "-H"
                                            })
                                        }
                                        setIsLoading(false);
                                        setGlobalSearchList(list);
                                    },
                                    failedCallback,
                                });
                            } else { }
                        },
                        failedCallback,
                    });
}
            },
            failedCallback,
        });
    }
    const getLocationFromId = (id) => {
        if (Number(id) == 1) {
            return "NL";
        } else if (Number(id) == 2) {
            return "EXP";
        } else if (Number(id) == 4) {
            return "US";
        } else if (Number(id) == 5) {
            return "ALL"
        }
    }

    const [selectedGeneral, setSelectedGeneral] = useState(true);
    const [openOrderDialog, setOpenOrderDialog] = useState(false);
    const onHandleOpenDialog = () => setOpenOrderDialog(true);
    const onHandleCancelDialog = () => setOpenOrderDialog(false);
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(resetOrderTableFilterSlice(null));
        if (location?.state?.name == "location") {
            dispatch(onSelectedLocationIndexChange(location?.state.locInd));
            dispatch(onSelectedTabIndexNameChange(location?.state?.tabInd));
        }
        console.log(loginUser);
    }, []);
    useEffect(() => {
        if (!fclType20Data) {
            sendRequest('/api/fclType20', REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(onFCLType20DataChange(response))
                },
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, fclType20Data]);
    useEffect(() => {
        if (!fclType40Data) {
            sendRequest('/api/fclType40', REQUEST_ACTIONS.GET, '', {
                successCallback: response => {
                    dispatch(onFCLType40DataChange(response))
                },
                failedCallback,
            });
        }
    }, [dispatch, failedCallback, fclType40Data]);

    const jsonToExcel = function (data, name, header) {
        var ws = XLSX.utils.json_to_sheet(data, header);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "All");
        XLSX.writeFile(wb, name + ".xlsx");
    }
    const csvExport = () => {
        setSearchSpinner(true);
        sendRequest(`/api/order/count?${addOrderFilter(loginUser, "Orders", selectedLocationIndex)}${addSearchFilter(null, null, null, null, consigneeId, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)}`, REQUEST_ACTIONS.GET, '', {
            successCallback: res1 => {
                sendRequest(
                    `/api/order?${addOrderFilter(loginUser, "Orders", selectedLocationIndex)}${addSearchFilter(null, null, null, null, consigneeId, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)}&page=0&size=${res1.ordersCount}`,
                    REQUEST_ACTIONS.GET,
                    '',
                    {
                        successCallback: resp => {
                            setOrderData(dispatch, getOrders, resp, loginUser, failedCallback, function (reap) {
                                if (!reap)
                                    return;
                                var data_main = [];
                                var header = ["Export", "File", "JOB", "PO", "Supplier Name", "QTY", "Package", "Weight", "Volume", "INCOTERMS", "Origin Country", "Load Port", "Discharge Port", "ETD", "ETA", "Vessel name", "Container", "Last Cus. Event"]
                                //data_main.push(header);
                                for (var i = 0; i < reap.length; i++) {
                                    var data = {
                                        "Export": "",
                                        "Customer": reap[i].consignee?.companyName ? reap[i].consignee.companyName : "-",
                                        "File": reap[i]?.orderStage[4]?.departureStage[0]?.fileNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.fileNumber : "-",
                                        "JOB": reap[i].jobNumber ? reap[i].jobNumber : "-",
                                        "PO": reap[i]?.suppliersPO ? reap[i]?.suppliersPO : "-",
                                        "Sub Mode": subModeData?.find(mode => mode.id === reap[i].subModeId)?.name || '-',
                                        "Supplier Name": reap[i]?.shipper?.companyName || '-',
                                        "QTY": getOrderQuantity(reap[i]),
                                        "Package": reap[i].shippingModeId == 3 ? getFCLType(reap[i]) : "PACKAGES",
                                        "Weight": getOrderWeight(reap[i]),
                                        "Volume": getOrderVolume(reap[i]),
                                        "INCOTERMS": incotermsData.find(inco => inco.id == reap[i]?.incotermsId).name || '-',
                                        "Origin Country": countryOfOriginData?.find(country => country.id === reap[i].countryOfOriginId)?.name,
                                        "Load Port": ((reap[i]?.shippingModeId === 1) ? (airPolData?.find(pol => pol.id === reap[i].polId)?.name) : (lclPolData?.find(pol => pol.id === reap[i].polId)?.name)) || '-',
                                        "Discharge Port": ((reap[i]?.shippingModeId === 1) ? (airPoaData?.find(poa => poa.id === reap[i].poaId)?.name) : (lclPoaData?.find(poa => poa.id === reap[i].poaId)?.name)) || '-',
                                        "ETD": formatDateTime(reap[i]?.orderStage[4]?.departureStage[0]?.etd),
                                        "ETA": formatDateTime(reap[i].airStatus?.podDate ? reap[i].airStatus?.podDate : (reap[i].orderStage[4]?.departureStage[0]?.eta ? reap[i].orderStage[4]?.departureStage[0].eta : null)),
                                        "Vessel name": reap[i]?.orderStage[4]?.departureStage[0]?.vesselNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.vesselNumber : "-",
                                        "Container": reap[i]?.orderStage[4]?.departureStage[0]?.containerNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.containerNumber : "-",
                                        "AWB": reap[i]?.shippingModeId === 1 ? (reap[i]?.orderStage[4]?.departureStage[0]?.awb ? reap[i]?.orderStage[4]?.departureStage[0]?.awb : "-") : "-",
                                        "Flight": reap[i]?.orderStage[4]?.departureStage[0]?.flightNumber ? reap[i]?.orderStage[4]?.departureStage[0]?.flightNumber : "-",
                                        "Last Cus. Event": getLastEvent(reap[i]),
                                    };
                                    data_main.push(data);
                                }
                                jsonToExcel(data_main, "orders", header);
                                sendRequest(`/api/order/count?${addOrderFilter(loginUser, "Orders", selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, "Orders")}`, REQUEST_ACTIONS.GET, '', {
                                    successCallback: response => {
                                        dispatch(onOrdersCountChange(response?.ordersCount));
                                        sendNewChatMsgNotification(loginUser, dispatch, chatCount, response?.unreadChatCount);
                                        dispatch(onChatCountChange(response?.unreadChatCount));
                                        dispatch(onChatCountTChange(response?.unreadChatTracerCount));
                                        dispatch(onChatCountCChange(response?.unreadChatCustomsCount));
                                        dispatch(onChatCountCTChange(response?.unreadChatCTCount));
                                        sendNewOrderNotification(loginUser, dispatch, userSpecificOrdersCount, response?.userSpecificOrdersCount);
                                        dispatch(onUserSpecificOrdersCountChange(response?.userSpecificOrdersCount));
                                        dispatch(onUnConfirmedOrdersCountChange(response?.unConfirmedOrdersCount));
                                    },
                                    failedCallback,
                                });
                                sendRequest(
                                    `/api/order?${addOrderFilter(loginUser, "Orders", selectedLocationIndex)}${addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, "Orders")}&page=${prevPage?.page == page && addSearchFilter(customerId, urgentShipment, userLocalNumber, userAbroadNumber, consigneeId, shipperId, countryOfOriginId, originState, shippingModeId, subModeId, userLocalId, userTracer, userCustoms, userAbroadId, dueDate, search, lastUpdateDate, reminderDate, ilReminderDate, startReminderDate, endReminderDate, startIlReminderDate, endIlReminderDate, stageSearch, stageStateSearch, reminderDateAdv, ilReminderDateAdv, "Orders")?.length ? 0 : page}&size=${size}`,
                                    REQUEST_ACTIONS.GET,
                                    '',
                                    {
                                        successCallback: response => {
                                            setOrderData(dispatch, getOrders, response, loginUser, failedCallback, function (data) {
                                                setSearchSpinner(false);
                                            });
                                        },
                                        failedCallback,
                                    },
                                );
                            });
                        },
                        failedCallback,
                    },
                );
            },
            failedCallback,
        });
    }
    const getFCLType = (order) => {
        var res = null;
        var res1 = null;
        if (order.orderFCLDetails?.fclType20Id) {
            res = fclType20Data.find(fcl => fcl.id == order.orderFCLDetails?.fclType20Id);
            if (order.orderFCLDetails?.fclType40Id) {
                res1 = fclType40Data.find(fcl => fcl.id == order.orderFCLDetails?.fclType40Id);
            }
        }
        else if (order.orderFCLDetails?.fclType40Id) {
            res = fclType40Data.find(fcl => fcl.id == order.orderFCLDetails?.fclType40Id);
        }
        return res ? res.name + (res1 ? (", " + res1.name) : "") : "-"
    }
    const getLastEvent = (order) => {
        if (order.orderStatus[8]?.status === "IN_PROGRESS" || order.orderStatus[8]?.status === "FAILED")
            return "Delivery to Consignee";
        if (order.orderStatus[7]?.status === "IN_PROGRESS" || order.orderStatus[7]?.status === "FAILED")
            return "Customs - " + getCustomStatus(order);
        if (order.orderStatus[6]?.status === "IN_PROGRESS" || order.orderStatus[6]?.status === "FAILED")
            return "POD - " + getPodStatus(order);
        if (order.orderStatus[5]?.status === "IN_PROGRESS" || order.orderStatus[5]?.status === "FAILED")
            return "POL - " + getPodStatus(order);
        if (order.orderStage[4]?.status === "IN_PROGRESS" || order.orderStage[4]?.status === "FAILED")
            return "Departure Waiting";
        if (order.orderStage[3]?.status === "IN_PROGRESS" || order.orderStage[3]?.status === "FAILED")
            return "In Warehouse";
        if (order.orderStage[2]?.status === "IN_PROGRESS" || order.orderStage[2]?.status === "FAILED")
            return "In Trucking";
        if (order.orderStage[1]?.status === "IN_PROGRESS" || order.orderStage[1]?.status === "FAILED")
            return "In Shipper";
        if (order.orderStage[0]?.status === "IN_PROGRESS" || order.orderStage[0]?.status === "FAILED")
            return "Need Confirmation";
    }
    const getPodStatus = (order) => {
        var res = 0;
        if (order.shippingModeId == 1) {
            res = order.airStatus?.lStatus ? order.airStatus?.lStatus : "";
        } else {
            res = getSinayStatus(order.sinay?.status?.val?.metadata?.shippingStatus ? order.sinay?.status?.val?.metadata?.shippingStatus : "", order.sinay?.status ? order.sinay?.status : "")
        }
        return res ? res : "";
    }
    const getCustomStatus = (order) => {
        if (order.shippingModeId == 1) {
            return order.airStatus?.lStatus ? order.airStatus?.lStatus : "";
        } else {
            return getTaskyamStatus(order.taskyam?.data?.status)
        }
    }
    const getSinayStatus = (str, status) => {
        var msg = status?.val?.message ? status?.val?.message : "";
        if (msg) {
            return msg;
        }
        return str;
    }
    const getTaskyamStatus = (id) => {
        switch (id) {
            case 0:
                return "";
            case 1:
                return "מצהר";
            case 2:
                return "זמינות";
            case 3:
                return "פקודת מסירה";
            case 4:
                return "בדיקת מכס";
            case 5:
                return "התרת מכס";
            case 6:
                return "התרת נמל";
            case 7:
                return "תעודת ליווי";
            case 8:
                return "בקשה להוצאה";
            case 9:
                return "משוב מהנמל";
            case 10:
                return "יציאת מטען";
        }
        return "-"
    }
    const formatDateTime = (date) => {
        if (!date)
            return "-";
        var dt = new Date(date);
        const padL = (nr, len = 2, chr = `0`) => `${nr}`.padStart(2, chr);
        return `${padL(dt.getDate())}/${padL(dt.getMonth() + 1)}/${dt.getFullYear().toString().substr(-2)}`;
    }
    const getOrderQuantity = (order) => {
        var res = 0;
        if (order.orderDetails) {
            for (var i = 0; i < order.orderDetails.length; i++) {
                res += order.orderDetails[i].numberOfPieces;
            }
        }
        if (order.orderFCLDetails?.quantity40) {
            res += order.orderFCLDetails.quantity40
        }
        if (order.orderFCLDetails?.quantity20) {
            res += order.orderFCLDetails.quantity20
        }
        return res;
    }
    const getOrderWeight = (order) => {
        var res = 0;
        if (order.orderDetails) {
            for (var i = 0; i < order.orderDetails.length; i++) {
                res += order.orderDetails[i].weightPerPiece;
            }
        }
        return res;
    }
    const getOrderVolume = (order) => {
        var res = 0;
        if (order.orderDetails) {
            for (var i = 0; i < order.orderDetails.length; i++) {
                res += (order.orderDetails[i].height * order.orderDetails[i].width * order.orderDetails[i]["length"]) / 1000000;
            }
        }
        return res;
    }
    useEffect(() => {
        console.log("orders use effect");
        // predefined sub mode data get
        sendRequest('/api/subMode', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onSubModeDataChange(response)),
            failedCallback,
        });

        // predefined shipping mode data get
        sendRequest('/api/shippingMode', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onShippingModeDataChange(response)),
            failedCallback,
        });

        // predefined country of origin data get
        sendRequest('/api/countryOfOrigin', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onCountryOfOriginDataChange(response)),
            failedCallback,
        });

        // predefined shippers data get
        sendRequest('/api/serviceProvider/1?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onShippersChange(response)),
            failedCallback,
        });

        // predefined consignee data get
        sendRequest('/api/serviceProvider/2?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onConsigneesChange(response)),
            failedCallback,
        });

        // predefined customer data get
        sendRequest('/api/serviceProvider/13?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onCustomersChange(response)),
            failedCallback,
        });

        // predefined customer data get
        sendRequest('/api/serviceProvider/12?activeFlag=true', REQUEST_ACTIONS.GET, '', {
            successCallback: response => setBrokers(response),
            failedCallback,
        });

        // predefined user local data get
        sendRequest('/api/users/IL', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onUserILDataChange(response)),
            failedCallback,
        });

        // predefined user abroad data get
        sendRequest('/api/users/NL', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onUserNLDataChange(response)),
            failedCallback,
        });

        // predefined user abroad data get
        sendRequest('/api/users/US', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onUserUSDataChange(response)),
            failedCallback,
        });

        // predefined air poa data get
        sendRequest('/api/poa/air', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onAirPoaDataChange(response)),
            failedCallback,
        });

        // predefined air pol data get
        sendRequest('/api/pol/air', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onAirPolDataChange(response)),
            failedCallback,
        });

        // predefined lcl poa data get
        sendRequest('/api/poa/lcl', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onLclPoaDataChange(response)),
            failedCallback,
        });

        // predefined lcl pol data get
        sendRequest('/api/pol/lcl', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onLclPolDataChange(response)),
            failedCallback,
        });

        // predefined incoterms data get
        sendRequest('/api/incoterms', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onIncotermsDataChange(response)),
            failedCallback,
        });

        // predefined code data get
        sendRequest('/api/codes', REQUEST_ACTIONS.GET, '', {
            successCallback: response => dispatch(onFileCodesChange(response)),
            failedCallback,
        });
    }, [dispatch, failedCallback]);

    useEffect(() => {
        const userILNameOptions = userILData.map(user => ({
            id: user.id,
            name: user.fullName,
            department: user.department,
        }));

        dispatch(onUserILNamesChange(userILNameOptions));
    }, [userILData, dispatch]);

    useEffect(() => {
        const userNLNameOptions = userNLData.map(user => ({
            id: user.id,
            name: user.fullName,
        }));

        dispatch(onUserNLNamesChange(userNLNameOptions));
    }, [userNLData, dispatch]);

    useEffect(() => {
        const userUSNameOptions = userUSData.map(user => ({
            id: user.id,
            name: user.fullName,
        }));

        dispatch(onUserUSNamesChange(userUSNameOptions));
    }, [userUSData, dispatch]);

    useEffect(() => {
        const shipperNameOptions = shippers.map(shipper => ({
            id: shipper.id,
            name: shipper.companyName,
            serviceProviderLocationId: shipper.serviceProviderLocationId,
        }));

        dispatch(onShipperNameOptionsChange(shipperNameOptions));
    }, [shippers, dispatch]);

    useEffect(() => {
        const customerNameOptions = customers.map(customer => ({
            id: customer.id,
            name: customer.companyName,
            serviceProviderLocationId: customer.serviceProviderLocationId,
        }));

        dispatch(onCustomerNameOptionsChange(customerNameOptions));
    }, [customers, dispatch]);

    useEffect(() => {
        const consigneeNameOptions = consignees.map(consignee => ({
            id: consignee.id,
            name: consignee.companyName,
            serviceProviderLocationId: consignee.serviceProviderLocationId,
        }));

        dispatch(onConsigneeNameOptionsChange(consigneeNameOptions));
    }, [consignees, dispatch]);

    useEffect(() => {
        setIsLoaded(subModeData && shippingModeData && countryOfOriginData && userILData && userNLData && userUSData);
    }, [subModeData, shippingModeData, countryOfOriginData, userILData, userNLData, userUSData]);


    return (
        <Grid container>
            {!isLoaded ? (
                <Grid container item justifyContent='center' mt={8}>
                    <CircularProgress style={{ marginTop: "20px" }} />
                </Grid>
            ) : (
                <Fragment>
                    <Grid container rowSpacing={1} >
                        {!(loginUser?.authorities && loginUser?.authorities[0]?.authority === 'User' && (loginUser?.location?.code === 'NL' || loginUser?.location?.code === 'US')) ?
                            (
                                <Grid item container xs={12}>
                                    <Tabs onclick={onHideTracker} value={selectedLocationIndex} onChange={onHandleSelectedLocationIndexChange} sx={{ minHeight: "30px", overflow: "hidden", border: "1px solid #ccc", backgroundColor: "#f1f1f10a" }}>
                                        <StyledTab label={"NL"} className="locationTab" />
                                        <StyledTab label="US" className="locationTab" />
                                        <StyledTab label="ALL" className="locationTab" />
                                        <StyledTab label="EXP" className="locationTab" />
                                    </Tabs>
                                    {loginUser.fullName == "Admin" && <Tabs onChange={onHandleSelectedLocationTrackerChange} sx={{ minHeight: "30px", overflow: "hidden", border: "1px solid #ccc", backgroundColor: "#f1f1f10a", marginLeft: "10%" }}>
                                        <StyledTab label="Tracker" className="locationTab" />
                                    </Tabs>}
                                    {/*
                                            <Button color='secondary' onClick={() => {
                                                onHandleOpenAdvSearchDialog();
                                            }} variant='contained' sx={{ padding: "0px 6px 0px 6px !important", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                                                <Typography noWrap variant='button'>
                                                    Adv Search
                                                </Typography>
                                            </Button>
                                        */}
                                </Grid>) : null}
                        {isTrackerLoaded && <Tracker fullName={loginUser.fullName} />}
                        {!isTrackerLoaded && <><Grid container item xs={8} spacing={1} style={{ marginTop: '0px' }}>
                            {/* <Typography noWrap variant='h5'>Orders History</Typography>*/}
                            <Button variant='contained' sx={{ fontWeight: "400", backgroundColor: "#1976d270 !important", textTransform: "none", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "13%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("Orders"));
                                dispatch(getOrders([]));
                                dispatch(onPageChange(0));
                                dispatch(onUnConfirmedOrdersCountChange(''));
                                window.setTimeout(function () {
                                    navigate("/home", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Orders" } })
                                }, 500)
                                //agentOrders()
                            }}>
                                Orders{unConfirmedOrdersCount ? ' (' + unConfirmedOrdersCount + ')' : ''}
                            </Button>
                            <Button color='secondary' variant='contained' sx={{ fontWeight: "400", textTransform: "none", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "16%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("OrdersHistory"));
                                dispatch(getOrders([]));
                                // navigate("/home", { state: { name: "location", locInd: selectedLocationIndex } })
                                dispatch(onPageChange(0));
                                dispatch(onUnConfirmedOrdersCountChange(''));
                                window.setTimeout(function () {
                                    navigate("/orderHistory", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "OrdersHistory" } })
                                }, 500)
                            }}>
                                Order History
                            </Button>
                            {selectedLocationIndex != 2 && <Button color='secondary' variant='contained' sx={{ fontWeight: "400", textTransform: "none", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "11%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("Parcels"));
                                dispatch(getOrders([]));
                                dispatch(onPageChange(0));
                                dispatch(onUnConfirmedParcelsCountChange(''));
                                window.setTimeout(function () {
                                    navigate("/phome", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Parcels" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Redbox
                            </Button>
                            }
                            {selectedLocationIndex != 2 && <Button color='secondary' variant='contained' sx={{ fontWeight: "400", textTransform: "none", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "17%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("ParcelsHistory"));
                                dispatch(getOrders([]));
                                // navigate("/home", { state: { name: "location", locInd: selectedLocationIndex } })
                                dispatch(onPageChange(0));
                                dispatch(onUnConfirmedParcelsCountChange(''));
                                window.setTimeout(function () {
                                    navigate("/parcelHistory", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "ParcelsHistory" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Redbox History
                            </Button>
                            }
                            {selectedLocationIndex != 2 && <Button color='secondary' variant='contained' sx={{ fontWeight: "400", textTransform: "none", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "11%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("Drops"));
                                dispatch(getOrders([]));
                                dispatch(onPageChange(0));
                                dispatch(onUnConfirmedParcelsCountChange(''));
                                window.setTimeout(function () {
                                    navigate("/dhome", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "Drops" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Drop
                            </Button>
                            }
                            {selectedLocationIndex != 2 && <Button color='secondary' variant='contained' sx={{ fontWeight: "400", textTransform: "none", height: "2.2vw", marginTop: "3px", marginLeft: "11px", width: "17%", fontSize: "1vw !important" }} onClick={() => {
                                dispatch(onSelectedTabIndexNameChange("DropsHistory"));
                                dispatch(getOrders([]));
                                // navigate("/home", { state: { name: "location", locInd: selectedLocationIndex } })
                                dispatch(onPageChange(0));
                                dispatch(onUnConfirmedParcelsCountChange(''));
                                window.setTimeout(function () {
                                    navigate("/dropHistory", { state: { name: "location", locInd: selectedLocationIndex, tabInd: "DropsHistory" } })
                                }, 500)
                                //redboxParcels()
                            }}>
                                Drop History
                            </Button>
                            }

                        </Grid>

                            <Grid container item xs={4} justifyContent='flex-end' flexWrap="nowrap">
                                <Grid container item xs={6} justifyContent='flex-end' alignContent="flex-start" flexWrap="nowrap">
                                    <Button color='secondary' onClick={() => {
                                        onHandleOpenAdvSearchDialog();
                                    }} variant='contained' sx={{ width: "30px", minWidth: "auto", marginRight: "5px", padding: "0", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                                        <ManageSearchIcon />
                                    </Button>
                                    <Button color='secondary' onClick={() => {
                                        csvExport();
                                    }} variant='contained' sx={{ width: "30px", minWidth: "auto", marginRight: "5px", padding: "0", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                                        <FileDownloadIcon />
                                    </Button>
                                        {(loginUser.department == 1 || loginUser.department == null) && <Button color='secondary' onClick={onHandleOpenDialog} variant='contained' sx={{ marginRight: "5px", padding: "0px 6px 0px 6px !important", backgroundColor: "#1976d270 !important", height: "2vw" }}>
                                            <Typography noWrap variant='button' style={{ textTransform: "none" }}>
                                                + Order Info
                                            </Typography>
                                        </Button>}
                                </Grid>
                            </Grid></>}
                        {openOrderDialog &&
                            <Dialog
                                open={openOrderDialog}
                                onHandleCancel={onHandleCancelDialog}
                                title='Order information'
                                maxWidth='lg'>
                                <OrderForm loginUser={loginUser} brokers={brokers} onHandleCancel={onHandleCancelDialog} order={null} orderTableFilter={orderTableFilter} pageName='Orders' />
                            </Dialog>
                        }
                        {advSearchDialog &&
                            <Dialog
                                open={advSearchDialog}
                                onHandleCancel={onHandleCancelAdvSearchDialog}
                                title='Advanced Search'
                                    maxWidth='lg'>
                                    <AdvSearchDialog selectedGeneral={selectedGeneral} setSelectedGeneral={setSelectedGeneral} onHandleChange={onHandleChange} subModeId={subModeId} subModeData={subModeData} onSubModeIdChange={onSubModeIdChange}
                                        STAGES_OPTIONS={STAGES_OPTIONS} stageSearch={stageSearch} OnStageSearchChange={OnStageSearchChange} fileNumber={fileNumber} setFileNumber={setFileNumber}
                                        urgentShipment={urgentShipment} onUrgentShipmentChange={onUrgentShipmentChange} advancedSearchDefaultStyle={advancedSearchDefaultStyle} resetAdvancedSearchFilter={resetAdvancedSearchFilter}
                                        globalSearch={globalSearch} setGlobalSearch={setGlobalSearch} setSearchResult={setSearchResult} isLoading={isLoading} CircularProgress={CircularProgress}
                                        globalSearchList={globalSearchList} selectedLocationIndex={selectedLocationIndex} onSelectedLocationIndexChange={onSelectedLocationIndexChange}
                                        onSearchChange={onSearchChange} setSearchSpinner={setSearchSpinner} setSearchText={setSearchText} dispatch={dispatch} sendRequest={sendRequest}
                                        failedCallback={failedCallback} onHandleCancelAdvSearchDialog={onHandleCancelAdvSearchDialog} setOrderData={setOrderData} loginUser={loginUser}
                                        getOrders={getOrders} />
                                
                            </Dialog>
                        }
                        </Grid>
                        {(!isTrackerLoaded) && <><OrderTable loginUser={loginUser} searchSpinner={searchSpinner} pageName='Orders' searchText={searchText} setSearchText={setSearchText} />
                    </>}
                </Fragment>
            )}
        </Grid>
    );
};

export default Orders;

export const StyledTab = styled(Tab)({
    border: '1px solid black',
    'font-size': '17px',
    'margin-right': '2px',
    'padding': '14px 16px',
    'min-height': '30px',
});
