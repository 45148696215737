/* eslint-disable react/prop-types */
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { MultilineField, TextField } from 'style-guide';
import { useDispatch } from "react-redux";
import './shipmentDetailDialog/ShipmentDetailDialog.scss';
import { REQUEST_ACTIONS, sendRequest, getCustomerData } from "utils/Communicator";
import { snackbarToggle } from "reducers/snackbarSlicer";

const ilChatNameStyle = { color: 'blue' };
const nlChatNameStyle = { color: 'orange' };

const Chat = ({ order, orderList, setOrderList, loginUser }) => {
    const [chatList, setChatList] = useState([]);
    const [chatText, setChatText] = useState("");
    const dispatch = useDispatch();

    const failedCallback = useCallback(message => {
        dispatch(snackbarToggle({ type: 'error', message }))
    }, [dispatch]);

    useEffect(() => {
        if (order) {
            if (order.chatCustomer)
                setChatList(JSON.parse(order.chatCustomer));
            else
                setChatList([]);
            updateSeen();
        }
    }, [order])

    const getChatDate = (d) => {
        var date = new Date(d);
        var month = date.getMonth(date)+1;
        var day = date.getDate(date);
        var year = date.getFullYear(date);

        var hr = date.getHours(date);
        var mn = date.getMinutes(date);
        return `${day}/${month}/${year} ${hr}:${mn}`;
    }

    const getInitiatorStyle = (ch) => {
        var type = ch['initiatorType']
        if (type == 5)
            return nlChatNameStyle;
        else
            return ilChatNameStyle;
    }

    const onHandlechangeChat = (value) => {
        setChatText(value);
    }

    const updateSeen = () => {
        if (order.chatCustomer) {
            var chat_list = JSON.parse(order.chatCustomer);
            var len = chat_list ? chat_list.length : 0;
            var changeMade = false;
            for (var j = 0; j < len; j++) {
                if (!chat_list[j]['NLStatus']) {
                    changeMade = true;
                    chat_list[j]['NLStatus'] = true;
                }
            }
            if (changeMade) {
                order.chatCustomer = JSON.stringify(chat_list);
                setChatList(chat_list);
                var o1 = JSON.parse(JSON.stringify(order));
                sendRequest('/api/order?fromStage=0&loginUserId=' + loginUser?.id + '&actionOn=ChatSeen', REQUEST_ACTIONS.POST, o1, {
                    successCallback: response => {
                        if (orderList) {
                            var len = orderList.length;
                            var orders = [];
                            for (var i = 0; i < len; i++) {
                                var o = JSON.parse(JSON.stringify(orderList[i]));
                                if (o.id == o1.id) {
                                    o.chatCustomer = o1.chatCustomer;
                                }
                                orders.push(o);
                            }
                            setOrderList(orders);
                        }
                        setChatText("");
                    },
                    failedCallback,
                });
            }
        }
    };

    const onUpdateChat = (id, chatString, fromStage) => {
        var data = {
            value: chatString
        }
        sendRequest(`/api/order/updateCustChat/${id}?fromStage=${fromStage}&loginUserId=${loginUser?.id}&actionOn=ChatDialog`, REQUEST_ACTIONS.PUT, data, {
            successCallback: data => {
                setChatList(JSON.parse(data.chatCustomer));
                var orders = [];
                if (orderList) {
                    var len = orderList.length;
                    var orders = [];
                    for (var i = 0; i < len; i++) {
                        var order = JSON.parse(JSON.stringify(orderList[i]));
                        if (order.id == data.id) {
                            order.chatCustomer = data.chatCustomer;
                        }
                        orders.push(order);
                    }
                    setOrderList(orders);
                }
                setChatText("");
            },
            failedCallback,
        });
    };

    const onHandleSendChat = (statusChatButton) => {
        var exists = false;
        var date = new Date();
        var chat = {
            'time': date.toUTCString(),
            'text': chatText,
            'initiator': loginUser.fullName,
            'initiatorType': 5,
            'location': 'All',
            'ILStatus': false,
            'NLStatus': true
        }
        let o1 = { ...order }

        var chat_list = [];
        if (order.chat) {
            chat_list = JSON.parse(order.chat);
        }
        if (chat_list?.length) {
            for (var j = 0; j < chat_list.length; j++) {
                if (!chat_list[j]['NLStatus']) {
                    chat_list[j]['NLStatus'] = true;
                }
            }
        }
        if (!order.chat) {
            chat_list[0] = chat;
        }
        else {
            chat_list.push(chat);
        }

        var chatString = JSON.stringify(chat) 

        onUpdateChat(order.id, chatString, 0);
        return;
    }
    return <Fragment>
        <div style={{ fontSize: "0.8vw", margin: "10px", height: '25vh', overflowY: 'auto', border: '1px solid #00AEEF', display: 'flex', flexDirection: 'column-reverse' }}>
            <div style={{ height: 'auto', width: '100%' }}>
                {chatList?.map(ch => (
                    <div style={{ width: '100%', padding: '5px' }}> <span style={{ color: 'grey' }}>{getChatDate(ch['time'])}</span> <span style={getInitiatorStyle(ch)}>{ch['initiator']}</span><span style={{ color: 'black' }}> : {ch['text']}</span></div>
                ))}
            </div>
            <div style={{ float: "left", clear: "both" }} id="bottomOfScreen"></div>
        </div>
        <Grid container item flexWrap="nowrap" justifyContent='space-evenly' style={{ fontSize: "14px" }}>
            <TextField
                sm={10}
                md={null}
                lg={null} rows1={true} style={{ fontSize: "0.8vw", width: '100%', height: "2.1vw" }} id="chatText" label="Chat" variant="outlined" type="text" value={chatText} onChange={(event) => onHandlechangeChat(event.target.value)}
            />
            <Button class="chatSendButton" variant='contained' sx={{ mr: 2 }} onClick={(event) => onHandleSendChat(11)} style={{ fontSize: "0.8vw", background: "#00AEEF", border: "#707070", borderRadius: "4px", color:"#FFF", width: "4.8vw", height: "2.1vw"}}>
                Chat
            </Button>
        </Grid>
    </Fragment>;
}

export default Chat;


